import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import InputDefault from "../../../../Form/InputDefault";
import ConfirmationForm from "../../../../Ui/ConfirmationForm";
import nextId from "react-id-generator";

//ICONES

import "./styles.scss";

const portalRoot = document.getElementById("portal-root");
export default function ModalConfirmation({
  id,
  onClose,
  setData,
  data,
  styles,
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [error, setError] = useState({});
  const [options, setOptions] = useState([]);

  const [formData, setFormData] = useState({
    question: data.config.question,
  });

  useEffect(() => {
    const newItem = JSON.parse(window.localStorage.getItem("@buildr:edition"));
    setOptions([...newItem.alternative]);
  }, [data]);

  function hadleOnChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  function handleOnAddOption() {
    options.push({
      id: nextId(),
      placeholder: "Aceitar os termos",
      value: "Aceitar os termos",
      require: false,
    });
    setOptions([...options]);
  }

  function handleInputalternative(key, value) {
    options[key].value = value;
    setOptions(...[options]);
  }

  function handleOnRequired(key) {
    options[key].required = !options[key].required;
    setOptions(...[options]);
  }

  function handleOnDelete(id) {
    const idSelect = options.filter((item) => item.id !== id);
    setOptions([...idSelect]);
  }

  async function handleSubimit(event) {
    try {
      event.preventDefault();

      options.map((row) => {
        if (!row.value) {
          throw {
            name: "termos",
            message: "Preencha todos os campos",
          };
        }
        return null
      });

      const alternative = options;
      const data = {
        ...formData,
        alternative,
      };

      setData({ ...data });
      onClose();
    } catch (e) {
      setError({
        error: true,
        message: e.message,
        name: e.name,
      });
    }
  }

  return ReactDOM.createPortal(
    <div
      id={id}
      onClick={handleCloseModal}
      className="OverlayModalEditFormulario"
    >
      <div id="ContainerEditInput">
        <header>
          <h2>Configurar esse campo</h2>
        </header>

        <form onSubmit={handleSubimit}>
          <div className="contentBoxArea">
            <div className="questionName">
              <InputDefault
                placeholder="Nome da pergunta"
                defaultValue={data?.config.question || "Texto"}
                onChange={(event) =>
                  hadleOnChange("question", event.target.value)
                }
                required
              />
            </div>

            {error && <span className="errorTerms">{error.message}</span>}

            <div className="sectionTextTerms">
              <div className="sectionOptions">
                <button
                  type="button"
                  className="buttonAddOptions add"
                  onClick={handleOnAddOption}
                >
                  Adicionar
                </button>
              </div>
              {options.map((row, key) => (
                <div className="sectionTerms" key={row.id}>
                  <div className="buttonsTerms">
                    <button
                      type="button"
                      className="buttonAddOptions delete"
                      onClick={() => handleOnDelete(row.id)}
                    >
                      Excluir
                    </button>
                    <div className="checkRequired">
                      <span>Tornar obrigatório</span>
                      <label className="switch">
                        <input 
                          type="checkbox"
                          onClick={() => handleOnRequired(key)}
                          defaultChecked={row.required}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                  <ConfirmationForm
                    handleOnDelete={(id) => handleOnDelete(id)}
                    styles={styles}
                    value={row.value}
                    onChangeText={(value) => handleInputalternative(key, value)}
                    tollbar={`document-editor__toolbar${key}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <footer>
            <button className="buttonCancel" type="button" onClick={onClose}>
              Cancelar
            </button>
            <button className="buttonSave" type="submit">
              Salvar
            </button>
          </footer>
        </form>
      </div>
    </div>,
    portalRoot,
  );
}
