import React from "react";

import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import "./styles.scss";

export default function ConfirmationForm({
  value = "",
  options = {},
  onChangeText = () => {},
  tollbar,
}) {
  function handleOnChangeText(evt, editor) {
    onChangeText(editor.getData());
  }

  return (
    <div id="editTextFormUi">
      <div className="App">
        <div className="document-editor">
          <div className={[tollbar]}></div>
          <div className="document-editor__editable-container">
            <CKEditor
              onInit={(editor) => {
                window.editor = editor;
                // Add these two lines to properly position the toolbar
                const toolbarContainer = document.querySelector("." + tollbar);

                toolbarContainer.color = options.colorText;
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
              }}
              editor={DecoupledEditor}
              config={{
                fontFamily: {
                  options: [
                    "default",
                    "Ubuntu, Arial, sans-serif",
                    "Ubuntu Mono, Courier New, Courier, monospace",
                  ],
                },
                toolbar: ["fontsize", "|", "link"],
              }}
              style={{ lineHeight: "100px" }}
              data={value}
              onChange={handleOnChangeText}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
}
