import React from "react";

export default function FileDownloadICon({ width = 16.146, height = 21.528, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.146 21.528"
    >
      <path
        id="FontAwsome_file-download_"
        data-name="FontAwsome (file-download)"
        d="M9.418,5.718V0H1.009A1.007,1.007,0,0,0,0,1.009v19.51a1.007,1.007,0,0,0,1.009,1.009H15.137a1.007,1.007,0,0,0,1.009-1.009V6.727H10.428A1.012,1.012,0,0,1,9.418,5.718Zm3.214,8.887L8.579,18.629a.717.717,0,0,1-1.011,0L3.514,14.605a.672.672,0,0,1,.473-1.15H6.727V10.091A.673.673,0,0,1,7.4,9.418H8.746a.673.673,0,0,1,.673.673v3.364h2.741a.673.673,0,0,1,.474,1.15Zm3.219-10.19L11.735.294A1.008,1.008,0,0,0,11.02,0h-.256V5.382h5.382V5.125A1.006,1.006,0,0,0,15.852,4.415Z"
        fill={color}
      />
    </svg>
  );
}
