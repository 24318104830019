import React from "react";

export default function SearchIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15.004"
      viewBox="0 0 15 15.004"
    >
      <path
        id="Icon_ionic-ios-search"
        data-name="Icon ionic-ios-search"
        d="M19.324,18.411,15.152,14.2a5.945,5.945,0,1,0-.9.914L18.394,19.3a.642.642,0,0,0,.906.023A.646.646,0,0,0,19.324,18.411ZM10.48,15.165A4.694,4.694,0,1,1,13.8,13.79,4.666,4.666,0,0,1,10.48,15.165Z"
        transform="translate(-4.5 -4.493)"
        fill={color ? color : "#000"}
      />
    </svg>
  );
}
