import React, { useCallback, useState } from 'react';

import DropDownIcon from '../../../Icons/DropDownIcon';

import "./styles.scss";

function SelectDefault({ placeholder, name, label, disabled, children, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (

    <div id="containerSelectDe">
      <label className={`${isFocused ? "focused" : ""}`} htmlFor={name}>{label}</label>

      <div className={`containerSelectDefault ${disabled ? "selectDisabled" : ""} ${isFocused ? "focused" : ""}`} {...rest}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      >
        <select
          className={`${disabled ? "selectDisabled" : ""}`}
          disabled={disabled}
          id={name}
          name={name}
          {...rest}
        >
          {placeholder && (
            <option  value="0" disabled={true}>
                {placeholder}
            </option>
          )}
          {children}
        </select>
        <DropDownIcon />
      </div>
    </div>

  );
}

export default SelectDefault
