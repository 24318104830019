import React from "react";

export default function WatchIcon({ size = 16, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        id="icone"
        fill={color}
        d="M16,8a8,8,0,1,0,8,8A8,8,0,0,0,16,8Zm0,14.452A6.452,6.452,0,1,1,22.452,16,6.45,6.45,0,0,1,16,22.452Zm1.994-3.368-2.739-1.99a.39.39,0,0,1-.158-.313v-5.3a.388.388,0,0,1,.387-.387h1.032a.388.388,0,0,1,.387.387v4.571l2.155,1.568a.387.387,0,0,1,.084.542L18.535,19A.39.39,0,0,1,17.994,19.084Z"
        transform="translate(-8 -8)"
      />
    </svg>
  );
}
