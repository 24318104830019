import React, { useState } from "react";
import TrashIcon from "../../../Icons/TrashIcon";

import "./styles.scss";
import InputDefault from "../../../Components/Form/InputDefault";

export default function ModalEditPage({
  id,
  idPage,
  onClose,
  onDelete = () => {},
  onUpdate = () => {},
  namePage,
  validationPage
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [data, setData] = useState('')
  const [error, setError] = useState({
    error: false,
    message: ''
  })

  function handleOnSetPage(e) {
    try {
      e.preventDefault()

      if(validationPage.filter(row => row.nome === data).length > 0) {
        throw{
          name: 'namePage',
          message: "Já existe uma page com este nome!",
        };
      } else if(data === "undefined" || data === null || data === '') {
        throw{
          name: 'namePage',
          message: "Favor insira um nome valido",
        };
      }

      onUpdate(data)

    } catch(err) {
      setError({
        error: true,
        message: err.message,
        name: err.name,
      })
    }
  }

  return (
    <div id={id} onClick={handleCloseModal} className="overlayModalEditPage">
      <form onSubmit={handleOnSetPage} className="containerConfigPage">
        <header>
          <h2>Configurações da Página</h2>
        </header>

        <main>
          <div className="questionName">
          <InputDefault
            type="text"
            name={"namePage"}
            label={"Nome da página"}
            onChange={(event) => setData(event.target.value)}
            placeholder="Digite o nome da página"
            defaultValue={namePage}
            error={error}
          />
          </div>
          {idPage !== "horizontal-1" && (
            <div className="deletePage">
              <h2>Deseja deletar essa página?</h2>

              <button className="buttonSettings delete" onClick={onDelete}>
                <TrashIcon />
                Deletar
              </button>
            </div>
          )}
        </main>
        <footer>
          <button className="buttonCancel" type="button" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="buttonSave"
            type="submit"
          >
            Salvar
          </button>
        </footer>
      </form>
    </div>
  );
}
