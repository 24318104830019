import React, { useState } from "react";
import widgetStore from "../../Store/WidgetStore";
import { ReactSortable } from "react-sortablejs";

//ICONS
import AddSectionIcon from "../../Icons/AddSectionIcon";

import "./AddSection.scss";
import DropUpIcon from "../../Icons/DropUpIcon";

export default function AddSection() {
  const newData = widgetStore.getState()
  const [close, setClose] = useState(false)

  return (
    <div id="addSection">
      <section>
        <div className="mainAddContent" onClick={() => setClose(!close)}>
          <div className="contentDescription">
          <AddSectionIcon />
          <h3>Adicionar seções</h3>
          </div>
          <div className="iconDropContent" style={close ? {transform: 'rotate(-180deg)'} : {transform: 'rotate(0deg)'}}>
            <DropUpIcon />
          </div>
        </div>

        <footer className={close ? 'close' : 'open'}>
          <ReactSortable
            animation={150}
            sort={false}
            list={newData}
            setList={() => {}}
            group={{
              name: "shared",
              pull: "clone",
              put: false,
            }}
          >
            {newData
              .filter((obcjt) => obcjt.template.cat === "secao")
              .map((row, key) => (
                <div className="drag-item" key={key}>
                  <i className={row.template.ico}></i>
                  {row.template.icone}
                  <span>{row.template.name}</span>
                </div>
              ))}
          </ReactSortable>
        </footer>
      </section>
    </div>
  );
}
