import React, { useState, useEffect } from "react";
import ButtonWidget from "../ButtonWidget/ButtonWidget";
import WidgetStore from "../../../Store/WidgetStore";

import "./WidgetRender.scss";

const WidgetRender = (props) => {
  const [componentRender, setComponentRender] = useState(false);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    var componentRender = WidgetStore.getState().filter(
      (obj) => obj.template.name === props.item.name,
      )[0];
      
      var comp = {
        component: componentRender.template.component,
        componentEdit: componentRender.template.componentEdit,
      };
      
      setComponentRender(comp);

    }, [props]);

  function handleOver() {
    setHover(true)
  }

  function handleLeave() {
    setHover(false)
  }

  return (
    <div
      key={props.key}
      id={props.id}
      onMouseOver={handleOver}
      onMouseLeave={handleLeave}
      className={
        props.editId === props.id
          ? props.item.className + " item-renderized edit"
          : props.item.className + " item-renderized"
      }
    >
      {props.edit === true && (
        <ButtonWidget
          editWidget={props.editWidget}
          removeWidget={props.removeWidget}
          item={props.item}
          id={props.id}
          indice={props.indice}
          componentEdit={componentRender.componentEdit}
          setLoading={props.setLoading}
          hover={hover}
        />
      )}

      {componentRender && (
        <componentRender.component
          updateWidgetColumn={props.updateWidgetColumn}
          template={props.template}
          editWidget={props.editWidget}
          removeWidget={props.removeWidget}
          indice={props.indice}
          item={props.item}
          id={props.id}
          editId={props.editId}
          setLoading={props.setLoading}
          edit={props.edit}
          stateButton={props.stateButton}
          pageId={props.pageId}
          setPageId={props.setPageId}
        />
      )}
    </div>
  );
};

export default WidgetRender;
