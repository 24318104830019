import React from "react";

//ICONES
import SignalIcon from '../../../Icons/SignalIcon';
import BateryIcon from '../../../Icons/BateryIcon';
import WifiIcon from '../../../Icons/WifiIcon';

import "./styles.scss";

export default function PhoneUi({
  bg,
  isHome,
  isFrame,
  page,
  bgImage,
  children,
  stiky,
}) {
  return (
    <div id="containerPhoneUi">
      <div className="mobileUi">
        {isFrame && (
          <div className="sectionBarUi">
            <span className="hoursPhone">09:20</span>
            <span className="pageName">{page}</span>
            <div className="sectionStatusBar">
              <SignalIcon size={18} />
              <WifiIcon size={16} />
              <BateryIcon size={18} />
            </div>
          </div>
        )}
        <div 
          className="screenUi"
          style={{ 
            backgroundColor: isHome ? bg : '#C1C9D8', 
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: stiky ? 'repeat' : 'no-repeat',
            backgroundSize:  stiky ? 'contain' : 'cover'  
          }}>
            <div className="sectionElementsUi">
              {children}
            </div>
        </div>
        <div className="inner"></div>
        <ul className="volume">
          <li></li>
          <li></li>
        </ul>
        <ul className="silent">
          <li></li>
        </ul>
        <ul className="sleep">
          <li></li>
        </ul>
      </div>
    </div>
  );
}
