import React from "react";

export default function InstaIcon({ size = 16, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16.004 16"
    >
      <path
        id="FontAwsome_instagram_"
        data-name="FontAwsome (instagram)"
        d="M7.929,35.723a4.1,4.1,0,1,0,4.1,4.1A4.1,4.1,0,0,0,7.929,35.723Zm0,6.769A2.667,2.667,0,1,1,10.6,39.825a2.672,2.672,0,0,1-2.667,2.667Zm5.227-6.937A.957.957,0,1,1,12.2,34.6.955.955,0,0,1,13.155,35.555Zm2.717.971a4.735,4.735,0,0,0-1.292-3.352,4.766,4.766,0,0,0-3.352-1.292c-1.321-.075-5.28-.075-6.6,0A4.759,4.759,0,0,0,1.274,33.17,4.751,4.751,0,0,0-.019,36.523c-.075,1.321-.075,5.28,0,6.6a4.735,4.735,0,0,0,1.292,3.352,4.772,4.772,0,0,0,3.352,1.292c1.321.075,5.28.075,6.6,0a4.735,4.735,0,0,0,3.352-1.292,4.766,4.766,0,0,0,1.292-3.352c.075-1.321.075-5.277,0-6.6Zm-1.707,8.015a2.7,2.7,0,0,1-1.521,1.521c-1.053.418-3.552.321-4.716.321s-3.667.093-4.716-.321a2.7,2.7,0,0,1-1.521-1.521c-.418-1.053-.321-3.552-.321-4.716s-.093-3.667.321-4.716a2.7,2.7,0,0,1,1.521-1.521c1.053-.418,3.552-.321,4.716-.321s3.667-.093,4.716.321a2.7,2.7,0,0,1,1.521,1.521c.418,1.053.321,3.552.321,4.716S14.583,43.492,14.166,44.541Z"
        transform="translate(0.075 -31.825)"
        fill={color}
      />
    </svg>
  );
}
