import React from "react";

export default function PlusIcon({ size = 12, color = "#252428" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <g
        id="Icon_feather-plus-circle"
        data-name="Icon feather-plus-circle"
        transform="translate(-1.5 -1.5)"
      >
        <path
          id="Caminho_24"
          data-name="Caminho 24"
          d="M70,36.5A33.5,33.5,0,1,1,36.5,3,33.5,33.5,0,0,1,70,36.5Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Caminho_25"
          data-name="Caminho 25"
          d="M18,12V38.8"
          transform="translate(18.5 11.1)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Caminho_26"
          data-name="Caminho 26"
          d="M12,18H38.8"
          transform="translate(11.1 18.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
