import React from "react";

export default function Dateicon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.125"
      height="15"
      viewBox="0 0 13.125 15"
    >
      <path
        id="icone"
        fill={color}
        d="M11.719,1.875H10.313V.352A.353.353,0,0,0,9.961,0H8.789a.353.353,0,0,0-.352.352V1.875H4.688V.352A.353.353,0,0,0,4.336,0H3.164a.353.353,0,0,0-.352.352V1.875H1.406A1.407,1.407,0,0,0,0,3.281V13.594A1.407,1.407,0,0,0,1.406,15H11.719a1.407,1.407,0,0,0,1.406-1.406V3.281A1.407,1.407,0,0,0,11.719,1.875Zm-.176,11.719H1.582a.176.176,0,0,1-.176-.176V4.688H11.719v8.73A.176.176,0,0,1,11.543,13.594Z"
      />
    </svg>
  );
}
