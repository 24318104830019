import React from 'react';
import TipIcon from '../../../Icons/TipIcon';

import './TipUi.scss';

function TipUi({ children }) {
  return (
    <div id="containerTip">
      <header>
        <TipIcon />
        <h2>Dica</h2>
      </header>
      <p>
        {children}
      </p>
    </div>
  );
}

export default TipUi;