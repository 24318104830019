import React, { useEffect, useState } from "react";
import ButtonControllers from "../../Components/Ui/ButtonControllers";

import AddSection from "../../Components/AddSection/AddSection";
import AddFunction from "../../Components/AddFunction/AddFunction";
import AddContent from "../../Components/AddContent/AddContent";
import { AddGlobal } from "../../Components/AddGlobal/AddGlobal";

import RenderWidgets from "../../Components/TemplateCreation/Sections/RenderWidgets";
import { EditionWidget } from "../../Components/TemplateCreation/Sections";
import { InfoGeral } from "../../Components/Edits/InfoGeral/InfoGeral";
import widgetStore from "../../Store/WidgetStore";
import messageStore from "../../Store/MessageStore";
import Galery from "../../Components/Ui/GaleryUi/Galery/Galery";
import PaginationFlow from "../Flow/PaginationFlow/PaginationFlow";
import { useParams } from "react-router-dom";
import PhoneTwo from "../../Components/Ui/PhoneTwo";
import Modal from '../../Components/Ui/Modal/Modal';
import ButtonDefault from "../../Components/Form/ButtonDefault";
import { useHistory } from "react-router-dom";

//ICONES
import ConfigurationIcon from "../../Icons/ConfigurationIcon";
import PagesIcon from "../../Icons/PagesIcon";
import AddIcon from "../../Icons/AddIcon";
import AddContentIcon from "../../Icons/AddContentIcon";
import AddFunctionIcon from "../../Icons/AddFunctionIcon";
import ArrowRoundIcon from "../../Icons/ArrowRoundIcon";
import EyeIcon from "../../Icons/EyeIcon";
import ArrowLeft from "../../Icons/ArrowLeft";
import PhoneUi from "../../Components/Ui/PhoneUi";

import "./styles.scss";

export default function GlobalEdit() {
  const params = useParams();
  const history = useHistory()
  const date = new Date(); 
  const [pageId, setPageId] = useState('0');
  const [viewProject, setViewProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalBackToPage, setModalBackToPage] = useState(false);
  
  const [template, setTemplate] = useState(
    window.localStorage.getItem(params.id)
    ? JSON.parse(window.localStorage.getItem(params.id))
    : [],
    );

  const isTemplate = template.pages.filter((obj) => obj.data);
  const isConnection = template.pages.filter(obj => obj.target);
  const isContent = isTemplate.map((row) => row.template.length);
  let ultimatePage = isTemplate.filter(obj => obj.pageId === pageId)[0].id
  const currentPage = isTemplate.filter(obj => obj.pageId === pageId)[0]

  const [editionWidgetEdit, setEditionWidgetEdit] = useState({
    id: null,
  });

  const [formStyles, setFormStyles] = useState(
    template
      ? template.globalStyles
      : {
          src: "https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png",
          borderRadius: "0px",
          category: "0",
          category1: "0",
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          bg: false,
          stiky: false,
        },
  );

  const [formData, setFormData] = useState({
    project: template.project,
    description: template.description,
    category: template.category,
    storytelling: template.storytelling,
    analytics: template.analytics,
    imagePage: template.imagePage,
    borderRadiusLogo: template.borderRadiusLogo,
  });

  const [link, setLink] = useState(template.storytelling);

  function handleOnChangeNew(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  function handleOnStorytelling(name, value) {
    setLink(!link)
    handleOnChangeNew(name, value)
  }

  function editWidget(item, id, componentEdit) {
    setEditionWidgetEdit({
      item: false,
      id: false,
      component: false,
    });

    setTimeout(function () {
      setEditionWidgetEdit({
        item: item,
        id: id,
        component: componentEdit,
      });
    }, 100);
  }

  function removeWidget(idItem) {
    const newTemplate = [];
    isTemplate.map((row) => {
      if (row.pageId === pageId) {
        row.template = row.template.filter((obj) => obj.id !== idItem);
      }
      return newTemplate.push(row);
    });

    isConnection.map((row) => {
      return newTemplate.push(row)
    })

    const {
      id,
      description,
      project,
      category,
      analytics,
      storytelling,
      globalStyles,
      borderRadiusLogo,
      imagePage,
    } = template;

    const newTemp = {
      id,
      project,
      description,
      borderRadiusLogo,
      category,
      analytics,
      storytelling,
      imagePage,
      globalStyles,
      pages: newTemplate,
      new: true,
      updateHours: date,
    };

    setTemplate(newTemp);

    if (editionWidgetEdit.id === idItem) {
      setEditionWidgetEdit({
        id: null,
      });
    }
  }

  function saveWidget(item) {
    const newWidget = [];
    isTemplate.map((row) => {
      if (row.pageId === pageId) {
        row.template = row.template.filter((obj) => obj.id !== item.id);
      }
      return newWidget.push(row);
    });

    isConnection.map((row) => {
      return newWidget.push(row)
    })

    const {
      description,
      id,
      project,
      category,
      analytics,
      storytelling,
      globalStyles,
      borderRadiusLogo,
      imagePage,
    } = template;

    const newTemp = {
      id,
      project,
      description,
      category,
      borderRadiusLogo,
      analytics,
      storytelling,
      imagePage,
      globalStyles,
      pages: newWidget,
      new: true,
      updateHours: date,
    };

    setTemplate(newTemp);
  }

  function updateWidgetColumn(contentState, idItem) {
    setLoading(false);
    var widgets = [];

    isTemplate.map((row) => {
      if (row.pageId === pageId) {
        row.template.map((row) => {
          if (row.id === idItem) {
            row.template.content = [];
            row.template.content = contentState;
          }
          return null;
        });
      }
      return widgets.push(row);
    });

    isConnection.map((row) => {
      return widgets.push(row)
    })

    const {
      id,
      project,
      category,
      analytics,
      storytelling,
      description,
      globalStyles,
      borderRadiusLogo,
      imagePage,
    } = template;

    const newTemp = {
      id,
      project,
      description,
      category,
      analytics,
      storytelling,
      imagePage,
      borderRadiusLogo,
      globalStyles,
      pages: widgets,
      new: true,
      updateHours: date,
    };

    setTemplate(newTemp);
    setLoading(true);
  }

  async function handleSaveTemplate() {
    window.localStorage.setItem(params.id, JSON.stringify(template));
    messageStore.addSuccess(`${template.project} - Template salvo.`);
  }

  function handleResetTemplate() {
    const resetTemplate = [];
    isTemplate.map((row) => {
      if (row.pageId === pageId) {
        row.template = [];
      }
      return resetTemplate.push(row);
    });

    isConnection.map((row) => {
      return resetTemplate.push(row)
    })

    const {
      description,
      id,
      project,
      category,
      analytics,
      storytelling,
      globalStyles,
      imagePage,
      borderRadiusLogo,
    } = template;

    const newTemp = {
      id,
      project,
      description,
      category,
      analytics,
      storytelling,
      imagePage,
      borderRadiusLogo,
      globalStyles,
      pages: resetTemplate,
      new: true,
      updateHours: date,
    };
    setTemplate(newTemp);
  }

  function handleOnStyles(name, value) {
    setFormStyles({ ...formStyles, [name]: value });
  }

  const SettingsData = [
    {
      id: 1,
      title: "Configurações gerais",
      PanelLeft: AddGlobal,
      panelCenter: "Global",
      PanelRight: "EditInformation",
      icon: <ConfigurationIcon />,
    },
    {
      id: 2,
      title: "Adicionar seções",
      PanelLeft: AddSection,
      PanelRight: "",
      icon: <AddIcon />,
    },
    {
      id: 3,
      title: "Adicionar conteúdo",
      PanelLeft: AddContent,
      icon: <AddContentIcon />,
    },
    {
      id: 4,
      title: "Adicionar função",
      PanelLeft: AddFunction,
      icon: <AddFunctionIcon />,
    },
    {
      id: 5,
      title: "Adicionar página",
      PanelLeft: "Global",
      panelCenter: PaginationFlow,
      PanelRight: "EditInformation",
      icon: <PagesIcon />,
    },
  ];

  const [active, setActive] = useState(isContent.filter(row => row > 0).length ? SettingsData[1] : SettingsData[0]);

  useEffect(() => {
    setLoading(false);
    widgetStore.setState();
    widgetStore.setGlobalStyle(formStyles);
  }, [formStyles, template, active, loading]);

  function handleClick(item) {
    setActive(item);
  }

  const nextPage = () => {
    const actualPage = isTemplate.filter(obj => obj.pageId === pageId)[0]
    const actualPageId = actualPage.id
    
    const connectionPageActual = isConnection.filter(obj => obj.source === actualPageId)[0]
    const nextConnectionPage = isTemplate.filter(obj => obj.id === connectionPageActual.target)[0]

    setPageId(nextConnectionPage.pageId);
  };

  const prevPage = () => {
    const actualPage = isTemplate.filter(obj => obj.pageId === pageId)[0]
    const actualPageId = actualPage.id
    
    const connectionPageActual = isConnection.filter(obj => obj.target === actualPageId)[0]
    const lastConnectionPage = isTemplate.filter(obj => obj.id === connectionPageActual.source)[0]

    setPageId(lastConnectionPage.pageId);
  };

  if (!Array.isArray(isTemplate) || isTemplate.length <= 0) {
    return null;
  }

  function handleSetTemplate(data) {
    const newTemplates = [];

    isTemplate.map((row) => {
      if (row.pageId === pageId) {
        row.template = data;
      }
      return newTemplates.push(row)
    });

    isConnection.map((row) => {
      return newTemplates.push(row)
    })

    const {
      description,
      id,
      project,
      category,
      analytics,
      storytelling,
      globalStyles,
      imagePage,
      borderRadiusLogo,
    } = template;

    const newTemp = {
      id,
      project,
      description,
      category,
      analytics,
      storytelling,
      imagePage,
      borderRadiusLogo,
      globalStyles,
      pages: newTemplates,
      new: true,
      updateHours: date,
    };

    setTemplate(newTemp);
  }

  function handleOnBackPanel(item) {
    setActive(SettingsData[1]);
    setPageId(item)
  }

  function handleOnPreview(idPage) {
    const { id, pages } = template;
    const templates = JSON.parse(window.localStorage.getItem("template"))

    const newTemp = {
      id,
      project: formData.project,
      category: formData.category,
      analytics: formData.analytics,
      description: formData.description,
      storytelling: formData.storytelling,
      globalStyles: formStyles,
      imagePage: formData.imagePage || 'https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png',
      borderRadiusLogo: formData.borderRadiusLogo,
      pages,
      new: true,
      updateHours: date,
      pageId: idPage,
    };

    const updateProject = templates.filter((obj) => obj.id !== id);
    updateProject.push({ ...newTemp });
    window.localStorage.setItem("@preview", JSON.stringify(newTemp));
    setViewProject(!viewProject);
  }

  function handleTemplateGlobal() {
    const { id, pages } = template;
    const templates = JSON.parse(window.localStorage.getItem("template"))

    const newTemp = {
      id,
      project: formData.project,
      category: formData.category,
      analytics: formData.analytics,
      description: formData.description,
      storytelling: formData.storytelling,
      globalStyles: formStyles,
      imagePage: formData.imagePage || 'https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png',
      borderRadiusLogo: formData.borderRadiusLogo,
      pages,
      new: true,
      updateHours: date,
    };

    const updateProject = templates.filter((obj) => obj.id !== id);
    updateProject.push({ ...newTemp });

    window.localStorage.setItem("template", JSON.stringify(updateProject));

    setTemplate(newTemp);
    handleSaveTemplateGlobal(newTemp);
  }

  async function handleSaveTemplateGlobal(newTemp) {
    window.localStorage.setItem(params.id, JSON.stringify(newTemp));
    messageStore.addSuccess(`${template.project} - Template salvo.`);
  }

  async function handleOnSaveBackTopage() {
    const { id, pages } = template;
    const templates = JSON.parse(window.localStorage.getItem("template"))

    const newTemp = {
      id,
      project: formData.project,
      category: formData.category,
      analytics: formData.analytics,
      description: formData.description,
      storytelling: formData.storytelling,
      globalStyles: formStyles,
      imagePage: formData.imagePage || 'https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png',
      borderRadiusLogo: formData.borderRadiusLogo,
      pages,
      new: true,
      updateHours: date,
    };

    const updateProject = templates.filter((obj) => obj.id !== id);
    updateProject.push({ ...newTemp });

    window.localStorage.setItem("template", JSON.stringify(updateProject));

    setTemplate(newTemp);
    handleSaveTemplateGlobal(newTemp);

    history.push('/home')
  }

  function handleOnBackToPage() {
    setModalBackToPage(!modalBackToPage)
    history.push('/home')
  }

  return (
    <>
      {active.id === 5 ? (
        <PaginationFlow
          templateArray={template}
          setTemplateArray={setTemplate}
          handleOnBackPanel={handleOnBackPanel}
        />
      ) : (
        <div id="containerPageTeste">
          <div className="content">
            <div className="panelLeft">

              <div className="headerPanelLeft">
                <button 
                  type="button"
                  onClick={() => setModalBackToPage(!modalBackToPage)}  
                >
                  <ArrowLeft />
                </button>
                <div className="descriptionProject">
                  <h2>{template.project ?? ""}</h2>
                  <h3>{currentPage.nome}</h3>
                </div>
                <div className='buttonsSaveAndRestart'>
                  {active.id !== 1 && (
                    <button className="buttonHeaderProject restart" onClick={handleResetTemplate}>
                      Limpar
                    </button>
                  )}
                  {active.id === 1 ? (
                    <button className="buttonHeaderProject save" onClick={handleTemplateGlobal}>
                      Salvar
                    </button>
                  ) : (
                    <button className="buttonHeaderProject save" onClick={handleTemplateGlobal}>
                      Salvar
                    </button>
                  )}
                </div>
              </div>

              <div className="sectionLeft">
                {active.id === 1 ? (
                  <AddGlobal styles={formStyles} handleOnStorytelling={handleOnStorytelling} handleOnChangeNew={handleOnChangeNew} image={formData.imagePage ?? template.imagePage} borderRadiusLogo={formData.borderRadiusLogo} link={link} />
                ) : (
                  <active.PanelLeft
                    page={0}
                    handleResetTemplate={handleResetTemplate}
                    handleSaveTemplate={handleSaveTemplate}
                  />
                )}
              </div>
            </div>
            <div className="panelCenter">
              <div className="sectionControl">
                {SettingsData.map((row) => (
                  <React.Fragment key={row.id}>
                    <ButtonControllers
                      key={row.id}
                      active={active.id === row.id}
                      title={row.title}
                      onClick={() => handleClick(row)}
                    >
                      {row.icon}
                    </ButtonControllers>
                  </React.Fragment>
                ))}
                <Galery
                  typeButton={true}
                  handleOnImage={() => {}}
                />

                <ButtonControllers
                  title={"Visualizar page"}
                  onClick={() => handleOnPreview(pageId)}
                >
                  <EyeIcon />
                </ButtonControllers>
              </div>

              <div className="sectionCenter">

                <button 
                  className={`buttonNavigatePages left ${active.id === 1 ? "isConfig" : ""}`}
                  onClick={prevPage}
                  disabled={pageId === '0' || active.id === 1 ? true : false}
                >
                  <ArrowRoundIcon />
                </button>

                <button
                  className={`buttonNavigatePages right ${active.id === 1 ? "isConfig" : ""}`}
                  onClick={nextPage}
                  disabled={isConnection.filter(obj => obj.source === ultimatePage).length === 0 || active.id === 1 ? true : false }
                >
                  <ArrowRoundIcon />
                </button>

                {active.panelCenter ? (
                  <PhoneTwo
                    image={formData.imagePage ?? template.imagePage}
                    bg={formStyles.colorPrimary}
                    bgImage={formStyles.bg ? formStyles.src : 'none'}
                    borderRadius={formData.borderRadiusLogo}
                    isHome={active.panelCenter}
                    stiky={formStyles.stiky}
                  />
                ) : (
                  <PhoneTwo
                    isHome={active.panelCenter}
                    isFrame={true}
                    page={currentPage.nome}
                    bgImage={formStyles.bg ? formStyles.src : 'none'}
                    stiky={formStyles.stiky}
                  >
                    <RenderWidgets
                      loading={loading}
                      setLoading={setLoading}
                      template={currentPage.template}
                      setTemplate={handleSetTemplate}
                      editWidget={editWidget}
                      editionWidgetEdit={editionWidgetEdit}
                      updateWidgetColumn={updateWidgetColumn}
                      removeWidget={removeWidget}
                      stateButton={false}
                      pageId={pageId}
                      setPageId={setPageId}
                    />
                  </PhoneTwo>
                )}
              </div>

              {viewProject && (
                <div
                  className="overlayPreview"
                  onClick={() => setViewProject(!viewProject)}
                > 
                  <PhoneUi
                    isHome={false}
                    isFrame={false}
                    page={currentPage.template}
                  >
                    <iframe
                      src="/previewTeste"
                      title="PreviewProject"
                      frameBorder="0"
                      style={{
                        width: '100%', 
                        height: '100%', 
                        backgroundImage: formStyles.bg ? `url(${formStyles.src})` : 'url(none)',
                        backgroundRepeat: formStyles.stiky ? 'repeat' : 'no-repeat',
                        backgroundSize:  formStyles.stiky ? 'contain' : 'cover',
                      }}
                    />
                  </PhoneUi>
                </div>
              )}
            </div>
            <div className="panelRight">
              <div className="sectionRight">
                {active.PanelRight ? (
                  <InfoGeral
                    styles={formStyles}
                    handleOnStyles={(name, value) => handleOnStyles(name, value) }
                   />
                ) : (
                  <EditionWidget
                    editionWidgetEdit={editionWidgetEdit}
                    setEditionWidgetEdit={setEditionWidgetEdit}
                    onSave={saveWidget}
                  />
                )}
              </div>
            </div>
          </div>
          {modalBackToPage ? (
            <Modal
              id='id'
              onClose={() => setModalBackToPage(!modalBackToPage)}
            >
              <div className="containerSaveTempalte">
                <span className="alertMessageModal">Antes de proseguir</span>
                <h1>Deseja salvar este page?</h1>
    
                <div className="sectionButtonsConfirmation">
                  <ButtonDefault
                    type="cancel"
                    title='Cancelar'
                    onClick={handleOnBackToPage}
                  />

                  <ButtonDefault
                    type="default"
                    title='Salvar'
                    onClick={handleOnSaveBackTopage}
                  />
                </div>
              </div>
            </Modal>
          ) : (
            null
          )}
        </div>
      )}

    </>
  );
}
