import React from "react";

export default function FunctionWhatsAppIcon() {
  return (
    <svg
      id="whatsapp"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="bg" fill="#eef0f4">
        <path
          d="M 30 39 L 10 39 C 5.037380218505859 39 1 34.96261978149414 1 30 L 1 10 C 1 5.037380218505859 5.037380218505859 1 10 1 L 30 1 C 34.96261978149414 1 39 5.037380218505859 39 10 L 39 30 C 39 34.96261978149414 34.96261978149414 39 30 39 Z"
          stroke="none"
        />
        <path
          d="M 10 2 C 5.588790893554688 2 2 5.588790893554688 2 10 L 2 30 C 2 34.41120910644531 5.588790893554688 38 10 38 L 30 38 C 34.41120910644531 38 38 34.41120910644531 38 30 L 38 10 C 38 5.588790893554688 34.41120910644531 2 30 2 L 10 2 M 10 0 L 30 0 C 35.52285003662109 0 40 4.477149963378906 40 10 L 40 30 C 40 35.52285003662109 35.52285003662109 40 30 40 L 10 40 C 4.477149963378906 40 0 35.52285003662109 0 30 L 0 10 C 0 4.477149963378906 4.477149963378906 0 10 0 Z"
          stroke="none"
          fill="#859ec5"
        />
      </g>
      <g id="whats" transform="translate(-574 -41)">
        <path
          id="União_5"
          data-name="União 5"
          d="M1.857,15.877a10.041,10.041,0,1,1,3.549,3.095L.733,20.213Z"
          transform="translate(584.257 50.257)"
          fill="#fff"
        />
        <path
          id="FontAwsome_whatsapp_"
          data-name="FontAwsome (whatsapp)"
          d="M19.768,35.379a11.525,11.525,0,0,0-18.133,13.9L0,55.25l6.108-1.6a11.486,11.486,0,0,0,5.506,1.4h.005a11.448,11.448,0,0,0,8.148-19.669ZM11.62,53.107a9.559,9.559,0,0,1-4.878-1.334l-.348-.208-3.622.95.965-3.534-.228-.363a9.595,9.595,0,1,1,17.8-5.091A9.683,9.683,0,0,1,11.62,53.107Zm5.252-7.172c-.285-.145-1.7-.841-1.967-.934s-.457-.145-.649.145-.742.934-.913,1.131-.337.218-.623.073A7.836,7.836,0,0,1,8.8,42.924c-.3-.509.3-.472.846-1.572a.533.533,0,0,0-.026-.5c-.073-.145-.649-1.562-.887-2.138-.234-.56-.472-.483-.649-.493s-.358-.01-.55-.01a1.066,1.066,0,0,0-.768.358,3.235,3.235,0,0,0-1.007,2.4,5.64,5.64,0,0,0,1.173,2.979,12.864,12.864,0,0,0,4.92,4.349c1.827.789,2.543.856,3.456.721a2.949,2.949,0,0,0,1.941-1.37,2.408,2.408,0,0,0,.166-1.37C17.349,46.147,17.157,46.075,16.872,45.934Z"
          transform="translate(583 17)"
          fill="#859ec5"
        />
      </g>
    </svg>
  );
}
