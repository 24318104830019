import React from "react";

export default function EyeIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12.134"
      viewBox="0 0 18 12.134"
    >
      <path
        id="icone"
        d="M11.25,7.383a9.678,9.678,0,0,0-9,6.067,9.709,9.709,0,0,0,18,0A9.678,9.678,0,0,0,11.25,7.383Zm0,10.113a4.046,4.046,0,1,1,4.09-4.046A4.08,4.08,0,0,1,11.25,17.5Zm0-6.473A2.427,2.427,0,1,0,13.7,13.45,2.449,2.449,0,0,0,11.25,11.023Z"
        transform="translate(-2.25 -7.383)"
      />
    </svg>
  );
}
