import React from "react";

export default function MapIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      id="mapa"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="bg" fill="#eef0f4">
        <path
          d="M 30 39 L 10 39 C 5.037380218505859 39 1 34.96261978149414 1 30 L 1 10 C 1 5.037380218505859 5.037380218505859 1 10 1 L 30 1 C 34.96261978149414 1 39 5.037380218505859 39 10 L 39 30 C 39 34.96261978149414 34.96261978149414 39 30 39 Z"
          stroke="none"
        />
        <path
          d="M 10 2 C 5.588790893554688 2 2 5.588790893554688 2 10 L 2 30 C 2 34.41120910644531 5.588790893554688 38 10 38 L 30 38 C 34.41120910644531 38 38 34.41120910644531 38 30 L 38 10 C 38 5.588790893554688 34.41120910644531 2 30 2 L 10 2 M 10 0 L 30 0 C 35.52285003662109 0 40 4.477149963378906 40 10 L 40 30 C 40 35.52285003662109 35.52285003662109 40 30 40 L 10 40 C 4.477149963378906 40 0 35.52285003662109 0 30 L 0 10 C 0 4.477149963378906 4.477149963378906 0 10 0 Z"
          stroke="none"
          fill="#859ec5"
        />
      </g>
      <g id="map" transform="translate(9 11.667)">
        <g
          id="Caminho_41"
          data-name="Caminho 41"
          transform="translate(-54.083 -50.667)"
          fill="#fff"
        >
          <path
            d="M 53.083251953125 66.94544219970703 L 53.083251953125 65.5 L 53.083251953125 52.50000381469727 L 53.083251953125 51.76925277709961 L 53.77947235107422 51.54726409912109 L 59.52947235107422 49.71388244628906 L 60.833251953125 49.29817199707031 L 60.833251953125 50.66662216186523 L 60.833251953125 63.3333740234375 L 60.833251953125 64.02519989013672 L 60.18585205078125 64.26914215087891 L 54.43585205078125 66.43577575683594 L 53.083251953125 66.94544219970703 Z"
            stroke="none"
          />
          <path
            d="M 59.833251953125 50.6666259765625 L 54.083251953125 52.50000381469727 L 54.083251953125 65.5 L 59.833251953125 63.3333740234375 L 59.833251953125 50.6666259765625 M 61.833251953125 47.92972564697266 L 61.833251953125 64.71703338623047 L 52.083251953125 68.39088439941406 L 52.083251953125 51.03849029541016 L 61.833251953125 47.92972564697266 Z"
            stroke="none"
            fill="#859ec5"
          />
        </g>
        <g
          id="Caminho_42"
          data-name="Caminho 42"
          transform="translate(-46.333 -50.667)"
          fill="#fff"
        >
          <path
            d="M 60.833251953125 66.94544219970703 L 59.48065185546875 66.43577575683594 L 53.73065185546875 64.26914215087891 L 53.083251953125 64.02519989013672 L 53.083251953125 63.3333740234375 L 53.083251953125 50.66662216186523 L 53.083251953125 49.29817199707031 L 54.38703155517578 49.71388244628906 L 60.13703155517578 51.54726409912109 L 60.833251953125 51.76925277709961 L 60.833251953125 52.50000381469727 L 60.833251953125 65.5 L 60.833251953125 66.94544219970703 Z"
            stroke="none"
          />
          <path
            d="M 54.083251953125 50.6666259765625 L 54.083251953125 63.3333740234375 L 59.833251953125 65.5 L 59.833251953125 52.50000381469727 L 54.083251953125 50.6666259765625 M 52.083251953125 47.92972564697266 L 61.833251953125 51.03849029541016 L 61.833251953125 68.39088439941406 L 52.083251953125 64.71703338623047 L 52.083251953125 47.92972564697266 Z"
            stroke="none"
            fill="#859ec5"
          />
        </g>
        <g
          id="Caminho_43"
          data-name="Caminho 43"
          transform="translate(-38.583 -50.667)"
          fill="#fff"
        >
          <path
            d="M 53.083251953125 66.94544219970703 L 53.083251953125 65.5 L 53.083251953125 52.50000381469727 L 53.083251953125 51.76925277709961 L 53.77947235107422 51.54726409912109 L 59.52947235107422 49.71388244628906 L 60.833251953125 49.29817199707031 L 60.833251953125 50.66662216186523 L 60.833251953125 63.3333740234375 L 60.833251953125 64.02519989013672 L 60.18585205078125 64.26914215087891 L 54.43585205078125 66.43577575683594 L 53.083251953125 66.94544219970703 Z"
            stroke="none"
          />
          <path
            d="M 59.833251953125 50.6666259765625 L 54.083251953125 52.50000381469727 L 54.083251953125 65.5 L 59.833251953125 63.3333740234375 L 59.833251953125 50.6666259765625 M 61.833251953125 47.92972564697266 L 61.833251953125 64.71703338623047 L 52.083251953125 68.39088439941406 L 52.083251953125 51.03849029541016 L 61.833251953125 47.92972564697266 Z"
            stroke="none"
            fill="#859ec5"
          />
        </g>
      </g>
    </svg>
  );
}
