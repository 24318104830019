import React, { useCallback, useEffect, useRef, useState } from "react";

import { RgbaStringColorPicker, HexColorInput } from "react-colorful";
import useClickOutside from "../../../hooks/useClickOutside";
import { useDebouncedCallback } from "use-debounce";

import "./styles.scss";

export default function ColorUi({ onChange = () => {}, value, description }) {
  const [color, setColor] = useState(value);
  const [colorFormat, setColorFormat] = useState();
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  useEffect(() => {
    RGBAToHexA(color);
  }, [color]);

  const debounced = useDebouncedCallback(
    (value) => {
      setColor(value);
      onChange(value);
    },
    300,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 2000 },
  );

  function RGBAToHexA(rgba) {
    if (rgba.includes("#")) {
      return setColorFormat(rgba);
    }

    let sep = rgba.indexOf(",") > -1 ? "," : " ";
    rgba = rgba.substr(5).split(")")[0].split(sep);

    // Strip the slash if using space-separated syntax
    if (rgba.indexOf("/") > -1) rgba.splice(3, 1);

    for (let R in rgba) {
      let r = rgba[R];
      if (r.indexOf("%") > -1) {
        let p = r.substr(0, r.length - 1) / 100;

        if (R < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }

    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length === 1) r = "0" + r;
    if (g.length === 1) g = "0" + g;
    if (b.length === 1) b = "0" + b;
    if (a.length === 1) a = "0" + a;

    return setColorFormat("#" + r + g + b + a);
  }

  return (
    <div className="containerPicker">
      <h3 className="descriptionNewColorUi">{description}</h3>

      <div className="picker">
        <div
          className="swatch"
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
        >
          <span>{colorFormat}</span>
        </div>

        {isOpen && (
          <div className="popover" ref={popover}>
            <RgbaStringColorPicker
              color={color}
              onChange={(value) => debounced(value)}
            />
            <HexColorInput
              className="colorPickerInput"
              color={color}
              onChange={(value) => debounced(value)}
              placeholder="Type a color"
              prefixed
            />
          </div>
        )}
      </div>
    </div>
  );
}
