import React from "react";

export default function CloseIcon({ size = 12, color = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.286"
      height="16"
      viewBox="0 0 18.286 16"
    >
      <path
        id="FontAwsome_window-close_"
        data-name="FontAwsome (window-close)"
        d="M16.571,32H1.714A1.715,1.715,0,0,0,0,33.714V46.286A1.715,1.715,0,0,0,1.714,48H16.571a1.715,1.715,0,0,0,1.714-1.714V33.714A1.715,1.715,0,0,0,16.571,32Zm0,14.071a.215.215,0,0,1-.214.214H1.929a.215.215,0,0,1-.214-.214V33.929a.215.215,0,0,1,.214-.214H16.357a.215.215,0,0,1,.214.214Zm-3.839-8.264L10.539,40l2.193,2.193a.427.427,0,0,1,0,.6l-.8.8a.427.427,0,0,1-.6,0L9.143,41.4,6.95,43.589a.427.427,0,0,1-.6,0l-.8-.8a.427.427,0,0,1,0-.6L7.746,40,5.554,37.807a.427.427,0,0,1,0-.6l.8-.8a.427.427,0,0,1,.6,0L9.143,38.6l2.193-2.193a.427.427,0,0,1,.6,0l.8.8a.42.42,0,0,1,0,.6Z"
        transform="translate(0 -32)"
        fill={color}
      />
    </svg>
  );
}
