import React, { useState, useCallback } from "react";
import ReactInputMask from "react-input-mask";
import CurrencyInput from "react-currency-masked-input";
import { useDebouncedCallback } from "use-debounce/lib";

// ICONES
import ConfigurationIcon from "../../../Icons/ConfigurationIcon";
import MoverIcon from "../../../Icons/MoverIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import DateIcon from "../../../Icons/DateIcon";
import Watch from "../../../Icons/WatchIcon";
import TelephoneIcon from "../../../Icons/TelephoneIcon";
import EnvelopeIcon from "../../../Icons/EnvelopeIcon";
import CifraoIcon from "../../../Icons/CifraoIcon";
import AlertCircleIcon from '../../../Icons/AlertCircleIcon';
import ModalEditForm from "./ModalEditForm";

function InputSimple({ item, onChange, onDelete, onItems, styles }) {
  const [isFocused, setIsFocused] = useState(false);
  const options = item.config
  const idField = item.id;
  const [modal, setModal] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const debounced = useDebouncedCallback(
    (e, idField) => {
      onChange(e, idField);
    },
    600,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 2000 },
  );

  return (
    <div
      className="containerTypeText"
      style={{
        background: styles.colorPrimary,
        borderRadius: styles.borderRadius,
        border: isFocused
          ? "1px solid" + styles.colorSecundary
          : "1px solid" + styles.colorPrimary,
      }}
    >
      {options.inputType === "text" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <ReactInputMask
            type="text"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "Digite uma pergunta"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
            minLength={options.minlength} 
            maxLength={options.maxlength}
          />
        </label>
      )}

       {options.inputType === "num" && (
        <label>
          <ReactInputMask
            type="num"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "Digite uma pergunta"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
            minLength={options.minlength} 
            maxLength={options.maxlength}
          />
        </label>
      )}
      
      {options.inputType === "date" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <DateIcon color={isFocused ? styles.colorSecundary : ""} />
          <ReactInputMask
            mask="99/99/9999"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "99/99/9999"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "hour" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <Watch color={isFocused ? styles.colorSecundary : ""} />
          <ReactInputMask
            mask="99:99"
            type="hour"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "__:__"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "datetime-local" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <DateIcon color={isFocused ? styles.colorSecundary : ""} />
          <ReactInputMask
            mask="99/99/9999-99-99"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "99/99/9999-12:59"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "tel" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <TelephoneIcon color={isFocused ? styles.colorSecundary : ""} />
          <ReactInputMask
            type='tel'
            mask="(99) 9999-99999"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "(99) 9999-99999"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "email" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <EnvelopeIcon color={isFocused ? styles.colorSecundary : ""} />
          <ReactInputMask
            type="email"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "Digite um email"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
            minLength={options.minlength} 
            maxLength={options.maxlength}
          />
        </label>
      )}

      {options.inputType === "money" && (
        <label onFocus={handleInputFocus} onBlur={handleInputBlur}>
          <CifraoIcon color={isFocused ? styles.colorSecundary : ""} />
          <CurrencyInput
            type="money"
            separator=","
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "R$: 129,99"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
            minLength={options.minlength} 
            maxLength={options.maxlength}
          />
        </label>
      )}

      {options.inputType === "cpf" && (
        <label>
          <ReactInputMask
            type="cpf"
            mask="999.999.999-99"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "999.999.999-99"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "cnpj" && (
        <label>
          <ReactInputMask
            type="cnpj"
            mask="99.999.999/9999-99"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "99.999.999/9999-99"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      {options.inputType === "rg" && (
        <label>
          <ReactInputMask
            type="rg"
            mask="99.999.999-9"
            style={{ color: styles.colorText }}
            placeholder={options.placeholder ? options.placeholder : "99.999.999-9"}
            name={options.name}
            defaultValue={options.valueInput || ''}
            onChange={(e) => debounced(e.target.value, idField)}
            required={options.required}
          />
        </label>
      )}

      <div className="boxHeaderRight">
          { options.required &&
            <div className="required">
              <div className="error">
                <AlertCircleIcon color={styles.colorSecundary} />
              </div>
            </div>
          }
        <div className="buttonsRight">
          <button
            type="button"
            onClick={() => onDelete(idField)}>
            <TrashIcon width={12} height={13} color={styles.colorText} />
          </button>
          <button
            type="button"
            onClick={() => setModal(!modal)}
          >
            <ConfigurationIcon size={13} color={styles.colorText} />
          </button>
          <button
            className="moveBox"
            type="button"
          >
            <MoverIcon color={styles.colorText} />
          </button>
        </div>
      </div>

    {modal && (
        <ModalEditForm
          id={'id'}
          onClose={() => setModal(!modal)}
          options={options}
          setOptions={(items) => onItems(items, idField)}
        />
      )}
    </div>
    
  );
}

export { InputSimple };
