import React, { useState, useRef, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import widgetStore from "../../../Store/WidgetStore";
import WidgetRender from "../../TemplateCreation/WidgetRender/WidgetRender";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import Galery from "../../Ui/GaleryUi/Galery/Galery";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";
import Select from "../../Ui/Select/Select";
import PositionUi from "../../Ui/PositionUi";

//ICONS
import MetroPinIcon from "../../../Icons/MetroPinIcon";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";

import "./EditColumn.scss";

const ColumnElement = (props) => {
  const [items, setItems] = useState(...[props.row]);
  const options = props.item.options;
  const styleOptions = {
    backgroundColor: !options.stiky ? options.colorPrimary : "transparent",
    opacity: options.opacity,
    padding: options.padding,
    backgroundImage: !options.stiky
      ? `url("${options.midia?.value}")`
      : "none",
  };

  const styleVideo = {
    backgroundColor: "transparent",
    opacity: options.opacity,
    padding: options.padding,
    zIndex: '10',
    minHeight: '160px',
  };

  useEffect(() => {
    setItems(...[props.row]);
    widgetStore.setState();
  }, [props, items]);

  function handleSetItems(state, e) {

    if(state[0]?.template.cat.includes('secao') === true) {
    } else if (e && props.edit === true){
      setItems(state);
      props.handleSetList(state, props.keyElement);
    }
  }

  return (
    <div className="item-column" style={options.midia?.type === 'imagem' ? styleOptions : styleVideo}>
      <ReactSortable
        group="shared"
        animation={150}
        list={items}
        setList={(state, e) => handleSetItems(state, e)}
        style={{justifyContent: options.positionItem, flexDirection: options.positionItem === 'left' | options.positionItem === 'right' ? 'row' : 'column'}}
      >
        {items &&
          items.map((item, itemkey) => (
            <WidgetRender
              id={item.id}
              item={item.template}
              indice={props.keyElement}
              key={itemkey}
              editId={props.editId}
              template={props.template}
              editWidget={props.editWidget}
              removeWidget={props.handleRemoveList}
              edit={props.edit}
              setLoading={props.setLoading}
              stateButton={props.stateButton}
              setPageId={props.setPageId}
              pageId={props.pageId}
            />
          ))}
      </ReactSortable>
    </div>
  );
};

const Column = (props) => {
  const elementRef = useRef(null);
  const [content, setContent] = useState([]);

  const verifyContent = content[0]?.map((row) => row?.template.cat)[0]

  useEffect(() => {
    setContent(...[props.item.content]);
    widgetStore.setState();
  }, [props]);

  function handleSetList(state, indice) {
    var newContent = [];

    content.map((row, key) => {
      newContent[key] = row;

      if (key === indice) {
        newContent[key] = state;
      }
      return null
    });

    props.updateWidgetColumn(newContent, props.id);
    setContent(...[newContent]);
  }

  function handleRemoveList(id) {
    var newContent = [];
    content.map((row, key) => {
      return newContent[key] = row.filter((obj) => obj.id !== id);
    });

    props.updateWidgetColumn(newContent, props.id);
    setContent(...[newContent]);
  }

  const options = props.item.options;
  const styleOptions = {
    color: options.colorText,
    gap: options.gap,
    width: options.width,
    margin: "0 auto",
    // top: options.position === 'top' ? '0' : '',
    bottom: options.position === 'bottom' ? '0' : '',
  };

  return (
    <div
      id="ContainerColumn"
      style={{
        opacity: options?.opacity,
        backgroundImage: options.midia?.type === 'imagem'
          ? `url("${options.midia.value}")`
          : "none",
      }}
    >

      {options.midia?.type === 'video' ? (
        <div className="videoBg">
          <video autoPlay loop muted src={options.midia?.value} type="video/mp4" />
        </div>
      ) : null}


      <div
        ref={elementRef}
        className={`columns ${options.fixed ? 'fixed' : ''}`}
        style={styleOptions}
      >
        {content.map((row, key) => (
          <ColumnElement
            key={key}
            edit={props.edit}
            handleRemoveList={handleRemoveList}
            handleSetList={handleSetList}
            row={row}
            keyElement={key}
            {...props}
          />
        ))}
      </div>
      {options.fixed && verifyContent !== 'funcao' && (
        <div
          style={{height: elementRef.current?.clientHeight}}
          className="columnsFixed"
        />
      )}
    </div>
  );
};

const ColumnEdit = ({ item, onSave }) => {
  const [options, setOptions] = useState(item.options);
  const [openGaleryModal, setOpenGaleryModal] = useState(false);

  useEffect(() => {
    widgetStore.setState();
  }, [item, onSave]);

  function handleOnChange(name, value) {
    options[name] = value;
    onSave(options);
    setOptions(options);
  }

  function handleToggleInputRange() {
    handleOnChange("fixed", !options.fixed);

    if(options.fixed === true) {
      handleOnChange("position", 'top');
    } else {
      handleOnChange("position", 'initial');
    }
  }

  function handleOnStiky() {
    handleOnChange("stiky", !options.stiky);
  }

  return (
    <div id="editColumn">
      <div className="headerPanelRight">
        <h2>Editar colunas</h2>
      </div>

      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Largura"}
          defaultValue={options.width.replace("%", "")}
          onChange={(value) => handleOnChange("width", value + "%")}
        />

        <Galery
          onClose={() => setOpenGaleryModal(!openGaleryModal)}
          handleOnImage={(value, type) => handleOnChange("midia", {value, type})}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Margem"}
          defaultValue={options.padding.replace("px", "")}
          onChange={(value) => handleOnChange("padding", value + "px")}
        />

        <PositionUi
          options={[
            {
              label: "left",
              value: "left",
            },
            {
              label: "center",
              value: "center",
            },
            {
              label: "right",
              value: "right",
            },
          ]}
          value={options.positionItem}
          onChange={(value) => handleOnChange("positionItem", value)}
        />

        <LinkUi onChange={handleToggleInputRange} value={options.fixed}>
          <MetroPinIcon />
          Fixar coluna
        </LinkUi>

        <Select
          onChange={(value) => handleOnChange("position", value)}
          value={options.position}
          placeholder="Selecione posição da coluna"
          disabled={!options.fixed}
        >
          <option value="top">Posição top</option>
          <option value="bottom">Posição bottom</option>
        </Select>

        <LinkUi onChange={handleOnStiky} value={options.stiky}>
          <ResizeWidthIcon />
          Esticar imagem
        </LinkUi>
      </div>
    </div>
  );
};

export { Column, ColumnEdit };
