import React from "react";

export default function InformationIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.167"
      height="24.167"
      viewBox="0 0 24.167 24.167"
    >
      <g
        id="Icon_feather-info"
        data-name="Icon feather-info"
        transform="translate(1 1)"
      >
        <path
          id="Caminho_7"
          data-name="Caminho 7"
          d="M30.5,19.417A11.083,11.083,0,1,1,19.417,8.333,11.083,11.083,0,0,1,30.5,19.417Z"
          transform="translate(-8.333 -8.333)"
          fill="none"
          stroke="#0766ff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_8"
          data-name="Caminho 8"
          d="M50,54.433V50"
          transform="translate(-38.917 -38.917)"
          fill="none"
          stroke="#0766ff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_9"
          data-name="Caminho 9"
          d="M50,33.333h0"
          transform="translate(-38.917 -26.683)"
          fill="none"
          stroke="#0766ff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
