import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import TipUi from "../../Ui/TipUi/TipUi";
import NewEditTextUi from "../../Ui/NewEditTextUi";
import copy from "copy-to-clipboard";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";

//ICONS
import CopyIcon from "../../../Icons/CopyIcon";

import "./EditText.scss";

function Text(props) {
  const options = props.item.options;
  const value = options.text;
  const stringValue = value?.replace(/<[^>]*>?/gm, "");

  const copyToClipboard = () => {
    copy(stringValue);
  };

  return (
    <>
      <div id="containerTextEdit">
        <CopyToClipboard
          options={{ debug: props.debug, message: "" }}
          text={options.copy === true ? stringValue : ""}
        >
          <div className="buttonCopyText">
            <footer
              style={{ color: options.colorText }}
              dangerouslySetInnerHTML={{ __html: options.text }}
            />
          </div>
        </CopyToClipboard>

        {options.copy && (
          <button
            style={{
              backgroundColor: options.colorButtonPrimary,
              borderRadius: options.borderButtonRadius,
            }}
            className="buttonTextDefault"
            type="submit"
            onClick={copyToClipboard}
            dangerouslySetInnerHTML={{ __html: options.textButton }}
          />
        )}

      </div>
    </>
  );
}

function TextEdit({ item, onSave }) {
  const [options, setOptions] = useState(item.options);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  function handleToggleInputRange() {
    handleOnChange("copy", !options.copy);
  }

  return (
    <div id="editText">
      <div className="headerPanelRight">
        <h2>Editar texto</h2>
      </div>
      <div className="mainPanelRight">

        <LinkUi onChange={handleToggleInputRange} value={options.copy}>
          <CopyIcon size={18} />
          Copiar para área <br /> de transferencia?
        </LinkUi>

        <NewEditTextUi
          value={options.text}
          options={{
            color: options.colorText,
          }}
          onChangeText={(value) => handleOnChange("text", value)}
        />

        {options.copy && (
          <>
            <div className="headerPanelRight" style={{ marginBottom: "0" }}>
              <h2>Editar botão</h2>
            </div>

            <ColorUi
              description={"Cor principal"}
              value={options.colorButtonPrimary}
              onChange={(value) => handleOnChange("colorButtonPrimary", value)}
              isDisabled
            />

            <NewEditTextUi
              value={options.textButton}
              options={{
                color: options.colorText,
              }}
              onChangeText={(value) => handleOnChange("textButton", value)}
            />

            <NewRange
              icon={<ResizeWidthIcon />}
              description={"Arredondamento da borda"}
              defaultValue={options.borderButtonRadius?.replace("px", "")}
              onChange={(value) =>
                handleOnChange("borderButtonRadius", value + "px")
              }
            />
          </>
        )}


        <TipUi>
          Você pode utilizar os dados do contato usando a tag.{" "}
          <Link to="#">Saiba mais.</Link>
        </TipUi>
      </div>
    </div>
  );
}

export { TextEdit, Text };
