import React from "react";

export default function ClockIcon({ size = 12, color = "#676767" }) {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#676767" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 16L12.586 13.586C12.2109 13.211 12.0001 12.7024 12 12.172V6" stroke="#676767" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}