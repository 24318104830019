import React, { useState } from "react";

import CreatableSelect from "react-select/creatable";
import "./styles.scss";

export default function SelectTwo({
  valueOption,
  placeholder,
  defaultOptions,
  handleChange,
  createOption,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  const handleCreate = (inputValue) => {
    setIsLoading(true);

    setTimeout(() => {
      setOptions([]);
      const newOption = createOption(inputValue);

      setIsLoading(false);
      setOptions([...options, newOption]);
      handleChange(newOption);
    }, 1000);
  };

  return (
    <div id="containerSelectCreate">
      <CreatableSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={options}
        value={valueOption}
        placeholder={placeholder}
        className="react-select-container"
      />
    </div>
  );
}
