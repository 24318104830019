import React from "react";

import "./styles.scss";

export default function ModalDeleteAlbum({
  id,
  onClose,
  handleOnDelete,
  message,
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <div id={id} onClick={handleCloseModal} className="overlayGaleryDelete">
      <div id="containerGaleryDelete">
        <header>
          <h2>Deletar mídia</h2>
        </header>

        <main>
          <div className="questionName">
             <h1>{`Deseja deletar ${message} ?`}</h1>
          </div>
        </main>
        <footer>
          <button className="buttonCancel" type="button" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="buttonSave delete"
            type="button"
            onClick={handleOnDelete}
          >
            Deletar
          </button>
        </footer>
      </div>
    </div>
  );
}
