import React from "react";

import "./styles.scss";

export default function ButtonDefault({
  Icon,
  children,
  title,
  type,
  width,
  ...rest
}) {
  return (
    <button
      type="button"
      {...rest}
      style={{ width: width ? width : "100%" }}
      className={`containerButtonDefault ${
        type === "default"
          ? "default"
          : "default" && type === "defaultDark"
          ? "defaultDark"
          : "default" && type === "register"
          ? "register"
          : "default" && type === "signIn"
          ? "signIn"
          : "default" && type === "cancel"
          ? "cancel"
          : "default"
      }`}
    >
      {Icon && Icon}
      <span>{title}</span>
    </button>
  );
}
