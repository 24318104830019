import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/pt-br";

import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import InputTypeDate from "../../Ui/InputTypeDate/InputTypeDate";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONS
import OpenTextIcon from "../../../Icons/OpenTextIcon";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import ExclamationIcon from "../../../Icons/ExclamationIcon";

import "./EditCount.scss";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import ExternalLinkIcon from "../../../Icons/ExternalLinkIcon";

const Count = (props) => {
  const options = props.item.options;
  const [partyTime, setPartyTime] = useState(false);

  const styleOptions = {
    backgroundColor: options.colorPrimary,
    borderRadius: options.borderRadius,
    color: options.colorText,
  };

  const calculateTimeLeft = () => {
    const difference = +new Date(`${options.date}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      if (
        timeLeft.days <= 0 &&
        timeLeft.hours <= 0 &&
        timeLeft.minutes <= 0 &&
        timeLeft.seconds <= 0
      ) {
        setPartyTime(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>,
    );
  });
  
  return (
    <div id="CountanierCount" style={styleOptions}>
      <header style={{ color: options.colorText }}>
        <ExclamationIcon color={options.colorText} />
        {!partyTime ? (
          <>
            {`${options.expiration + "\u00A0"}`}
            <Moment fromNow>{options.date}</Moment>
          </>
        ) : (
          "Contador encerrado!!"
        )}
      </header>
      <main>
        <div className="conteinerTime">
          <div className="time">
            <span style={{ backgroundColor: options.colorSecundary }}>
              {timeLeft.days || "0"}
            </span>
          </div>
          <b style={{ color: options.colorText }}>dias</b>
        </div>
        <span>:</span>
        <div className="conteinerTime">
          <div className="time">
            <span style={{ backgroundColor: options.colorSecundary }}>
              {timeLeft.hours || "0"}
            </span>
          </div>
          <b style={{ color: options.colorText }}>hrs</b>
        </div>
        <span>:</span>
        <div className="conteinerTime">
          <div className="time">
            <span style={{ backgroundColor: options.colorSecundary }}>
              {timeLeft.minutes || "0"}
            </span>
          </div>
          <b style={{ color: options.colorText }}>min</b>
        </div>
        <span>:</span>
        <div className="conteinerTime">
          <div className="time">
            <span style={{ backgroundColor: options.colorSecundary }}>
              {timeLeft.seconds || "0"}
            </span>
          </div>
          <b style={{ color: options.colorText }}>seg</b>
        </div>
      </main>
    </div>
  );
};

const CountEdit = ({ id, item, onSave }) => {
  const [options, setOptions] = useState(item.options);
  const [closePage, setClosePage] = useState(item.options.closePage);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options, id);
  }

  function handleToggleInputRange() {
    setClosePage(!closePage);
    handleOnChange("closePage", !closePage);

    if (closePage === true) {
      // Insira a lógica  aqui
      handleOnChange("closePage", true);
    }
  }

  return (
    <div id="editCount">
      <div className="headerPanelRight">
        <h2>Editar contagem</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <InputPrimary
          placeholder="Digite aqui"
          defaultValue={options.expiration}
          onChange={(value) => handleOnChange("expiration", value)}
        >
          <OpenTextIcon />
        </InputPrimary>

        <InputTypeDate
          placeholder="dd-mm-yyyy"
          onChange={(value) => handleOnChange("date", value)}
          value={options.date}
        />

        {/* <InputTypeTime
          value={options.time}
          onChange={(value) => handleOnChange("time", value)}
        /> */}

        <LinkUi
          onChange={() => handleToggleInputRange(closePage)}
          value={closePage}
        >
          <ExternalLinkIcon />
          Desativar page
        </LinkUi>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
};

export { CountEdit, Count };
