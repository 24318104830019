import React from "react";

export default function BateryIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18H18C19.103 18 20 17.103 20 16V14H22V10H20V8C20 6.897 19.103 6 18 6H4C2.897 6 2 6.897 2 8V16C2 17.103 2.897 18 4 18ZM4 8H18L18.002 16H4V8Z"
        fill={color}
      />
    </svg>
  );
}
