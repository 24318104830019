import React from "react";

import "./styles.scss";

export default function ButtonControllers({
  children,
  title,
  active,
  ...rest
}) {
  return (
    <div className="containerButtonControllers">
      <button className={active ? "controllersActive" : ""} {...rest}>
        {children}
        <span className="tooltipButtonControllers">{title}</span>
      </button>
    </div>
  );
}
