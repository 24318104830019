import React from "react";

export default function ExternalLinkIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="icone"
        d="M12.656,9.375h-.937a.469.469,0,0,0-.469.469v3.281H1.875V3.75H6.094a.469.469,0,0,0,.469-.469V2.344a.469.469,0,0,0-.469-.469H1.406A1.406,1.406,0,0,0,0,3.281V13.594A1.406,1.406,0,0,0,1.406,15H11.719a1.406,1.406,0,0,0,1.406-1.406V9.844A.469.469,0,0,0,12.656,9.375ZM14.3,0h-3.75a.7.7,0,0,0-.5,1.2L11.1,2.248,3.955,9.386a.7.7,0,0,0,0,1l.664.663a.7.7,0,0,0,1,0l7.137-7.139L13.8,4.951a.7.7,0,0,0,1.2-.5V.7A.7.7,0,0,0,14.3,0Z"
      />
    </svg>
  );
}
