import React from "react";

export default function PercentIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="icone"
        d="M4.265,68.265a2.5,2.5,0,1,0-3.536,0A2.5,2.5,0,0,0,4.265,68.265Zm10,6.465a2.5,2.5,0,1,0,0,3.536A2.5,2.5,0,0,0,14.265,74.73Zm-.076-9.04-.884-.884a1.25,1.25,0,0,0-1.768,0L.806,75.538a1.25,1.25,0,0,0,0,1.768l.884.884a1.25,1.25,0,0,0,1.768,0L14.19,67.457A1.25,1.25,0,0,0,14.19,65.689Z"
        transform="translate(0.003 -63.997)"
      />
    </svg>
  );
}
