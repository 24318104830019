import React from "react";

export default function OpenTextIcon({ size = 15, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
    >
      <path
        id="Icon_open-text"
        data-name="Icon open-text"
        d="M0,0V3.75H.938A1.881,1.881,0,0,1,2.813,1.875H5.625V12.188a.928.928,0,0,1-.937.938H3.75V15h7.5V13.125h-.937a.928.928,0,0,1-.937-.937V1.875h2.813A1.881,1.881,0,0,1,14.063,3.75H15V0Z"
      />
    </svg>
  );
}
