import React from "react";
import DateIcon from "../../../Icons/DateIcon";

import "./InputTypeDate.scss";

function InputTypeDate({ onChange = () => {}, value, ...rest }) {
  return (
    <div id="containerDate">
      <DateIcon />
      <span>{value}</span>
      <input
        placeholder="dd-mm-yyyy"
        className="datepicker-input"
        type="datetime-local"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        {...rest}
      />
    </div>
  );
}

export default InputTypeDate;
