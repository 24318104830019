import React from "react";
import "./RangeSeconds.scss";

import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} s`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

export default function RangeSeconds({
  defaultValue,
  icon,
  description,
  onChange = () => {},
}) {
  return (
    <>
      <div className="containerRange">
        <div className="descriptionRange">
          {icon}
          <span>{description ? description : "Nome do widget"}</span>
        </div>

        <Slider
          min={5}
          max={30}
          defaultValue={defaultValue ? defaultValue : 0}
          handle={handle}
          trackStyle={{ backgroundColor: "#0766FF", height: "8px" }}
          railStyle={{
            backgroundColor: "rgba(255, 255, 255, 0.49)",
            height: "8px",
          }}
          handleStyle={{
            borderColor: "rgb(230, 239, 255)",
            width: "20px",
            height: "20px",
          }}
          marks={{ 5: "5s", 30: "30s" }}
          onAfterChange={(event) => onChange(event)}
        />
      </div>
    </>
  );
}
