import React, { useState } from "react";
import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import NewRange from "../../Ui/NewRange/NewRange";
import VideoUi from "../../Ui/VideoUi";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import Galery from "../../Ui/GaleryUi/Galery/Galery";

//ICONS
import ExternalLinkIcon from "../../../Icons/ExternalLinkIcon";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import LinkIcon from "../../../Icons/LinkIcon";

import "./EditVideo.scss";

function Video(props) {
  const options = props.item.options;

  return (
    <div id="containerVideo">
      {options.toggleLink ? (
        <VideoUi styles={options} embedId={options.externalLink} />
      ) : (
        <div
          className="contentVideoExibition"
          style={{ borderRadius: options.borderRadius }}
        >
          <video controls style={{ borderRadius: options.borderRadius }}>
            <source src={options.internalLink} type="video/mp4" />
            <source src={options.internalLink} type="video/ogg" />
          </video>
        </div>
      )}
    </div>
  );
}

function VideoEdit({ id, item, onSave }) {
  const [options, setoptions] = useState(item.options);
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [link, setLink] = useState(options.toggleLink);
  const [openGaleryModal, setOpenGaleryModal] = useState(false);

  function handleOnChange(name, value) {
    options[name] = value;
    setoptions(options);
    onSave(options);
  }

  function handleOnVid(item) {
    if (item.includes("youtube") === false) {
      setError({
        error: true,
        message: "Favor insira URL valida",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
      let convertLink = item
        .replace("/watch?v=", "/embed/")
        .replace("&", " ")
        .split(" ");
      handleOnChange("externalLink", convertLink[0]);
    }
  }

  function handleToggleInputRange() {
    setLink(!link);
    handleOnChange("toggleLink", !link);

    if (link === true) {
      handleOnChange("internalLink", options.internalLink);
    }
  }

  return (
    <div id="editVideo">
      <div className="headerPanelRight">
        <h2>Editar video</h2>
      </div>
      <div className="mainPanelRight">
        <LinkUi onChange={() => handleToggleInputRange(link)} value={link}>
          <ExternalLinkIcon />
          Link externo
        </LinkUi>

        <InputPrimary
          placeholder="Link do Youtube"
          defaultValue={options.src}
          onChange={(value) => handleOnVid(value)}
          error={error.message}
          disabled={!link}
        >
          <LinkIcon />
        </InputPrimary>

        <Galery
          onClose={() => setOpenGaleryModal(!openGaleryModal)}
          handleOnImage={(value) => handleOnChange("internalLink", value)}
          disabled={link}
          type={"video"}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
}

export { Video, VideoEdit };
