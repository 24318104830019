import React, { useState, useCallback } from "react";

import "./InputPrimary.scss";

function InputPrimary({ onChange = () => {}, value, children, disabled, error, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <>
      <div className={`containerInputPrimary ${isFocused ? "focused" : ""} ${disabled ? "inputDisabled" : ""} ${error ? 'error' : ''}`} >
        {children}
        <input 
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
          {...rest}
        />

        {/* {error && (
          <div className="errorMessage">
            <span>{error}</span>
          </div>
        )} */}
      </div>
    </>
  );
}

export default InputPrimary;
