import React from "react";

export default function ResizeWidthIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.4"
      height="9"
      viewBox="0 0 14.4 9"
    >
      <path
        id="Icon_open-resize-width"
        data-name="Icon open-resize-width"
        d="M5.4,0,0,4.5,5.4,9V5.4H9V9l5.4-4.5L9,0V3.6H5.4Z"
      />
    </svg>
  );
}
