import React from "react";

export default function DropDownIcon({ size = 12, color = "#a2a2a2" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.119"
      height="5.786"
      viewBox="0 0 10.119 5.786"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M11.249,15.288l3.826-3.829a.72.72,0,0,1,1.021,0,.729.729,0,0,1,0,1.024l-4.336,4.339a.722.722,0,0,1-1,.021L6.4,12.486A.723.723,0,1,1,7.42,11.462Z"
        transform="translate(-6.188 -11.246)"
        fill={color}
      />
    </svg>
  );
}
