import React, { useCallback, useState } from 'react';

import DropDownIcon from '../../../Icons/DropDownIcon';

import "./Select.scss";

function Select({ onChange = () => {}, placeholder, disabled, children, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <div className={`customSelect ${disabled ? "selectDisabled" : ""}`} {...rest}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      style={{border: isFocused ? "1px solid #0766ff" : ""}}
    >
      <select
        className={`${disabled ? "selectDisabled" : ""}`}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled={true}>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      <DropDownIcon />
    </div>
  );
}

export default Select
