import React from "react";

export default function AddContentIcon2({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 19 22"
    >
      <path
        id="icone_conteudo"
        data-name="icone conteudo"
        d="M17,1.5H5a2.006,2.006,0,0,0-2,2v14H5V3.5H17Zm3,4H9a2.006,2.006,0,0,0-2,2v14a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7.5A2.006,2.006,0,0,0,20,5.5Zm0,16H9V7.5H20Z"
        transform="translate(-3 -1.5)"
        fill="#0766ff"
      />
    </svg>
  );
}
