import React, { useState } from "react";
import "./Radio.scss";

const RadioForm = ({ data = [], styles, colorItem, defaultValue, onChange = () => {}, ...rest }) => {
  const [selected, setSelected] = useState();

  function handleOnChange(value, name) {
    setSelected(value);
    onChange(value, name);
  }

  return (
    <>
      {data.map((row, key) => (
        <div
          tabIndex="-1"
          className="containerRadio"
          key={key}
          onClick={() => handleOnChange(row.id, row.name)}
          {...rest}
        >
          <div
            className="circle"
            style={
              row.id === selected
                ? { border: '4px solid #0766FF' }
                : {}
            }
          />
          <span
            style={
              row.id === selected
                ? { color: '#0766FF' }
                : { color: "var(--text)" }
            }
          >
            {row.value}
          </span>
        </div>
      ))}
    </>
  );
};

export { RadioForm };
