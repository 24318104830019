import React from "react";

import "./Button.scss";

const ButtonSignIn = ({ children, ...rest }) => {
  const disabled = false;
  return (
    <button
      className={`buttonLogin ${disabled ? "buttonDisabled" : ""}`}
      {...rest}
    >
      {children}
    </button>
  );
};

const ButtonRegister = ({ children, ...rest }) => {
  return (
    <button className="buttonRegister" {...rest}>
      {children}
    </button>
  );
};

const ButtonDefault = ({ children, ...rest }) => {
  return (
    <button className="buttonDefault" {...rest}>
      {children}
    </button>
  );
};

const ButtonCancel = ({ children, ...rest }) => {
  return (
    <button className="ButtonCancel" {...rest}>
      {children}
    </button>
  );
};

const ButtonBlur = ({ children, ...rest }) => {
  return (
    <button className="buttonBlur" {...rest}>
      {children}
    </button>
  );
};

export { ButtonSignIn, ButtonRegister, ButtonCancel, ButtonDefault, ButtonBlur };
