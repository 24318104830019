import React, { useState } from "react";
import InputDefault from "../../../Components/Form/InputDefault";
import Logo from "../../../Components/Logo/Logo";
import { ButtonSignIn } from "../../../Components/Ui/Button/Button";


//ICONES
import ArrowLeft from '../../../Icons/ArrowLeft'

import "./styles.scss";

export default function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  })

  function handleInputChange(event) {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value });

  }

  async function handleSubimit(event) {
    event.preventDefault();
    try {

      // await api.post('data', data);
  
      alert('Usuaraio cadastrado com sucesso!!')

    } catch(e) {
      console.log(e)

      alert('Mensagem de erro')
    }
  }

  return (
    <div id="containerSignUp">
      <div className="contentLeft">
        <div className="sectionlogo">
          <Logo />
        </div>
      </div>

      <div className="contentRight">
        <div className="sectionRegister">
          <div className="sectionForm">
            <h1>Faça seu cadastro</h1>

            <form onSubmit={handleSubimit}>
              <InputDefault 
                label="Nome"
                name="name"
                type="text"
                defaultValue={formData.name}
                onChange={(event) => handleInputChange(event)}
                placeholder="nome"
              />

              <InputDefault 
                label="E-mail"
                name="email"
                type="email"
                defaultValue={formData.email}
                onChange={(event) => handleInputChange(event)}
                placeholder="E-mail"
              />

              <InputDefault 
                label="Senha"
                name="password"
                type="password"
                defaultValue={formData.password}
                onChange={(event) => handleInputChange(event)}
                placeholder="Senha"
              />

              <ButtonSignIn type="submit">
                Cadastrar
              </ButtonSignIn>
            </form>

            <a href="/">
              <ArrowLeft  />
              Voltar para login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}