import React from "react";

export default function CommentDotsIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13.998"
      viewBox="0 0 16 13.998"
    >
      <path
        id="FontAwsome_comment-dots_"
        data-name="FontAwsome (comment-dots)"
        d="M4.435,37.5a1,1,0,1,0,1,1A1,1,0,0,0,4.435,37.5Zm3.5,0a1,1,0,1,0,1,1A1,1,0,0,0,7.935,37.5Zm3.5,0a1,1,0,1,0,1,1A1,1,0,0,0,11.434,37.5ZM7.935,32c-4.418,0-8,2.909-8,6.5a5.744,5.744,0,0,0,1.653,3.946,7.929,7.929,0,0,1-1.45,2.287A.75.75,0,0,0,.686,46a7.581,7.581,0,0,0,4.346-1.447,9.65,9.65,0,0,0,2.9.447c4.418,0,8-2.909,8-6.5S12.353,32,7.935,32Zm0,11.5a8.12,8.12,0,0,1-2.45-.378L4.776,42.9l-.609.431a6.719,6.719,0,0,1-1.8.906,7.837,7.837,0,0,0,.622-1.256l.331-.878-.644-.681A4.3,4.3,0,0,1,1.436,38.5c0-2.756,2.915-5,6.5-5s6.5,2.243,6.5,5S11.519,43.5,7.935,43.5Z"
        transform="translate(0.066 -32)"
      />
    </svg>
  );
}
