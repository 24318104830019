import React from "react";

export default function SetinhaIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.116" height="32.733" viewBox="0 0 33.116 32.733">
  <defs>
    <filter id="esquerda" x="0" y="0" width="33.116" height="32.733" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood floodOpacity="0.2"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#esquerda)">
    <path id="esquerda-2" data-name="esquerda" d="M8.639,51.4l-.749.749a.806.806,0,0,1-1.144,0L.188,45.59a.806.806,0,0,1,0-1.144l6.558-6.558a.806.806,0,0,1,1.144,0l.749.749a.811.811,0,0,1-.013,1.157L4.56,43.667h9.7a.808.808,0,0,1,.81.81v1.08a.808.808,0,0,1-.81.81H4.56l4.065,3.873A.8.8,0,0,1,8.639,51.4Z" transform="translate(9.05 -31.65)" fill="#fff"/>
  </g>
</svg>

  );
}
