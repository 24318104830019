import React, { useState } from "react";
import InputDefault from "../../../Form/InputDefault";
import LupaIcon from "../../../../Icons/LupaIcon";

import "./styles.scss";
import { useEffect } from "react";

export default function ModalEdit({
  id,
  onClose,
  handleOnChange,
  handleOnCreate = () => {},
  name,
  title,
  placeholder,
  error
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [switchType, setSwitchType] = useState(false)
  const [file, setFile] = useState('imagem')

  useEffect(() => {
    setFile(name.includes('.mp4') || name.includes('.3gp') || name.includes('.ogg') ? 'video' : 'imagem')
  }, [name])

  async function handleChangeFile(e) {
    setFile(e.target.files[0].type.split("/")[0])

    // if (e.target.files.length) {
    //   const data = new FormData();
    //   data.append("imagem", e.target.files[0]);

    //   let response = await api.post('pedido-upload', data);
    //   setFile(retorno.data.result)
      // handleOnChange(e)
    // }

    handleOnChange(e.target.value)
  }

  async function handleOnSubmit(event) {
    try {
      event.preventDefault();

    } catch(e) {
      console.log(e)
    }

  }


  return (
    <div id={id} onClick={handleCloseModal} className="overlayGaleryEdit">

      <div id="containerGaleryEdit">
        <form onSubmit={handleOnSubmit}>

          <header>
            <h2>{title}</h2>
          </header>

          <main>
            <div className="questionName">

              {title === "Adicionar imagem" && (
                <div className="sectionSwitchGalery">
                  <h2 className="descriptionSwitch">Selecione forma de envio</h2>
                  <div className="sectionGroupSwitch">
                    <h3 className="typeSwitch">{switchType ? 'Upload' : 'URL'}</h3>
                    <label className="switch">
                      <input 
                        type="checkbox"
                        checked={switchType}
                        onChange={() => setSwitchType(!switchType)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              )}

              {!switchType ? (
                <div className="field">
                  <InputDefault
                    type="text"
                    name={"nameAlbum"}
                    onChange={(event) => handleOnChange(event.target.value)}
                    placeholder={placeholder}
                    error={error}
                  />
                </div>
              ) : (
                <div className="upload">              
                  <InputDefault
                    type="file"
                    name={"nameAlbum"}
                    onChange={(event) => handleChangeFile(event)}
                    placeholder={'Escolher arquivo'}
                    error={error}
                    Icon={<LupaIcon />}
                    className={"uploadFile"}
                  />
                </div>
              )}

            </div>
          </main>
          <footer>
            <button className="buttonCancel" type="button" onClick={onClose}>
              Cancelar
            </button>
            <button
              className="buttonSave"
              type="button"
              onClick={() => handleOnCreate(file, name)}
              disabled={name < 1 ? true : false}
            >
              Salvar
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
}
