import React from "react";

export default function DuplicateIcon({ size = 12, color = "#252428" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.465"
      height="22.465"
      viewBox="0 0 22.465 22.465"
    >
      <g id="duplicar" transform="translate(-2 -2)">
        <path
          id="Caminho_31"
          data-name="Caminho 31"
          d="M15.546,13.5h9.209A2.046,2.046,0,0,1,26.8,15.546v9.209A2.046,2.046,0,0,1,24.756,26.8H15.546A2.046,2.046,0,0,1,13.5,24.756V15.546A2.046,2.046,0,0,1,15.546,13.5Z"
          transform="translate(-3.337 -3.337)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_32"
          data-name="Caminho 32"
          d="M6.07,16.3H5.046A2.046,2.046,0,0,1,3,14.256V5.046A2.046,2.046,0,0,1,5.046,3h9.209A2.046,2.046,0,0,1,16.3,5.046V6.07"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
