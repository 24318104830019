import React from "react";

export default function TelephoneIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="FontAwsome_phone_"
        fill={color}
        data-name="FontAwsome (phone)"
        d="M15.408.758l-3.25-.75A.755.755,0,0,0,11.3.443l-1.5,3.5a.748.748,0,0,0,.216.875l1.894,1.55A11.581,11.581,0,0,1,6.371,11.9l-1.55-1.894A.749.749,0,0,0,3.946,9.8l-3.5,1.5a.759.759,0,0,0-.437.862l.75,3.25a.75.75,0,0,0,.731.581,14.5,14.5,0,0,0,14.5-14.5A.749.749,0,0,0,15.408.758Z"
        transform="translate(0.01 0.011)"
      />
    </svg>
  );
}
