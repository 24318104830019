import React from "react";

export default function ShoppingCartIcon({ size = 12, color = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
    >
      <path
        id="FontAwsome_shopping-cart_"
        data-name="FontAwsome (shopping-cart)"
        d="M16.5,9.416l1.477-6.5A.75.75,0,0,0,17.25,2H4.975L4.689.6A.75.75,0,0,0,3.954,0H.75A.75.75,0,0,0,0,.75v.5A.75.75,0,0,0,.75,2H2.934l2.2,10.732a1.75,1.75,0,1,0,2.1.268h6.551a1.75,1.75,0,1,0,1.987-.325l.172-.759A.75.75,0,0,0,15.2,11H6.816l-.2-1h9.161A.75.75,0,0,0,16.5,9.416Z"
        fill={color}
      />
    </svg>
  );
}
