import React from "react";

export default function EditIcon({ width = 22.465, height = 22.656, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.465 22.656"
    >
      <g
        id="editar_app"
        data-name="editar app"
        transform="translate(-2 -1.626)"
      >
        <path
          id="Caminho_33"
          data-name="Caminho 33"
          d="M12.154,6H5.034A2.034,2.034,0,0,0,3,8.034V22.273a2.034,2.034,0,0,0,2.034,2.034H19.273a2.034,2.034,0,0,0,2.034-2.034V15.154"
          transform="translate(0 -1.024)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_34"
          data-name="Caminho 34"
          d="M22.679,3.45A2.158,2.158,0,0,1,25.73,6.5l-9.662,9.662L12,17.18l1.017-4.068Z"
          transform="translate(-2.898)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
