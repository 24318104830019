import React from "react";
import EditIcon from "../../../Icons/EditIcon";
import TrashIcon from "../../../Icons/TrashIcon";

import "./ButtonWidget.scss";

const ButtonWidget = (props) => {
  function remove() {
    props.setLoading(true);
    props.removeWidget(props.id);
  }

  return (
    <div
      className="button-widget-edit"
      style={{ opacity: `${props.hover === true ? "1" : "0"}` }}
    >
      <button
        className="buttonEdit"
        onClick={() =>
          props.editWidget(props.item, props.id, props.componentEdit)
        }
      >
        <EditIcon color="#fff" width={17.72} height={15.75} />
      </button>

      <button className="buttonRemove" onClick={remove}>
        <TrashIcon color="#fff" />
      </button>
    </div>
  );
};

export default ButtonWidget;
