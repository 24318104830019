import React from "react";
import Header from "../Header/Header";

import "./LayoutPrivate.scss";

export default function LayoutPrivate(props) {

  return (
    <div id="layoutPrivate">
        <Header />

        <div className="container">
            {props.children}
        </div>
    </div>
  );
}
