import React from "react";

export default function MapMarkerIcon({ width = 11.25, height = 15, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11.25 15"
    >
      <path
        id="FontAwsome_map-marker-alt_"
        data-name="FontAwsome (map-marker-alt)"
        d="M5.047,14.7C.79,8.526,0,7.893,0,5.625a5.625,5.625,0,0,1,11.25,0c0,2.268-.79,2.9-5.047,9.072a.7.7,0,0,1-1.156,0Zm.578-6.729A2.344,2.344,0,1,0,3.281,5.625,2.344,2.344,0,0,0,5.625,7.969Z"
        fill={color}
      />
    </svg>
  );
}
