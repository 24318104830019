import React from "react";

//ICONES
import SignalIcon from '../../../Icons/SignalIcon';
import BateryIcon from '../../../Icons/BateryIcon';
import WifiIcon from '../../../Icons/WifiIcon';

import "./styles.scss";

export default function PhoneTwo({
  bg,
  isHome,
  isFrame,
  page,
  bgImage,
  children,
  stiky,
}) {
  return (
    <div id="containerPhoneTwo">
      <div className="mobile">
        <div 
          className="screen"
          style={{ 
            backgroundColor: isHome ? bg : '#C1C9D8', 
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: stiky ? 'repeat' : 'no-repeat',
            backgroundSize:  stiky ? 'contain' : 'cover'  
          }}>
            <div className="sectionElements">
              {isFrame && (
                <div className="sectionBar">
                  <span className="hoursPhone">09:20</span>
                  <span className="pageName">{page}</span>
                  <div className="sectionStatusBar">
                    <SignalIcon size={18} />
                    <WifiIcon size={16} />
                    <BateryIcon size={18} />
                  </div>
                </div>
              )}
              {children}
            </div>
          {/* )} */}
        </div>
        <div className="inner"></div>
        <ul className="volume">
          <li></li>
          <li></li>
        </ul>
        <ul className="silent">
          <li></li>
        </ul>
        <ul className="sleep">
          <li></li>
        </ul>
      </div>
    </div>
  );
}
