import React from "react";

export default function ArrowLeft({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.468"
      height="15.652"
      viewBox="0 0 23.468 15.652"
    >
      <path
        id="Icon_ionic-ios-arrow-round-back"
        data-name="Icon ionic-ios-arrow-round-back"
        d="M30.4,31.554a1.065,1.065,0,0,1,.008,1.5l-4.948,4.964H44.311a1.06,1.06,0,0,1,0,2.119H25.457L30.413,45.1a1.073,1.073,0,0,1-.008,1.5,1.055,1.055,0,0,1-1.492-.008L22.2,39.828h0a1.19,1.19,0,0,1-.22-.334,1.011,1.011,0,0,1-.082-.408,1.062,1.062,0,0,1,.3-.742l6.717-6.766A1.038,1.038,0,0,1,30.4,31.554Z"
        transform="translate(-21.895 -31.255)"
      />
    </svg>
  );
}
