import React, { useState } from "react";
import ReactDOM from "react-dom";

import InputDefault from "../../../Form/InputDefault";
import { InputCheck } from "../../../Ui/InputRadio/InputRadio";

import "./styles.scss";

const portalRoot = document.getElementById("portal-root");
export default function ModalBox({
  id,
  onClose,
  setData,
  data
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };
  
  const [check, setCheck] = useState(false);

  const [formData, setFormData] = useState({
    question: data.question,
  });

  function hadleOnChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubimit(event) {
    try {
      event.preventDefault();

      const required = check;
      const data = {
        ...formData,
        placeholder: 'Digite aqui',
        required,
      };

      setData({...data});
      onClose();

    } catch (e) {
      // error
    }
  }

  return ReactDOM.createPortal(
    <div
      id={id}
      onClick={handleCloseModal}
      className="OverlayModalEditFormulario"
    >
      <div id="ContainerEditInput">
        <header>
          <h2>Configurar esse campo</h2>
        </header>

        <form onSubmit={handleSubimit}>
          <div className="contentBoxArea">

            <div className="questionName">
              <InputDefault
                placeholder="Nome da pergunta"
                defaultValue={data?.question || 'Texto'}
                onChange={(event) => hadleOnChange("question", event.target.value)}
                required
              />
            </div>

            <div className="required">
              <InputCheck
                id="obg"
                name="obg"
                value="obg"
                onClick={() => setCheck(!check)}
              >
                Tornar esse campo Obrigatório
              </InputCheck>
            </div>
          </div>
            <footer>
              <button
                className="buttonCancel"
                type="button"
                onClick={onClose}
              >
                Cancelar
              </button>
              <button
                className="buttonSave"
                type="submit"
              >
                Salvar
              </button>
            </footer>
        </form>
      </div>
    </div>,
    portalRoot,
  );
}
