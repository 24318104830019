import React from "react";

export default function PagesIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 15.421 15.421"
    >
      <g id="icon" transform="translate(1 1)">
        <path
          id="Caminho_4"
          data-name="Caminho 4"
          d="M15.044,7.333a.642.642,0,0,1,.287.068L22.4,10.935a.642.642,0,0,1,0,1.149l-7.068,3.534a.642.642,0,0,1-.575,0L7.688,12.084a.642.642,0,0,1,0-1.149L14.757,7.4A.642.642,0,0,1,15.044,7.333Zm5.632,4.176L15.044,8.694,9.412,11.51l5.632,2.816Z"
          transform="translate(-8.333 -8.333)"
          fill={color}
        />
        <path
          id="Caminho_5"
          data-name="Caminho 5"
          d="M15.044,74.652a.642.642,0,0,1-.287-.068L7.688,71.05A.642.642,0,1,1,8.263,69.9l6.781,3.39,6.781-3.39A.642.642,0,1,1,22.4,71.05l-7.068,3.534A.642.642,0,0,1,15.044,74.652Z"
          transform="translate(-8.333 -60.231)"
          fill={color}
        />
        <path
          id="Caminho_6"
          data-name="Caminho 6"
          d="M15.044,53.819a.642.642,0,0,1-.287-.068L7.688,50.217a.642.642,0,1,1,.575-1.149l6.781,3.39,6.781-3.39a.642.642,0,1,1,.575,1.149l-7.068,3.534A.642.642,0,0,1,15.044,53.819Z"
          transform="translate(-8.333 -42.932)"
          fill={color}
        />
      </g>
    </svg>
  );
}
