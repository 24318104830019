import React from "react";
import SetaIcon from "../../../Icons/SetaIcon";

import "./InputRadio.scss";

const InputRadio = ({ children, ...rest }) => {
  return (
    <>
      <div className="inputRadio">
        <label className="KeepMeConnected">
        <input 
          type="radio"
          {...rest}
         />
          {children}
        </label>
      </div>
    </>
  );
};

const InputCheck = ({ onChange = () => {}, children, ...rest }) => {
  return (
    <>
      <label className="inputCheck">
        <input 
          type="checkbox"
          onChange={(event) => onChange(event.target.value)}
          {...rest}
          />
          {children}
        <span 
          className="checkmark">
          <SetaIcon />
        </span>
      </label>
    </>
  );
};

const InputRangeSlider = ({ onChange = () => {}, children, total, ...rest }) => {
  return (
    <>
      <div id="inputRangeSlider">
        <header>
          {children}
        </header>

        <label className="switch">
          <input 
            type="checkbox"
            checked={total > 0 && "checked"}
            onChange={(value) => onChange(value)}
            {...rest}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
};

export { InputRadio, InputCheck, InputRangeSlider };
