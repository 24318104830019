import React from "react";

export default function PlusCircleIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="FontAwsome_plus-circle_"
        data-name="FontAwsome (plus-circle)"
        d="M15.5,8A7.5,7.5,0,1,0,23,15.5,7.5,7.5,0,0,0,15.5,8Zm4.355,8.347a.364.364,0,0,1-.363.363H16.71v2.782a.364.364,0,0,1-.363.363H14.653a.364.364,0,0,1-.363-.363V16.71H11.508a.364.364,0,0,1-.363-.363V14.653a.364.364,0,0,1,.363-.363H14.29V11.508a.364.364,0,0,1,.363-.363h1.694a.364.364,0,0,1,.363.363V14.29h2.782a.364.364,0,0,1,.363.363Z"
        transform="translate(-8 -8)"
        fill="#fff"
      />
    </svg>
  );
}
