import React from "react";

import "./styles.scss";

export default function InputRadioDefault({
  data,
  styles,
  ...rest
}) {

  return (
    <div id="containerRadioDefault">
      <label htmlFor={data.id}>
        <input 
          id={data.id}
          name={data.name}
          value={data.value}
          type="radio"
          defaultChecked={data.checked}
          {...rest}
        />
        <span className="radioPulse" />
          <span className="radioButton" style={{backgroundColor: data.checked && styles.colorSecundary}}>
            <span className="radioButtonInner" />
          </span>
          <span style={{color: data.checked && styles.colorText}} className="radioLabel">{data.value}</span>
      </label>
    </div>
  );
}

