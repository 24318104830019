import React from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";
const portalRoot = document.getElementById("portal-root");

export default function Modal({
  id = "overlayModal",
  onClose = () => {},
  children,
}) {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  return ReactDOM.createPortal(
    <div id={id} className="overlayModal" onClick={handleOutsideClick}>
      {children}
    </div>,
    portalRoot,
  );
}
