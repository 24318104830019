import React from "react";

export default function DropUpIcon({ size = 12, color = "#252428" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.119"
      height="5.786"
      viewBox="0 0 16 9.148"
    >
      <path
        id="drop"
        d="M14.19,14l6.05,6.055a1.139,1.139,0,0,0,1.615,0,1.153,1.153,0,0,0,0-1.62L15,11.58a1.141,1.141,0,0,0-1.577-.033l-6.9,6.888a1.144,1.144,0,1,0,1.615,1.62Z"
        transform="translate(-6.188 -11.246)"
        fill="#a2a2a2"
      />
    </svg>
  );
}
