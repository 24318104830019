import React from "react";

export default function PageIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.976"
      height="16.976"
      viewBox="0 0 16.976 16.976"
    >
      <g id="galeria" transform="translate(1 1)">
        <g id="Icon_feather-image" data-name="Icon feather-image">
          <path
            id="Caminho_12"
            data-name="Caminho 12"
            d="M14.118,12.5H25.444a1.618,1.618,0,0,1,1.618,1.618V25.444a1.618,1.618,0,0,1-1.618,1.618H14.118A1.618,1.618,0,0,1,12.5,25.444V14.118A1.618,1.618,0,0,1,14.118,12.5Z"
            transform="translate(-12.5 -12.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Caminho_13"
            data-name="Caminho 13"
            d="M31.594,30.38a1.214,1.214,0,1,1-1.214-1.214A1.214,1.214,0,0,1,31.594,30.38Z"
            transform="translate(-25.931 -25.931)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Caminho_14"
            data-name="Caminho 14"
            d="M33.777,45.712l-4.045-4.045-8.9,8.9"
            transform="translate(-19.215 -36.004)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
