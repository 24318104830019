import React, { useState } from "react";

import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONS
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import FileDownloadICon from "../../../Icons/FileDownloadICon";
import EnvelopeIcon from "../../../Icons/EnvelopeIcon";
import FacebookIcon from "../../../Icons/FacebookIcon";
import InstaIcon from "../../../Icons/InstaIcon";
import WhatsIcon from "../../../Icons/WhatsIcon";
import TelephoneIcon from "../../../Icons/TelephoneIcon";
import CommentsIcon from "../../../Icons/CommentsIcon";
import MapMarkerIcon from "../../../Icons/MapMarkerIcon";
import YoutubeIcon from "../../../Icons/YoutubeIcon";
import CommentDotsIcon from "../../../Icons/CommentDotsIcon";

//CSS
import "./EditButtonLink.scss";

function ButtonLink(props) {
  const options = props.item.options;

  const styleButton = {
    background: options.colorPrimary,
    borderRadius: options.borderRadius,
    boxShadow: "0px 5px 20px" + props.item.options.colorPrimary + "42",
  };

  const styleOptions = {
    background: options.colorSecundary,
    borderRadius: options.borderRadius,
    width: options.width,
    height: options.width,
    maxWidth: "64px",
    maxHeight: "64px",
    minWidth: "33px",
    minHeight: "33px",
  };

  return (
    <div id="containerDownload">
      <div className="contentRight">
        <button style={styleButton}>
          <div className="containerIcon" style={styleOptions}>
            {options.name === "Download" ? (
              <FileDownloadICon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Facebook" ? (
              <FacebookIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Email" ? (
              <EnvelopeIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Instagram" ? (
              <InstaIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "WhatsApp" ? (
              <WhatsIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Telefone" ? (
              <TelephoneIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "SMS" ? (
              <CommentsIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Localizacao" ? (
              <MapMarkerIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
            {options.name === "Video" ? (
              <YoutubeIcon color={options.colorPrimary} />
            ) : (
              ""
            )}
          </div>
        </button>
        <span style={{ color: options.colorText }}>{options.text}</span>
      </div>
    </div>
  );
}

function ButtonLinkEdit({ item, onSave }) {
  const [options, setOptions] = useState(item.options);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  return (
    <div id="editDownload">
      <div className="headerPanelRight">
        <h2>Editar download</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <InputPrimary
          placeholder="Texto do botão"
          defaultValue={options.text}
          onChange={(value) => handleOnChange("text", value)}
        />

        <InputPrimary
          placeholder={options.placeholder}
          onChange={(value) => handleOnChange("link", value)}
        >
          {<options.icone />}
        </InputPrimary>

        {item.name === "Whatsapp" && (
          <InputPrimary
            placeholder={'Olá Fulano, tenho interesse...'}
            onChange={(value) => handleOnChange("message", value)}
          >
            {<CommentDotsIcon />}
          </InputPrimary>
        )}

        <NewRange
          icon={<ResizeWidthIcon />}
          marks={4}
          description={"Largura"}
          defaultValue={options.width.replace("px", "")}
          onChange={(value) => handleOnChange("width", value + "px")}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
}

export { ButtonLink, ButtonLinkEdit };
