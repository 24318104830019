import React, { useState } from "react";

import Galery from "../../Ui/GaleryUi/Galery/Galery";
import NewRange from "../../Ui/NewRange/NewRange";
import SelectTwo from "../../Form/SelectTwo";
import ColorUi from "../../Ui/ColorUi";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";

//ICONES
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import PageIcon from "../../../Icons/PageIcon";

//CSS
import "./InfoGeral.scss";

function InfoGeral({ styles, handleOnStyles }) {
  const [bg, setBg] = useState(styles.bg);

  function handleOnChange(name, value) {
    handleOnStyles(name, value)
  }
  
  const createOption = (label) => ({
    label: label.replace(/[^\d]/g, "") + "px",
    value: label.replace(/[^\d]/g, "") + "px",
  });
  
  const defaultOptions = [
    createOption('8px'),
    createOption('9px'),
    createOption('10px'),
    createOption('11px'),
    createOption('12px'),
    createOption('14px'),
    createOption('16px'),
    createOption('18px'),
    createOption('20px'),
    createOption('22px'),
    createOption('24px'),
    createOption('26px'),
    createOption('28px'),
    createOption('32px'),
    createOption('36px'),
    createOption('48px'),
    createOption('72px'),
  ];

  function handleToggleInputRange() {
    setBg(!bg);
    handleOnChange("bg", !bg);

    if(bg === true) {
      handleOnChange("bg", false)
    }
  }

  function handleOnStiky() {
    handleOnChange("stiky", !styles.stiky);
  }

  return (
    <div id="editInformation">
      <div className="headerPanelRight">
        <h2>Editar informações</h2>
      </div>
      <div className="mainPanelRight">

        <ColorUi
          description={"Cor principal"}
          value={styles.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={styles.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={styles.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <LinkUi onChange={() => handleToggleInputRange(bg)} value={bg}>
        <PageIcon />
          Background
        </LinkUi>

        <Galery 
          disabled={!bg}
          handleOnImage={(value) => handleOnChange("src", value)}
          type={'imagem'}
          resolution="375x812"
        />

        <LinkUi onChange={handleOnStiky} value={!styles.stiky} disabled={!bg}>
          <ResizeWidthIcon />
          Esticar imagem
        </LinkUi>

        <SelectTwo
          defaultOptions={defaultOptions}
          createOption={createOption}
          handleChange={(value) => handleOnChange('category1', value)}
          valueOption={styles.category1}
          placeholder={'Tamanho dos titulos'}
        />

        <SelectTwo
          defaultOptions={defaultOptions}
          createOption={createOption}
          handleChange={(value) => handleOnChange('category', value)}
          valueOption={styles.category}
          placeholder={'Tamanho dos textos'}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={styles.borderRadius.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />

      </div>
    </div>
  );
}

export { InfoGeral };
