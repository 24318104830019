import React, { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import AlertCircleIcon from "../../../../Icons/AlertCircleIcon";

// ICONS
import ConfigurationIcon from "../../../../Icons/ConfigurationIcon";
import MoverIcon from "../../../../Icons/MoverIcon";
import TrashIcon from "../../../../Icons/TrashIcon";
import ModalBox from "../ModalBox";

import "./styles.scss";

export default function BoxArea({ item, onChange, onItems, onDelete, styles }) {
  const [isFocused, setIsFocused] = useState(false);
  const [modal, setModal] = useState(false);
  const idField = item.id;

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  function hadleOnDelete(id) {
    onDelete(id)
  }

  const debounced = useDebouncedCallback(
    (e, idField) => {
      onChange(e, idField);
    },
    600,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 2000 },
  );

  return (
    <div
      className={`containerBoxSelection  ${isFocused ? "focused" : ""}`}
      style={{
        background: styles.colorPrimary,
        borderRadius: styles.borderRadius,
        border: isFocused
          ? "1px solid" + styles.colorSecundary
          : "1px solid" + styles.colorPrimary,
      }}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
    >
      <div className="boxHeader">
        <div className="boxHeaderLeft">
          <span style={{ color: `${isFocused ? styles.colorSecundary : styles.colorText}` }}>
            {item.config.question}
          </span>
        </div>
        <div className="boxHeaderRight">
          {item.config.required && (
            <div className="required">
              <div className="error">
                <AlertCircleIcon color={styles.colorSecundary} />
              </div>
            </div>
          )}
          <div className="buttonsRight">
            <button
              type="button"
              onClick={() => hadleOnDelete(idField)}
            >
              <TrashIcon width={12} height={13} color={styles.colorText} />
            </button>
            <button
              type="button"
              onClick={() => setModal(!modal)
              }
            >
              <ConfigurationIcon size={13} color={styles.colorText} />
            </button>
            <button
              className="moveBox"
              type="button"
            >
              <MoverIcon color={styles.colorText} />
            </button>
          </div>
        </div>
      </div>

      <div className="boxMainSelections">
        <textarea
          style={{ color: styles.colorText }}
          placeholder={'Digite aqui'}
          defaultValue={item.config.valueInput || ''}
          onChange={(e) => debounced(e.target.value, idField)}
        />
      </div>

      {modal && (
        <ModalBox 
          id={"id"}
          onClose={() => setModal(!modal)}
          setData={(items) => onItems(items, idField)}
          data={item.config}
        />
      )}
    </div>
  );
}
