import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


// icons
import AdjustIcon from "../../Icons/AdjustIcon";
import AdjustDarkIcon from "../../Icons/AdjustDarkIcon";
import DropDownIcon from "../../Icons/DropDownIcon";
import LogoHeader from "../../Icons/LogoHeaderIcon";
import NotificationIcon from "../../Icons/NotificationIcon";
import OptionsIcon from "../../Icons/OptionsIcon";
import SearchIcon from "../../Icons/SearchIcon";
import UnreadNotificationIcon from "../../Icons/UnreadNotificationIcon";
import UserImg from "../../Images/UserImg.png";

import "./Header.scss";
import Notifications from "../widgets/Notifications";

export default function Header() {
  const [theme, setTheme] = useState(false);
  const [showModalSettings, setShowModalSettings] = useState(false);
  const [showModalNotifications, setShowModalNotifications] = useState(false);

  const openModalSettings = () => {
    setShowModalSettings(prev => !prev);
  };

  const openModalNotifications = () => {
    setShowModalNotifications(prev => !prev);
  };

  function handleTheme() {
    setTheme(!theme);
    if (theme) {
      trans()
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem('Theme', 'light');
    } else {
      trans()
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem('Theme', 'dark');
    }
  }

  useEffect(() => {
    const getTheme = localStorage.getItem('Theme');

    if(getTheme === 'dark') {
      document.documentElement.setAttribute("data-theme", "dark");
      setTheme(true)
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      setTheme(false)
    }
  }, [])

  let trans = () => {
    document.documentElement.classList.add('transition');
    window.setTimeout(() => {
        document.documentElement.classList.remove('transition')
    }, 1000)
}
  function handleLogout() {
    document.documentElement.setAttribute("data-theme", "light");
  }

  return (
    <div id="header">
      <div className="headerLogo">
        <Link to="#">
          <LogoHeader color={theme && "#fff"} />
        </Link>
      </div>

      <div className="headerContentRight">
        <div className="headerSearch">
          <button type="button">
            <SearchIcon color={theme && "#fff"} />
          </button>
          <input type="text" placeholder="Pesquisa" />
        </div>

        <div className="headerMenu">
          <ul>
            <li>
              <button>
                <OptionsIcon color={theme && "#fff"} />
              </button>
            </li>
            <li>
              <button
                className="headerIconHasNotification"
                onClick={openModalNotifications}
              >
                <NotificationIcon color={theme && "#fff"}/>
              </button>
            </li>
            <li>
              <button onClick={handleTheme}>
                {theme ? <AdjustDarkIcon /> : <AdjustIcon />}
              </button>
            </li>
          </ul>

          <Notifications
            id="modal"
            showModal={showModalNotifications}
            setShowModal={setShowModalNotifications}
          >
            <div className={` notificationModal ${showModalNotifications ? "opened" : ""}`}>
              <header>Notificações</header>
              {[0, 1, 2].map((number) => (
                <main key={number.toString()}>
                  <div className="infoNotification">
                    <div className="iconNotification">
                      <UnreadNotificationIcon />
                    </div>
                    <p>
                      But I must explain to you how all this mistaken idea of
                      denouncing pleasure. <span>há 1 semana</span>
                    </p>
                  </div>
                </main>
              ))}
              <footer>Ver todas as notificações</footer>
            </div>
          </Notifications>
        </div>

        <div
          className="headerProfile"
          onClick={openModalSettings}
        >
          <span>Kaique Stack</span>
          <div className="headerUser">
            <img src={UserImg} alt="Imagem do usuario" />
          </div>
          <button
            className={`buttonDropDown ${showModalSettings ? "opened" : ""}`}
          >
            <DropDownIcon />
          </button>

        </div>
            <Notifications
              id="modal"
              showModal={showModalSettings}
              setShowModal={setShowModalSettings}
            >
              <div className={`userModal ${showModalSettings ? "opened" : ""}`}>
                <header>Minha Conta</header>
                  <main>
                    <ul>
                      <li>
                        <Link to="#">Configurações</Link>
                      </li>
                      <li>
                        <Link to="#">Meu plano</Link>
                      </li>
                      <li>
                        <Link to="#">Carteira</Link>
                      </li>
                      <li>
                        <Link to="#">Estatísticas</Link>
                      </li>
                    </ul>
                  </main>
                <footer>
                  <Link to="/" onClick={() => handleLogout()}>Sair</Link>
                </footer>
              </div>
            </Notifications>
      </div>
    </div>
  );
}
