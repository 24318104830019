import React from "react";

export default function GmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.742"
      height="16"
      viewBox="0 0 15.742 16"
    >
      <path
        id="FontAwsome_google_"
        data-name="FontAwsome (google)"
        d="M15.742,16.187A7.429,7.429,0,0,1,8,24,8,8,0,0,1,8,8a7.693,7.693,0,0,1,5.365,2.094l-2.177,2.094C8.339,9.439,3.042,11.5,3.042,16A5.011,5.011,0,0,0,8,21.052,4.326,4.326,0,0,0,12.542,17.6H8V14.852h7.616a7.013,7.013,0,0,1,.126,1.335Z"
        transform="translate(0 -8)"
        fill="#fff"
      />
    </svg>
  );
}
