import React from "react";

export default function LupaIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="icone"
        d="M14.8,12.969l-2.921-2.921a.7.7,0,0,0-.5-.205H10.9A6.093,6.093,0,1,0,9.845,10.9v.478a.7.7,0,0,0,.205.5l2.921,2.921a.7.7,0,0,0,.993,0l.829-.829a.706.706,0,0,0,0-1Zm-8.7-2.657a4.218,4.218,0,1,1,4.219-4.218A4.216,4.216,0,0,1,6.094,10.311Zm.794-4.469-1.319-.4a.383.383,0,0,1-.257-.373.368.368,0,0,1,.346-.386h.824a.7.7,0,0,1,.376.109.24.24,0,0,0,.3-.021L7.5,4.447a.235.235,0,0,0-.017-.356,1.678,1.678,0,0,0-.919-.333V3.281a.234.234,0,0,0-.234-.234H5.86a.234.234,0,0,0-.234.234v.472a1.3,1.3,0,0,0-1.25,1.32A1.326,1.326,0,0,0,5.3,6.345l1.319.4a.383.383,0,0,1,.257.373A.368.368,0,0,1,6.53,7.5H5.707a.7.7,0,0,1-.376-.109.24.24,0,0,0-.3.021l-.344.328a.235.235,0,0,0,.017.356,1.678,1.678,0,0,0,.919.333v.477a.234.234,0,0,0,.234.234h.469a.234.234,0,0,0,.234-.234V8.434a1.3,1.3,0,0,0,1.25-1.32,1.326,1.326,0,0,0-.925-1.271Z"
      />
    </svg>
  );
}
