import React from "react";

export default function UnreadNotificationIcon({
  size = 12,
  color = "#252428",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.444"
      height="15.333"
      viewBox="0 0 20.444 15.333"
    >
      <path
        id="FontAwsome_envelope_"
        data-name="FontAwsome (envelope)"
        d="M18.528,64H1.917A1.917,1.917,0,0,0,0,65.917v11.5a1.917,1.917,0,0,0,1.917,1.917H18.528a1.917,1.917,0,0,0,1.917-1.917v-11.5A1.917,1.917,0,0,0,18.528,64Zm0,1.917v1.629c-.9.729-2.323,1.863-5.374,4.252-.672.529-2,1.8-2.931,1.785C9.3,73.6,7.963,72.327,7.291,71.8c-3.051-2.389-4.479-3.523-5.374-4.252V65.917ZM1.917,77.417V70.005c.915.729,2.213,1.751,4.19,3.3.873.687,2.4,2.2,4.115,2.194,1.706.009,3.215-1.485,4.115-2.194,1.978-1.549,3.276-2.572,4.191-3.3v7.411Z"
        transform="translate(0 -64)"
        fill="#fff"
      />
    </svg>
  );
}
