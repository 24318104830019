import React from "react";

import "./LinkUi.scss";

const LinkUi = ({ onChange, children, value, disabled, ...rest }) => {

  return (
    <>
      <div id="linkUi" className={`${disabled && 'disabled'}`}>
        <header>
          {children}
        </header>

        <label className="switch">
          <input 
            type="checkbox"
            onClick={onChange}
            defaultChecked={value}
            {...rest}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
};

export { LinkUi };
