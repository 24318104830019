import React from "react";

export default function AddPlusIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="plus"
        d="M12.571,39.429v1.143a.43.43,0,0,1-.429.429H9v3.143a.43.43,0,0,1-.429.429H7.429A.43.43,0,0,1,7,44.143V41H3.857a.43.43,0,0,1-.429-.429V39.429A.43.43,0,0,1,3.857,39H7V35.857a.43.43,0,0,1,.429-.429H8.571A.43.43,0,0,1,9,35.857V39h3.143A.43.43,0,0,1,12.571,39.429ZM16,33.714V46.286A1.715,1.715,0,0,1,14.286,48H1.714A1.715,1.715,0,0,1,0,46.286V33.714A1.715,1.715,0,0,1,1.714,32H14.286A1.715,1.715,0,0,1,16,33.714ZM14.286,46.071V33.929a.215.215,0,0,0-.214-.214H1.929a.215.215,0,0,0-.214.214V46.071a.215.215,0,0,0,.214.214H14.071A.215.215,0,0,0,14.286,46.071Z"
        transform="translate(0 -32)"
        fill="#9b9fab"
      />
    </svg>
  );
}
