import React, { useState } from "react";

import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import { useParams } from "react-router-dom/";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import Select from "../../Ui/Select/Select";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONS
import LinkIcon from "../../../Icons/LinkIcon";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import ExternalLinkIcon from "../../../Icons/ExternalLinkIcon";

import "./EditButtons.scss";

const Button = (props) => {
  const options = props.item.options;
  const styleOptions = {
    background: options.colorPrimary,
    color: options.colorText,
    borderRadius: options.borderRadius,
    border: options.border,
    width: options.width,
    boxShadow: "0px 5px 20px" + options.colorPrimary + "26",
  };

  function handleClick() {
    if (options.toggleLink === false) {
      props.setPageId(options.internalLink)
    } else if (options.externalLink.includes('http')) {
      var url = options.externalLink;
      var win = window.open(url, "_blank");
      win.focus();
    }
  }

  return (
    <div className="buttonPrimary">
      <button 
        style={styleOptions}
        onClick={handleClick}
        disabled={!props.stateButton}
      >
        {props.item.options.textButton}
      </button>
    </div>
  );
};

const ButtonEdit = ({ item, onSave }) => {
  const [options, setOptions] = useState(item.options);
  const params = useParams();
  const template =
    window.localStorage.getItem(params.id)
      ? JSON.parse(window.localStorage.getItem(params.id))
      : []

  const pages = template.pages.filter((obj) => obj.data)
  const [link, setLink] = useState(options.toggleLink)

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  function handleToggleInputRange(value) {
    setLink(!link);
    handleOnChange("toggleLink", !value);

    if(link === true) {
      handleOnChange("internalLink", '0')
    }
  }

  return (
    <div id="editButtons">
      <div className="headerPanelRight">
        <h2>Editar botão</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <InputPrimary
          placeholder="Texto do Botão"
          defaultValue={options.textButton}
          onChange={(value) => handleOnChange("textButton", value)}
        />

        <InputPrimary
          placeholder="Link do Botão"
          onChange={(value) => handleOnChange("externalLink", value)}
          defaultValue={options.externalLink}
          disabled={!link}
        >
          <LinkIcon />
        </InputPrimary>

        <Select
          onChange={(value) => handleOnChange("internalLink", value)}
          value={options.internalLink}
          placeholder="Selecione link da página"
          disabled={link}
        >
          {pages.map((row) => (
            <option 
              key={row.id} 
              value={row.pageId}
            >
              {row.nome}
            </option>
          ))}
        </Select>

        <LinkUi 
          onClick={() => handleToggleInputRange(link)}
          value={link}
        >
          <ExternalLinkIcon />
          Link externo
        </LinkUi>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />

      </div>
    </div>
  );
};

export { Button, ButtonEdit };
