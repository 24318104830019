import React, { useState } from "react";
import ReactDOM from "react-dom";
import { OptionsInputsData } from "../../../Data/OptionsInputsData";
import InputDefault from "../../../Form/InputDefault";
import { InputCheck } from "../../../Ui/InputRadio/InputRadio";
import { RadioForm } from "../../../Ui/RadioForm/Radio";

import "./styles.scss";

const portalRoot = document.getElementById("portal-root");
export default function ModalEditForm({ id, onClose, options, setOptions }) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [check, setCheck] = useState(options.required);
  const [error, setError] = useState({
    error: false,
    message: ''
  });

  const [formData, setFormData] = useState({
    inputType: '',
    placeholder: '',
    minlength: '',
    maxlength: ''
  });

  function hadleOnChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubimit(event) {
    try {
      event.preventDefault();

      const required = check;
      const data = {
        ...formData,
        required,
      };

      if (!formData.inputType) {
        throw {message: "Selecione tipo do campo"};
      }

      setOptions(data)
      onClose();

    } catch (e) {
      setError({
        error: true,
        message: e.message,
      });
    }
  }

  return ReactDOM.createPortal(
    <div id={id} onClick={handleCloseModal} className="OverlayModalEditForm">
      <div id="ContainerEditInput">
        <header>
          <h2>Configurar esse campo</h2>
        </header>

        <form onSubmit={handleSubimit}>
          <main>
            <div className="questionName">
              <InputDefault
                placeholder="Nome da pergunta"
                defaultValue={options?.placeholder || ''}
                onChange={(e) => hadleOnChange("placeholder", e.target.value)}
                required
              />
            </div>
            <div className="required">
              <InputCheck
                id="obg"
                name="obg"
                value={options?.required}
                onClick={() => setCheck(!check)}
              >
                Tornar esse campo Obrigatório
              </InputCheck>
            </div>

            <div className="TitleTypeCampo">
              <h2>Tipo do Campo</h2>
              {error && (
                <span className="errorInputType">{error.message}</span>
              )}
            </div>

            <div className="containerInputType">
              <RadioForm 
                data={OptionsInputsData}
                onChange={(value, name) => hadleOnChange("inputType", name)}
                error={error}
              />
            </div>

            <div className="minMaxCaracters">
              <InputDefault
                type="number"
                min={0}
                max={200}
                placeholder="Mínimo de caracteres"
                onChange={(event) => hadleOnChange("minlength", event.target.value)}
              />
              <InputDefault
                type="number"
                min={0}
                max={200}
                placeholder="Máximo de caracteres"
                onChange={(event) => hadleOnChange("maxlength", event.target.value)}
              />
            </div>
          </main>
          <footer>
            <button
              className="buttonCancel"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="buttonSave"
              type="submit"
            >
              Salvar
            </button>
          </footer>
        </form>
      </div>
    </div>,
    portalRoot,
  );
}
