import React, { useState } from 'react';
import widgetStore from '../../Store/WidgetStore';
import { ReactSortable } from 'react-sortablejs';

//ICONES
import AddContentIcon2 from '../../Icons/AddContentIcon2';
import DropUpIcon from '../../Icons/DropUpIcon';

import './AddFunction.scss'

export default function AddContent() {
  const newData = widgetStore.getState().filter(obj => obj.template.cat === 'funcao')
  const [close, setClose] = useState(false)

  return (
    <div id="addFunction">
    <section>
      <div className="mainAddContent" onClick={() => setClose(!close)}>
        <div className="contentDescription">
          <AddContentIcon2 />
          <h3>Adicionar funçoes</h3>
        </div>
        <div className="iconDropContent" style={close ? {transform: 'rotate(-180deg)'} : {transform: 'rotate(0deg)'}}>
          <DropUpIcon />
        </div>
      </div>
      
      <footer className={close ? 'close' : 'open'}>
        <ReactSortable
          animation={150}
          sort={false}
          list={newData} 
          setList={() => {}}
          group={
            {
              name: 'shared',
              pull: 'clone',
              put: false
            }
          }
        >
          {newData.map((row, key) => (
            <div className="drag-item" key={key}>
              {row.template.icone}
                <span>{row.template.name}</span>
            </div>
          ))}
        </ReactSortable>
      </footer>
    </section>
  </div>
  );
}
