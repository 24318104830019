import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Confetti from "confetti-react";
import ModalEditProject from "../ModalEditProject";
import ModalEdit from "../ModalEdit";
import messageStore from "../../../Store/MessageStore";
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/pt-br';

//icons
import DeleteIcon from "../../../Icons/DeleteIcon";
import DuplicateIcon from "../../../Icons/DuplicateIcon";
import EditIcon from "../../../Icons/EditIcon";
import PlusIcon from "../../../Icons/PlusIcon";
import ClockIcon from "../../../Icons/ClockIcon";

import TropaImg from '../../../Images/TropaImg.png'

import "./Mypages.scss";

export default function MyPages() {
  const history = useHistory()
  const date = new Date(); 
  const [template, setTemplate] = useState(
    window.localStorage.getItem("template")
      ? JSON.parse(window.localStorage.getItem("template"))
      : [],
  );

  const randomId = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  const [modalCreate, setModalCreate] = useState(false);

  const [modalDelete, setModalDelete] = useState(false);
  const [modalDuplicate, setModalDuplicate] = useState(false);
  const [descriptionApp, setDescriptionApp] = useState("");
  const [nameProject, setNameProject] = useState("");
  const [idProject, setIdProject] = useState(null);
  const [animationConfetti, setAnimationConfetti] = useState();

  useEffect(() => {
    saveTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  function saveTemplate() {
    window.localStorage.setItem("template", JSON.stringify(template));
  }

  async function handleSaveTemplate() {
    window.localStorage.setItem("template", JSON.stringify(template));
    messageStore.addSuccess(`${nameProject} - Page salvo.`);
  }

  function handleNewLandingPage() {
    const idRandom = randomId();
    const temp = {
      id: idRandom,
      project: nameProject,
      description: descriptionApp,
      category: "",
      analytics: "UA-XXXXXXXX",
      storytelling: false,
      imagePage: 'https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png',
      borderRadiusLogo: '0px',
      globalStyles: {
        src: "https://ts-direct.co.uk/wp-content/uploads/2017/04/img-placeholder.png",
        borderRadius: "0px",
        category: "0",
        category1: "0",
        colorPrimary: "#F5F5F5",
        colorSecundary: "#1F1F1F",
        colorText: "#000000",
        bg: false,
        stiky: false,
      },
      updateHours: date,
      new: true,
      pages: [
        {
          pageId: '0',
          nome: "Home",
          template: [],
          id: "horizontal-1",
          sourcePosition: "right",
          type: "selectorInput",
          className: "dark-node",
          data: {
            label: 'Home',
            namePage: 'Home',
          },
          position: { x: 80, y: 40 },
        },
      ],
    };

    window.localStorage.setItem(idRandom, JSON.stringify(temp));

    template.push(temp);
    setTemplate([...template]);
    handleSaveTemplate();
    setModalCreate(false);
    setDescriptionApp("");
    setNameProject("");
    handleConfetti();
  }

  function openModalDelete(id, project) {
    setIdProject(id);
    setNameProject(project);
    setModalDelete(true);
  }

  function handleOnDelete(id) {
    const newTemplate = template;
    const deleteTemplate = newTemplate.filter((obj) => obj.id !== id);
    window.localStorage.removeItem(id);

    setTemplate(deleteTemplate);
    setModalDelete(false);
  }

  function openModalDuplicate(id, project) {
    setIdProject(id);
    setNameProject(project);
    setModalDuplicate(true);
  }

  function handleOnDuplicate(id) {
    const newId = randomId()
    const newTemplate = template;
    const duplicate = newTemplate.filter((obj) => obj.id === id);

    const newDuplicate = duplicate.map((row) => ({
      id: newId,
      project: row.project,
      description: row.description,
      category: row.category,
      analytics: row.analytics,
      storytelling: row.storytelling,
      imagePage: row.imagePage,
      borderRadiusLogo: row.borderRadiusLogo,
      globalStyles: row.globalStyles,
      updateHours: date,
      new: false,
      pages: row.pages,
    }));
    window.localStorage.setItem(newId, JSON.stringify(newDuplicate[0]));
    newTemplate.push({ ...newDuplicate[0] });

    setTemplate(newTemplate);
    handleSaveTemplate();
    setModalDuplicate(false);
  }

  function handleOnCloseModal() {
    setNameProject("");
    setDescriptionApp("");
    setModalCreate(!modalCreate);
  }

  function handleConfetti() {
    const exists = JSON.parse(localStorage.getItem('template'));
    exists.map((item) => 
      setAnimationConfetti(item.id)
    );
  }

  function handleOnPage(id) {
    history.push(`/editar/${id}`)
  }

  return (
    <div id="myPages" className="effectPage">
      <div className="createNewPage">
        <button
          type="button"
          onClick={() => setModalCreate((createPageModal) => !createPageModal)}
        >
          <PlusIcon />
          <span>Criar um novo Page</span>
        </button>
      </div>
      
      {template.map((row, key) => (
          <div key={key} className="pageCreated">
            <Link to={`/editar/${row.id}`} className="divRelative" />
            <div className={`${row.id === animationConfetti ? "animated" : "no-animated"}`}>
              <Confetti recycle={false} width={324} height={350} />
            </div>
            <header>
              <div className="imagePage">
                <img src={row.imagePage ? row.imagePage : TropaImg} alt="Imagem da Page" />
              </div>
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => openModalDelete(row.id, row.project)}
                  >
                    <DeleteIcon />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => openModalDuplicate(row.id, row.project)}
                  >
                    <DuplicateIcon color="#000" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => handleOnPage(row.id)}
                  >
                    <EditIcon />
                  </button>
                </li>
              </ul>
            </header>

            <div className="descriptionMain">
              <div className="descriptionPage">
                <div
                  className="buttonTitlePage"
                >
                  {row.project}
                </div>
                <div className="sectionDescription">
                  <p>{row.description}</p>
                </div>
              </div>

              <button 
                className="dataAltered"
              >
                <ClockIcon />
                <p>
                  Ultima alteração feita <Moment fromNow>{row.updateHours}</Moment>
                </p>
                
              </button>
            </div>
          </div>
      ))}

      {modalDelete && (
        <ModalEditProject
          id="containerModalProject"
          onClose={() => setModalDelete(!modalDelete)}
          onDelete={() => handleOnDelete(idProject)}
          title={"Deletar projeto"}
          subTitle={"Deseja deletar essa Page?"}
          idProject={idProject}
          nameProject={nameProject}
          messageButtonCancel={"Cancelar"}
          messageButtonSend={"Deletar"}
        />
      )}

      {modalDuplicate && (
        <ModalEditProject
          id="containerModalProject"
          onClose={() => setModalDuplicate(!modalDuplicate)}
          onDelete={() => handleOnDuplicate(idProject)}
          title={"Duplicar Page"}
          subTitle={"Deseja duplicar essa Page? "}
          idProject={idProject}
          nameProject={nameProject}
          messageButtonCancel={"Não"}
          messageButtonSend={"Sim"}
        />
      )}

      {modalCreate && (
        <ModalEdit
          id="containerModalEdit"
          onClose={() => handleOnCloseModal()}
          onEdit={() => handleNewLandingPage(idProject)}
          handleOnDescriptionValue={(value) => setDescriptionApp(value)}
          handleOnNameValue={(value) => setNameProject(value)}
          title={"Criar nova Page"}
          description={descriptionApp}
          nameProject={nameProject}
          idProject={idProject}
        />
      )}
    </div>
  );
}
