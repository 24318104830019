import React, { useEffect, useState } from "react";
import { InputSimple } from "./InputForm";
import nextId from "react-id-generator";
import { ReactSortable } from "react-sortablejs";
import ColorUi from "../../Ui/ColorUi";
import AddForm from "../../Ui/AddForm/AddForm";
import NewRange from "../../Ui/NewRange/NewRange";
import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import BoxSelection from "./BoxSelection";
import BoxAlternative from "./BoxAlternative";
import BoxArea from "./BoxArea";
import Confirmation from "./BoxConfirmation/Confirmation";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";
import ExternalLinkIcon from "../../../Icons/ExternalLinkIcon";
import Select from "../../Ui/Select/Select";
import { useParams } from "react-router-dom";
import LinkIcon from "../../../Icons/LinkIcon";

//ICONS
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";

//CSS
import "./EditForm.scss";

function EditForm({ item, stateButton, setLoading, setPageId, edit }) {
  const [load, setLoad] = useState(false);
  const [options, setOptions] = useState(item.options);
  const styles = item.style;
  const [items, setItems] = useState({
    question: "Seleção",
    required: false,
    alternative: [
      {
        id: 1,
        name: "name",
        placeholder: "Alternativa",
        value: "Alternativa",
        checked: false,
      },
    ],
  });

  function handleSetItems(itemParams, idField) {
    setLoad(true);

    const newItems = options.fields.filter((obj) => obj.id === idField);
    const updateItems = newItems.map((row) => {
      return (row.config = itemParams);
    });
    setItems(updateItems);
    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleOnInput(itemParams, idField) {

    setLoad(true);
    const newItems = options.fields.filter((obj) => obj.id === idField);

    newItems.map((row) => {
      return (row.config = itemParams);
    });

    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleOnBoxArea(itemParams, idField) {
    setLoad(true);

    const newItems = options.fields.filter((obj) => obj.id === idField);

    const updateItems = newItems.map((row) => ({
      config: itemParams,
      id: row.id,
      type: row.type,
    }));

    const newOptions = options.fields.filter((obj) => obj.id !== idField);
    newOptions.push(updateItems[0]);
    options.fields = newOptions;

    setOptions(options);
    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleOnConfirmation(itemParams, idField) {
    setLoad(true);
    const newItem = options.fields.filter((obj) => obj.id === idField);

    const updateItem = newItem.map((row) => ({
      id: row.id,
      type: row.type,
      chosen: row.chosen,
      selected: row.selected,
      config: itemParams,
    }));

    const newOptions = options.fields.filter((obj) => obj.id !== idField);
    newOptions.push(updateItem[0]);
    options.fields = newOptions;

    setOptions(options);

    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleDeletField(idField) {
    setLoad(true);
    setLoading(true);
    const fields = options.fields.filter((obj) => obj.id !== idField);
    options.fields = fields;
    setOptions([options]);

    setTimeout(function () {
      setLoad(false);
      setLoading(false);
    }, 10);
  }

  useEffect(() => {
    setOptions(item.options);
  }, [item, load, items]);

  function handleMoveField(fields) {
    options.fields = fields;
    setOptions(...[options]);
  }

  function handleMoveFields() {
    setLoad(true);
    setOptions(...[options]);

    setTimeout(function () {
      setLoad(false);
    }, 10);
  }
  
  function handleOnChangeInput(value, idField) {
    const newItems = options.fields.filter((obj) => obj.id === idField);

    newItems.map((row) => {
      return (row.config = {
        ...row.config,
        valueInput: value,
      });
    });
  }

  function handleOnCheckRadio(id, idField) {
    const newItems = options.fields.filter((obj) => obj.id === idField);
    
    setLoad(true)
    newItems[0].config.alternative.map((row) => {
      if(row.id === id) {
        row.checked = !row.checked
      } else {
        row.checked = false
      }
      return null
    })
    
    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleOnCheck(id, idField) {
    const newItems = options.fields.filter((obj) => obj.id === idField);

    setLoad(true)
    newItems[0].config.alternative.map((row) => {
      if(row.id === id) {
        row.checked = !row.checked
      } 
      return null
    })
    
    setTimeout(function () {
      setLoad(false);
    }, 10);
  }

  function handleOnCheckConfirm(id, idField) {
    const newItems = options.fields.filter((obj) => obj.id === idField);

    setLoad(true)
    newItems[0].config.alternative.map((row) => {
      if(row.id === id) {
        row.checked = !row.checked
      } 
      return null
    })
    
    setTimeout(function () {
      setLoad(false);
    }, 10);
  }


  async function handleSubimit(event) {
    try {
      event.preventDefault();
      // Validacao do formulario
      // Todos os campos estão dentro de options.fields
      console.log(options.fields)

      // Definir para onde deve ser redirecionado
      if (options.toggleLink === false) {
        setPageId(options.internalLink)
      } else if (options.externalLink.includes('http')) {
        var url = options.externalLink;
        var win = window.open(url, "_blank");
        win.focus();
      }

    } catch (e) {
      // Validação de erro
    }
  }

  return (
    <>
      {load === false && options.fields && (
        <div id="containerFormEdit">
          <div className="contentFormEdit">
            <form onSubmit={handleSubimit}>
              <ReactSortable
                dragoverBubble={true}
                list={options.fields}
                setList={(fields) => handleMoveField(fields)}
                onUpdate={handleMoveFields}
                className="input-edit"
                animation={150}
                disabled={stateButton}
              >
                {options.fields.map((row, key) => {
                  if (row.input?.name === "InputSimple") {
                    return (
                      <InputSimple
                        onChange={(value, idField) => handleOnChangeInput(value, idField)}
                        styles={styles}
                        edit={false}
                        item={row}
                        key={key}
                        onDelete={(idField) => handleDeletField(idField)}
                        onItems={(itemParams, idField) =>
                          handleOnInput(itemParams, idField)
                        }
                      />
                    );
                  }

                  if (row.type === "selecao") {
                    return (
                      <BoxSelection
                        styles={styles}
                        edit={false}
                        item={row}
                        key={key}
                        onDelete={(idField) => handleDeletField(idField)}
                        onItems={(itemParams, idField) =>
                          handleSetItems(itemParams, idField)
                        }
                        handleOnCheck={(id, idField) => handleOnCheck(id, idField)}
                      />
                    );
                  }

                  if (row.type === "alternative") {
                    return (
                      <BoxAlternative
                        styles={styles}
                        edit={false}
                        item={row}
                        key={key}
                        onDelete={(idField) => handleDeletField(idField)}
                        onItems={(itemParams, idField) => handleSetItems(itemParams, idField)}
                        handleOnCheckRadio={(id, idField) => handleOnCheckRadio(id, idField)}
                      />
                    );
                  }

                  if (row.type === "textarea") {
                    return (
                      <BoxArea
                        styles={styles}
                        onChange={(value, idField) => handleOnChangeInput(value, idField)}
                        edit={false}
                        item={row}
                        key={key}
                        onDelete={(idField) => handleDeletField(idField)}
                        onItems={(itemParams, idField) => handleOnBoxArea(itemParams, idField)}
                      />
                    );
                  }

                  if (row.type === "confirmation") {
                    return (
                      <Confirmation
                        styles={styles}
                        edit={false}
                        item={row}
                        key={key}
                        onDelete={(idField) => handleDeletField(idField)}
                        onItems={(itemParams, idField) => handleOnConfirmation(itemParams, idField) }
                        handleOnCheckConfirm={(id, idField) => handleOnCheckConfirm(id, idField)}
                      />
                    );
                  }
                  return null
                })}
              </ReactSortable>

              <button
                style={{
                  backgroundColor: item.options.colorButtonPrimary,
                  color: item.options.colorButtonSecundary,
                  borderRadius: item.options.borderButtonRadius,
                  pointerEvents:
                  edit === true || options.linkImage === "default"
                    ? "none"
                    : "false",
                }}
                className="buttonFormDefault"
                type="submit"
                // onClick={handleClick}
              >
                {item.options.textButton}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

function FormEdit({ item, onSave }) {
  const [options, setOptions] = useState(item.options);
  const [styles, setStyles] = useState(item.style);
  const [link, setLink] = useState(options.toggleLink);
  const params = useParams();
  const template = window.localStorage.getItem(params.id)
      ? JSON.parse(window.localStorage.getItem(params.id))
      : []

  const pages = template.pages.filter((obj) => obj.data);

  useEffect(() => {
    setOptions(item.options);
  }, [item]);

  function handleOnChange(name, value) {
    options[name] = value;
    styles[name] = value;
    setOptions(options);
    setStyles(styles);
    onSave(options, styles);
  }

  function handleNewField() {
    var newField = {
      id: nextId(),
      input: {
        type: "text",
        required: false,
        placeholder: "Pergunta",
        name: "InputSimple",
        mask: "",
      },
      config: {
        required: false,
        inputType: "text",
        placeholder: "Pergunta",
        minlength: "1",
        maxlength: "20",
      },
    };

    options.fields.push(newField);
    setOptions(...[options]);
    onSave(options);
  }

  function handleNewSelecao() {
    const newField = {
      id: nextId(),
      type: "selecao",
      config: {
        question: "Seleção",
        required: false,
        alternative: [
          {
            id: nextId(),
            name: `groupSelecao-${nextId()}`,
            placeholder: "Alternativa",
            value: "Alternativa",
            checked: true,
          },
        ],
      },
    };

    options.fields.push(newField);
    setOptions(...[options]);
    onSave(options);
  }

  function handleNewAlternative() {
    var newField = {
      id: nextId(),
      type: "alternative",
      config: {
        question: "Alternativas",
        required: false,
        alternative: [
          {
            id: nextId(),
            name: `groupAlternative-${nextId()}`,
            placeholder: "Alternativa",
            value: "Alternativa",
            checked: true,
          },
        ],
      },
    };

    options.fields.push(newField);
    setOptions(...[options]);
    onSave(options);
  }

  function handleNewTextarea() {
    var newField = {
      id: nextId(),
      type: "textarea",
      config: {
        required: false,
        question: 'Texto',
      },
    };

    options.fields.push(newField);
    setOptions(...[options]);
    onSave(options);
  }

  function handleNewConfirmation() {
    var newField = {
      id: nextId(),
      type: "confirmation",
      config: {
        required: true,
        question: "Confirmar",
        placeholder: "Digite seu texto",
        text: "Aceitar os termos",
        alternative: [
          {
            id: 1,
            placeholder: "Aceitar os termos",
            value: "Aceitar os termos",
            required: false,
          },
        ],
      },
    };

    options.fields.push(newField);
    setOptions(...[options]);
    onSave(options);
  }

  function handleToggleInputRange() {
    setLink(!link);
    handleOnChange("toggleLink", !link);

    if(link === true) {
      handleOnChange("internalLink", '0')
    }
  }

  return (
    <div id="contentEditForm">
      <div className="headerPanelRight">
        <h2>Editar formulário</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <AddForm
          onClick={handleNewField}
          total={
            options.fields.filter((obj) => obj.input?.name === "InputSimple")
              .length
          }
        >
          Adicionar linha simples
        </AddForm>

        <AddForm
          onClick={handleNewTextarea}
          total={
            options.fields.filter((obj) => obj.type === "textarea")
              .length
          }
        >
          Adicionar caixa
        </AddForm>

        <AddForm
          onClick={handleNewAlternative}
          total={
            options.fields.filter((obj) => obj.type === "alternative")
              .length
          }
        >
          Adicionar alternativa
        </AddForm>

        <AddForm
          onClick={handleNewSelecao}
          total={
            options.fields.filter((obj) => obj.type === "selecao")
              .length
          }
        >
          Adicionar seleção
        </AddForm>

        <AddForm
          onClick={handleNewConfirmation}
          total={
            options.fields.filter((obj) => obj.type === "confirmation").length
          }
        >
          Adicionar confirmação
        </AddForm>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />

        <div className="headerPanelRight" style={{marginBottom: '0'}}>
          <h2>Editar botão</h2>
        </div> 

        <ColorUi
          description={"Cor principal"}
          value={options.colorButtonPrimary}
          onChange={(value) => handleOnChange("colorButtonPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorButtonSecundary}
          onChange={(value) => handleOnChange("colorButtonSecundary", value)}
        />

        <InputPrimary
          placeholder="Texto do Botão"
          defaultValue={options.textButton}
          onChange={(value) => handleOnChange("textButton", value)}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderButtonRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderButtonRadius", value + "px")}
        />

        <div className="headerPanelRight" style={{marginBottom: '0'}}>
          <h2>Página destino</h2>
        </div> 

        <LinkUi onChange={() => handleToggleInputRange(link)} value={link}>
          <ExternalLinkIcon />
          Link externo
        </LinkUi>

        <Select
          onChange={(value) => handleOnChange("internalLink", value)}
          value={options.internalLink}
          placeholder="Selecione uma página"
          disabled={link}
        >
          {pages.map((row) => (
            <option key={row.id} value={row.pageId}>
              {row.nome}
            </option>
          ))}
          <option value="0">Sem link</option>
        </Select>

        <InputPrimary
          placeholder="Link da imagem"
          onChange={(value) => handleOnChange("externalLink", value)}
          defaultValue={options.externalLink}
          disabled={!link}
        >
          <LinkIcon />
        </InputPrimary>

      </div>
    </div>
  );
}

export { EditForm, FormEdit };
