import React, { useState } from "react";
import { useEffect } from "react";
import WidgetRender from "../../Components/TemplateCreation/WidgetRender/WidgetRender";
import Modal from "../../Components/Ui/Modal/Modal";

//Image
import navigationImage from "../../Images/navigationImage.png";

//Icons
import ArrowRoundIcon from "../../Icons/ArrowRoundIcon";
import BateryIcon from "../../Icons/BateryIcon";
import SignalIcon from "../../Icons/SignalIcon";
import WifiIcon from "../../Icons/WifiIcon";

import "./styles.scss";

export default function PreviewProject() {
  const [modal, setModal] = useState(true);

  const [template, setTemplate] = useState(
    window.localStorage.getItem("@preview")
      ? JSON.parse(window.localStorage.getItem("@preview"))
      : [],
  );

  const [pageId, setPageId] = useState(template.pageId);
  const [filterTemplate, setFilterTemplate] = useState([]);
  
  const isTemplate = template.pages.filter((obj) => obj.data);
  const isConnection = template.pages.filter(obj => obj.target);
  let ultimatePage = isTemplate.filter(obj => obj.pageId === pageId)[0].id
  const currentPage = isTemplate.filter(obj => obj.pageId === pageId)[0]

  useEffect(() => {
    orderTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, pageId]);

  function changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  function orderTemplate() {
    const selectedColumns = currentPage.template.filter(
      (row) => row.template.options.fixed !== true,
    );

    let newTemplate = selectedColumns;
    const length = newTemplate.length;

    if (
      currentPage.template.filter(
        (row) => row.template.options.fixed === true,
      ) &&
      currentPage.template.filter(
        (row) => row.template.options.position === "top",
      ).length
    ) {
      newTemplate.push(
        currentPage.template.filter(
          (row) => row.template.options?.position === "top",
        )[0],
      );
      newTemplate = changePosition(newTemplate, length, 0);
    }

    if (
      currentPage.template.filter(
        (row) => row.template.options.fixed === true,
      ) &&
      currentPage.template.filter(
        (row) => row.template.options.position === "bottom",
      ).length
    ) {
      newTemplate.push(
        currentPage.template.filter(
          (row) => row.template.options?.position === "bottom",
        )[0],
      );
      newTemplate = changePosition(newTemplate, length, length);
    }

    setFilterTemplate([...newTemplate]);
  }

  // const pages = template.pages.filter((obj) => obj.data);
  // const length = pages.length;
  const [editionWidgetEdit, setEditionWidgetEdit] = useState({
    id: null,
  });

  const nextPage = () => {
    const actualPage = isTemplate.filter(obj => obj.pageId === pageId)[0]
    const actualPageId = actualPage.id
    
    const connectionPageActual = isConnection.filter(obj => obj.source === actualPageId)[0]
    const nextConnectionPage = isTemplate.filter(obj => obj.id === connectionPageActual.target)[0]

    setPageId(nextConnectionPage.pageId);
  };

  const prevPage = () => {
    const actualPage = isTemplate.filter(obj => obj.pageId === pageId)[0]
    const actualPageId = actualPage.id
    
    const connectionPageActual = isConnection.filter(obj => obj.target === actualPageId)[0]
    const lastConnectionPage = isTemplate.filter(obj => obj.id === connectionPageActual.source)[0]

    setPageId(lastConnectionPage.pageId);
  };

  if (!Array.isArray(isTemplate) || isTemplate.length <= 0) {
    return null;
  }

  function removeWidget(idItem) {
    const newTemplate = [];

    template.pages.map((row) => {
      if (row.pageId === pageId) {
        row.template = row.template.filter((obj) => obj.id !== idItem);
      }
      return newTemplate.push(row);
    });

    const { description, id, project, updateHours, category, globalStyles } =
      template;

    const newTemp = {
      id,
      project,
      description,
      category,
      globalStyles,
      pages: newTemplate,
      new: true,
      updateHours,
    };

    setTemplate(newTemp);

    if (editionWidgetEdit.id === idItem) {
      setEditionWidgetEdit({
        id: null,
      });
    }
  }

  return (
    <div id="containerPreviewProject">
      <div className="sectionBarUi">
        <span className="hoursPhone">09:20</span>
        <span className="pageName">{currentPage.nome}</span>
        <div className="sectionStatusBar">
          <SignalIcon size={18} />
          <WifiIcon size={16} />
          <BateryIcon size={18} />
        </div>
      </div>
      <div className="contentPreviewProject">
        {template &&
          filterTemplate.map((item, index) => (
            <React.Fragment key={index}>
              <WidgetRender
                id={item.id}
                item={item.template}
                style={item.style}
                removeWidget={removeWidget}
                stateButton={true}
                pageId={pageId}
                setPageId={setPageId}
              />
            </React.Fragment>
          ))}

        {template.storytelling === true && (
          <>
            <button
              className="buttonPrevPageProject"
              type="button"
              onClick={prevPage}
              disabled={pageId === '0' ? true : false}
            >
              <ArrowRoundIcon />
            </button>

            <button
              className="buttonNextPageProject"
              type="button"
              onClick={nextPage}
              disabled={isConnection.filter(obj => obj.source === ultimatePage).length === 0 ? true : false }
            >
              <ArrowRoundIcon />
            </button>
            {modal && (
              <Modal id="modal" onClose={() => setModal(!modal)}>
                <div className="containerInfoModal">
                  <img src={navigationImage} alt="como navegar" />
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
}
