import React, { useState, useCallback, useEffect } from "react";

import "./styles.scss";

function InputDefault({
  name,
  value,
  label,
  Icon,
  disabled,
  error,
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState({
    error: false,
  });

  useEffect(() => {
    setIsError({ ...error });
  }, [error]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsError({
      error: false,
    });
  }, []);

  return (
    <>
    <div id="containerInput">
      {label && (
        <label className={`${isFocused ? "focused" : ""}`} style={{color: isError.error === true && error.name === name && '#e15050'}} htmlFor={name}>{label}</label>
      )}
      <div
        className={`containerInputDefault ${isFocused ? "focused" : ""} ${
          disabled ? "inputDisabled" : ""
        } ${isError.error && isError.name === name ? "errorLabel" : ""}`}
      >

        {Icon && Icon}

        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          id={name}
          name={name}
          value={value}
          disabled={disabled}
          {...rest}
        />


      {isError.error === true && error.name === name && (
          <div className="errorMessage">
            <span>{error.message}</span>
          </div>
        )}
      </div>
    </div>
    </>
  );
}

export default InputDefault;
