import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import nextId from "react-id-generator";

import MoverIcon from "../../../../Icons/MoverIcon";
import TrashIcon from "../../../../Icons/TrashIcon";
import InputDefault from "../../../Form/InputDefault";
import { InputCheck } from "../../../Ui/InputRadio/InputRadio";

import "./styles.scss";

const portalRoot = document.getElementById("portal-root");
export default function ModalFormulario({
  id,
  onClose,
  setData,
  data,
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [item, setItem] = useState(JSON.parse(window.localStorage.getItem('@buildr:edition')));
  const [error, setError] = useState({});
  const [check, setCheck] = useState(item.required);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newItem = JSON.parse(window.localStorage.getItem('@buildr:edition'))
    setOptions([...newItem.alternative])
    setItem(newItem)
  }, [data])

  function handleOnAddOption() {
    options.push({
      id: nextId(),
      name: data?.config.alternative[0].name,
      placeholder: "Alternativa",
      checked: false,
      value: "",
    });
    setOptions([...options]);
  }

  function handleInputalternative(key, value) {
    options[key].value = value;
    setOptions(...[options]);
  }

  function handleOnDelete(id) {
    const idSelect = options.filter((item) => item.id !== id);
    setOptions([...idSelect]);
  }

  const [formData, setFormData] = useState({
    question: item.question
  });

  function hadleOnChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubimit(event) {
    try {
      event.preventDefault();

      if (!formData.question) {
        throw{
          name: "question",
          message: "Preencha a pergunta",
        };
      }

      const alternative = options;
      const required = check;
      const data = {
        ...formData,
        alternative,
        required,
      };

      setData({...data})
      onClose();

    } catch (e) {
      setError({
        error: true,
        message: e.message,
        name: e.name,
      });
    }
  }

  return ReactDOM.createPortal(
    <div
      id={id}
      onClick={handleCloseModal}
      className="OverlayModalEditFormulario"
    >
      <div className="containerModalEdit">
        <div className="sectionModalTitle">
          <h1 className="configModalEdit">Configurar esse campo</h1>
        </div>

        <form onSubmit={handleSubimit}>
          <div className="fieldModalEdit">
            <InputDefault
              placeholder="Nome da pergunta"
              defaultValue={item.question}
              label="Nome da pergunta"
              name="question"
              onChange={(event) =>
                hadleOnChange("question", event.target.value)
              }
              error={error}
            />
          </div>

          <div className="fieldModalEdit">
            <InputCheck
              id="required"
              name="required"
              value={check}
              onClick={() => setCheck(!check)}
            >
              Tornar esse campo Obrigatório
            </InputCheck>
          </div>

          <div className="fieldModalAdd">
            <div className="sectionOptions">
              <h2>Adicionar opções</h2>
              <button
                type="button"
                className="buttonAddOptions"
                onClick={handleOnAddOption}
              >
                Adicionar
              </button>
            </div>

            {options.map((item, key) => (
              <div className="alternatives" key={item.id}>
                <div
                  onClick={() => handleOnDelete(item.id)}
                  className="iconsAlternatives"
                >
                  <button 
                    type="button"
                  >
                    <TrashIcon />
                  </button>
                  <button
                    type="button"
                  >
                    <MoverIcon />
                  </button>
                </div>
                <input
                  type="text"
                  name={key}
                  defaultValue={item.value}
                  placeholder="Alternativa"
                  required
                  onChange={(event) =>
                    handleInputalternative(key, event.target.value)
                  }
                />
              </div>
            ))}
          </div>

          <div className="sectionModalButtons">
            <button
              className="buttonCancel"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="buttonSave"
              type="submit"
              // disabled={!formData.question ? true : false}
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>,
    portalRoot,
  );
}
