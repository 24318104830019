import React from 'react';
import AddPlusIcon from '../../../Icons/AddPlusIcon';

import './AddForm.scss';

function AddForm({  total, children, ...rest}) {
  return (
    <div id="container">
      <div>
        <button
          {...rest}
        >
          <AddPlusIcon />
        </button>
        {children}
      </div>

      <span className={`${total <= 0 ? 'counterZero' : ''}`}>{total}</span>
    </div>
  );
}

export default AddForm;