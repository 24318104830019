import React from "react";

export default function SignalIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 16H5V21H3V16ZM7 13H9V21H7V13ZM11 10H13V21H11V10ZM15 7H17V21H15V7Z"
        fill={color}
      />
    </svg>
  );
}
