import React from "react";

export default function AddIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30.5"
      height="20.33"
      viewBox="0 0 16.097 10.73"
    >
      <path
        id="Caminho_1"
        data-name="Caminho 1"
        d="M16.179,11.141H5.008a.509.509,0,0,1-.508-.508h0a.509.509,0,0,1,.508-.508H16.179a.509.509,0,0,1,.508.508h0A.509.509,0,0,1,16.179,11.141Z"
        transform="translate(-4.5 -6.504)"
        fill={color}
      />
      <path
        id="Caminho_2"
        data-name="Caminho 2"
        d="M16.179,17.891H5.008a.509.509,0,0,1-.508-.508h0a.509.509,0,0,1,.508-.508H16.179a.509.509,0,0,1,.508.508h0A.509.509,0,0,1,16.179,17.891Z"
        transform="translate(-4.5 -10.207)"
        fill={color}
      />
      <path
        id="Caminho_3"
        data-name="Caminho 3"
        d="M16.179,24.641H5.008a.509.509,0,0,1-.508-.508h0a.509.509,0,0,1,.508-.508H16.179a.509.509,0,0,1,.508.508h0A.509.509,0,0,1,16.179,24.641Z"
        transform="translate(-4.5 -13.911)"
        fill={color}
      />
      <path
        id="União_2"
        data-name="União 2"
        d="M1.847,4.222V2.9H.527a.528.528,0,0,1,0-1.056H1.847V.527A.528.528,0,0,1,2.9.527V1.847H4.222a.528.528,0,1,1,0,1.056H2.9V4.222a.528.528,0,1,1-1.056,0Z"
        transform="translate(11.347)"
        fill={color}
      />
    </svg>
  );
}
