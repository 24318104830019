import React from "react";

export default function MetroPinIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.04"
      height="16.267"
      viewBox="0 0 11.04 16.267"
    >
      <path
        id="Icon_metro-pin"
        data-name="Icon metro-pin"
        d="M36.357,26.323l-5.429-3.135A1.393,1.393,0,1,0,29.535,25.6l5.428,3.135a1.393,1.393,0,0,0,1.394-2.412Zm-4.478-3.39L36.1,25.371l1.436-4-2.916-1.683ZM28.767,33.2l4.039-4.907L31,27.249ZM39.284,19.164l-3.619-2.09a1.045,1.045,0,0,0-1.045,1.81l3.619,2.09a1.045,1.045,0,1,0,1.044-1.81Z"
        transform="translate(-28.767 -16.934)"
      />
    </svg>
  );
}
