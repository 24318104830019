import React, { useState, useEffect } from "react";
import ArrowLeft from "../../../../Icons/ArrowLeft";
import PlusCircleIcon from "../../../../Icons/PlusCircleIcon";
import ButtonDefault from "../../../Form/ButtonDefault";
import CardItem from "../CardItem";
import ModalDeleteAlbum from "../ModalDeleteAlbum";
import GaleryEdit from "../ModalEdit";

import "./styles.scss";

export default function AlbumModal({
  id,
  onClose,
  handlePrevioModal,
  idModal,
  handleOnImage,
  type,
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };
  const randomId = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  const [modalImage, setModalImage] = useState(false);
  const [modalDeleteImage, setModalDeleteImage] = useState(false);
  const [addressImage, setAddressImage] = useState("");
  const [idModalDelete, setIdModalDelete] = useState(null);
  const [messageDelete, setMessageDelete] = useState("");
  const [error, setError] = useState({
    name: "",
    message: "",
    error: false,
  });

  const [galery, setGalery] = useState(
    window.localStorage.getItem(`galery:${idModal}`)
      ? JSON.parse(window.localStorage.getItem(`galery:${idModal}`))
      : [],
  );

  const galeryGroup = window.localStorage.getItem(`galery`)
      ? JSON.parse(window.localStorage.getItem(`galery`))
      : []

  const [filteredGalery, setFilteredGalery] = useState(galery.images);

  useEffect(() => {
    filterGalery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, galery]);

  function filterGalery() {
    if (!type) {
      setFilteredGalery(galery.images);
    } else {
      setFilteredGalery(
        galery.images
          .map((row) => row)
          .filter((obj) => obj.type === type || obj.type === "album"),
      );
    }
  }

  function handleOnAddImage(typeFile, item) {
    if (!item.includes("http")) {
      setError({
        error: true,
        message: "Link invalido",
        name: "nameAlbum",
      });
    } else {
      const { id, title, images, type } = galery;
      const newGalery = {
        id,
        title,
        type,
        images: [
          ...images,
          {
            id: randomId(),
            item: item,
            type: typeFile,
          },
        ],
      };

      setGalery(newGalery);
      window.localStorage.setItem(`galery:${idModal}`,JSON.stringify(newGalery));

      const updateGaleryGruop = galeryGroup.filter((obj) => obj.id === idModal);

      const update = updateGaleryGruop.map((row) => ({
        id: row.id,
        images: newGalery.images,
        title: row.title,
        type: row.type,
      }));

      const updateProjectModal = galeryGroup.filter((obj) => obj.id !== idModal);
      updateProjectModal.push({ ...update[0] });

      window.localStorage.setItem(`galery`, JSON.stringify(updateProjectModal));
      setError({
        name: "",
        message: "",
        error: false,
      });
      setModalImage(false);
    }
    filterGalery();
  }

  function handleSelectImage(url, type) {
    handleOnImage(url, type);
    onClose();
  }

  function handleOnCancel() {
    setAddressImage("");
    setModalImage(!modalImage);
  }

  function handleOpenModalDelete(id, message) {
    setMessageDelete(message)
    setIdModalDelete(id);
    setModalDeleteImage(!modalDeleteImage);
  }

  function handleOnDelete() {
    const newGalery = galery;
    const selectGalery = newGalery.images.filter((obj) => obj.id !== idModalDelete);

    const { id, title, type } = newGalery;
    const updateGalery = {
      id,
      title,
      type,
      images: selectGalery,
    };

    window.localStorage.setItem(`galery:${idModal}`, JSON.stringify(updateGalery));
    setGalery(updateGalery);

    const updateGaleryGruop = galeryGroup.filter((obj) => obj.id === idModal);

    const update = updateGaleryGruop.map((row) => ({
      id: row.id,
      images: updateGalery.images,
      title: row.title,
      type: row.type,
    }));

    const updateProjectModal = galeryGroup.filter((obj) => obj.id !== idModal);
    updateProjectModal.push({ ...update[0] });

    window.localStorage.setItem(`galery`, JSON.stringify(updateProjectModal));
    filterGalery();
    setModalDeleteImage(false);
  }


  return (
    <div id={id} onClick={handleCloseModal} className="overlayAlbumModal">
      <div className="albumModal">
        <header>
          <div className="headerLeft">
            <button onClick={handlePrevioModal}>
              <ArrowLeft />
            </button>
            <div className="informationAlbum">
              <h1>{galery.title}</h1>
              <span>{filteredGalery.length} Mídias</span>
            </div>
          </div>
          <ButtonDefault
            onClick={() => setModalImage(!modalImage)}
            width={"245px"}
            Icon={<PlusCircleIcon />}
            title={"Adicionar imagem"}
            type="default"
          />
        </header>

        <main>
          <div className="containerAlbum">
            {filteredGalery.map((row) => (
              <React.Fragment key={row.id}>
                {row.type === "imagem" && (
                  <CardItem
                    handleOnDelete={() => handleOpenModalDelete(row.id, row.type)}
                    handleOnclick={() => handleSelectImage(row.item, row.type)}
                    image={row.item}
                    type={row.type}
                  />
                )}
              </React.Fragment>
            ))}

            {filteredGalery.map((row) => (
              <React.Fragment key={row.id}>
                {row.type === "video" && (
                  <CardItem
                    handleOnDelete={() => handleOpenModalDelete(row.id, row.type)}
                    handleOnclick={() => handleSelectImage(row.item, row.type)}
                    image={row.item}
                    type={row.type}
                  />
                )}
              </React.Fragment>
            ))}

            {filteredGalery.length <= 0 &&
              galery.images.map((row) => (
                <React.Fragment key={row.id}>
                  {row.type === "imagem" ? (
                    <CardItem
                      handleOnDelete={() => handleOpenModalDelete(row.id, row.type)}
                      handleOnclick={() => handleSelectImage(row.item, row.type)}
                      image={row.item}
                      type={row.type}
                    />
                  ) : (
                    <CardItem
                      handleOnDelete={() => handleOpenModalDelete(row.id, row.type)}
                      handleOnclick={() => handleSelectImage(row.item, row.type)}
                      image={row.item}
                      type={row.type}
                    />
                  )}
                </React.Fragment>
              ))}
          </div>
        </main>
      </div>
      {modalImage && (
        <GaleryEdit
          id="containerModalEdit"
          onClose={handleOnCancel}
          handleOnCreate={(typeFile, item) => handleOnAddImage(typeFile, item)}
          handleOnChange={(value) => setAddressImage(value)}
          title={"Adicionar imagem"}
          name={addressImage}
          placeholder={"URL da imagem"}
          error={error}
        />
      )}

      {modalDeleteImage && (
        <ModalDeleteAlbum
          id="containerGaleryDelete"
          onClose={() => setModalDeleteImage(!modalDeleteImage)}
          handleOnDelete={handleOnDelete}
          message={messageDelete}
          idModal={idModalDelete}
        />
      )}
    </div>
  );
}
