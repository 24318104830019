import React from "react";

export default function ArrowRoundIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.468"
      height="34.993"
      viewBox="0 0 52.468 34.993"
    >
      <path
        id="Icon_ionic-ios-arrow-round-back"
        data-name="Icon ionic-ios-arrow-round-back"
        d="M40.9,31.925a2.381,2.381,0,0,1,.018,3.353l-11.062,11.1H72.011a2.369,2.369,0,0,1,0,4.738H29.859l11.08,11.1a2.4,2.4,0,0,1-.018,3.353,2.359,2.359,0,0,1-3.335-.018L22.569,50.422h0a2.661,2.661,0,0,1-.492-.747,2.261,2.261,0,0,1-.182-.911,2.375,2.375,0,0,1,.674-1.658L37.586,31.979A2.322,2.322,0,0,1,40.9,31.925Z"
        transform="translate(-21.895 -31.255)"
        opacity="0.14"
        fill={color}
      />
    </svg>
  );
}
