import React, { useEffect, useState } from "react";

import ReactFlow, {
  Background,
  Controls,
} from "react-flow-renderer";
import { useParams } from "react-router-dom";
import ConnectionLine from "../../../Icons/ConnectionLine";
import dagre from "dagre";
import ArrowLeft from "../../../Icons/ArrowLeft";
import AddPlusIcon from "../../../Icons/AddPlusIcon";
import ModalEditPage from "../ModalEditPage";
import ModalNewPage from "../ModalNewPage";
import {Edges, EdgeInput} from "../Edges";
import messageStore from "../../../Store/MessageStore";
import Moment from "react-moment";

import "./PaginationFlow.scss";

const nodeTypes = {
  selectorNode: Edges,
  selectorInput: EdgeInput
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const randomId = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

const PaginationFlow = ({handleOnBackPanel, templateArray, setTemplateArray}) => {
  const paramsId = useParams();
  const date = new Date();
  const [elements, setElements] = useState(templateArray.pages);
  const snapGrid = [20, 20];
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalName, setModalName] = useState(false);
  const isTemplate = elements.filter((obj) => obj.data)

  const [namePage, setNamePage] = useState("Pagina 1");
  const [page, setPage] = useState("");

  useEffect(() => {
    handleNewElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, templateArray]);

  function changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  function onNodeDragStop(event, node) {
    const newPages = []

    templateArray.pages.map((row) => {
      if(row.id === node.id) {
        row.position = node.position
      }
      return newPages.push(row)
    })

    const { description, id, project, imagePage, borderRadiusLogo, category, analytics, globalStyles } = templateArray
    const newTemp = {
      id,
      project,
      description,
      category,
      imagePage,
      borderRadiusLogo,
      analytics,
      globalStyles,
      pages: newPages,
      new: true,
      updateHours: date,
    }

    setTemplateArray(newTemp)
  }

  function handleNewElements() {
    const elementsSource = [];

    templateArray.pages
      .filter((obj) => obj.source)
      .map((row) => {
        return elementsSource.push({
          id: row.id,
          source: row.source,
          sourceHandle: row.sourceHandle,
          target: row.target,
          targetHandle: row.targetHandle,
        });
      });

    templateArray.pages
      .filter((obj) => obj.data)
      .map((row) => {
        return elementsSource.push({
          data: {
            label: row.nome,
            namePage: row.nome,
            onClick: () => handleOnClick(row.nome),
            handleOn: () => handleOnBackPanel(row.pageId),
          },
          id: row.id,
          nome: row.nome,
          pageId: row.pageId,
          position: row.position,
          sourcePosition: row.sourcePosition,
          targetPosition: row.targetPosition,
          template: row.template,
          type: row.type,
        });
      });

    setElements(elementsSource);
  }

  function onElementsRemove(elementsToRemove) {
    setLoad(true);
    const newTemplate = templateArray.pages
    const newRemove = []
    newTemplate.map((row) => {
      if (elementsToRemove.filter(obj => obj.id === row.id).length === 0 ) {
        newRemove.push(row)
      }
      return null
    })

    const { description, id, imagePage, borderRadiusLogo, project, category, analytics, globalStyles } = templateArray
    const newTemp = {
      id,
      project,
      description,
      category,
      imagePage,
      borderRadiusLogo,
      analytics,
      globalStyles,
      pages: newRemove,
      new: true,
      updateHours: date,
    }

    setTemplateArray(newTemp)
    window.localStorage.setItem(paramsId.id, JSON.stringify(newTemp));
    setLoad(false);
  }

  function onElementClick(event, element) {
    setPage(element);
  }

  function onConnect(params) {

    elements.push({
      id: params.source + "_" + params.target,
      source: params.source,
      target: params.target,
      sourceHandle: null,
      targetHandle: null,
      ...params,
    });

    const { description, id, project, borderRadiusLogo, imagePage, category, analytics, globalStyles } = templateArray
    const newTemp = {
      id,
      project,
      description,
      category,
      imagePage,
      borderRadiusLogo,
      analytics,
      globalStyles,
      pages: elements,
      new: true,
      updateHours: date,
    }

    setTemplateArray(newTemp);
    window.localStorage.setItem(paramsId.id, JSON.stringify(newTemp));
  }

  function updateNewPage(data, idItem) {
    setLoad(true);

    let newItem = templateArray.pages;
    const selectPage = newItem.filter((obj) => obj.id === idItem);
    
    const updateNamePage = selectPage.map((row) => ({
      data: {
        namePage: data,
        onClick: () => handleOnClick(row.nome),
        handleOn: () => handleOnBackPanel(row.pageId),
      },
      id: row.id,
      nome: data,
      pageId: row.pageId,
      position: row.position,
      sourcePosition: row.sourcePosition,
      targetPosition: row.targetPosition,
      template: row.template,
      type: row.type,
    }));

    // const lenght = newItem.length

    let NewPage = templateArray.pages.filter((obj) => obj.id !== idItem);
    NewPage.push({ ...updateNamePage[0] });
    
    // const itemArray = newItem.map((row, key) => { return row.id === idItem && key}).filter(obj => obj ?? false)
    // NewPage = changePosition(NewPage, lenght -1, itemArray[0]);

    const { description, id, project, imagePage, borderRadiusLogo, category, analytics, globalStyles } = templateArray
    const newTemp = {
      id,
      project,
      description,
      category,
      imagePage,
      borderRadiusLogo,
      analytics,
      globalStyles,
      pages: NewPage,
      new: true,
      updateHours: date,
    }

    setTemplateArray(newTemp);
    window.localStorage.setItem(paramsId.id, JSON.stringify(newTemp));
    messageStore.addSuccess(`${templateArray.project} - Page atualizada com sucesso!`);
    setLoad(false);
    setModalName(false);
  }

  function handleOnClick(name) {
    setNamePage(name)
    setModalName(true);
  }

  function hanleOnNewConnection(target, source) {

    let newPage = templateArray.pages;
    const length = newPage.length
    newPage.push({
      id: source + "_" + target,
      source: source,
      target: target,
      sourceHandle: null,
      targetHandle: null,
    });
    newPage = changePosition(newPage, length, length);
  }

  function handleOnNewPage(data) {
    setLoad(true);
    
    const newPage = templateArray.pages;
    var lastArray = isTemplate[isTemplate.length -1];
    
    const newArray = newPage.filter(obj => obj.source)
    let newConnection = []
    let getUltimate = []
    
    if(newArray.length > 0) {
     newConnection = newArray[newArray.length -1]
     getUltimate = isTemplate.filter(obj => obj.id === newConnection.target)[0]
    } else {
      newConnection = isTemplate[isTemplate.length -1];
      getUltimate = isTemplate.filter(obj => obj.id === newConnection.id)[0]
    }

    newPage.push({
      pageId: randomId(),
      nome: data,
      template: [],
      type: "selectorNode",
      id: `horizontal-${randomId()}`,
      sourcePosition: "right",
      targetPosition: "left",
      data: { 
        data, 
        onClick: handleOnClick, 
        handleOn: handleOnBackPanel,
      },
      position: { x: lastArray.position.x + 190, y: 40 },
    });
    var lastArrayForPush = newPage.filter((obj) => obj.data).pop()
    hanleOnNewConnection(lastArrayForPush.id, getUltimate.id);
    setElements([...newPage]);
    setModal(!modal);
    window.localStorage.setItem(paramsId.id, JSON.stringify(templateArray));
    messageStore.addSuccess(`${templateArray.project} - Page criada com sucesso!`);

    setTimeout(() => {
      setLoad(false);
    }, 100);
  }

  function handleOnDelete(idItem) {
    setLoad(true);
    const newTemplate = templateArray.pages;

    const deleteTemplate = newTemplate.filter(
      (obj) => obj.id !== idItem && obj.target !== idItem && obj.source !== idItem,
    );

    const { description, id, project, imagePage, borderRadiusLogo, category, analytics, globalStyles } = templateArray
    const newTemp = {
      id,
      project,
      description,
      imagePage,
      borderRadiusLogo,
      category,
      analytics,
      globalStyles,
      pages: deleteTemplate,
      new: true,
      updateHours: date,
    }

    setTemplateArray(newTemp);
    window.localStorage.setItem(paramsId.id, JSON.stringify(newTemp));
    messageStore.addSuccess(`${templateArray.project} - Page removida.`);
    setLoad(false);
    setModalName(false);
  }

  function handleOnAddPage() {
    setNamePage('Nome da página')
    setModal(!modal)
  }

  return (
    <div id="paginationFlow">
      <div className="headerPagination">
        <div className="headerInfoLeft">
          <div className="headerBackToPage">
            <button
              className="buttonBackToPage"
              onClick={() => handleOnBackPanel('0')}
            >
              <ArrowLeft />
            </button>
            <h1>{templateArray.project}</h1>
          </div>
          <div className="headerTotalPage">
            <h2>{templateArray.pages.filter((obj) => obj.pageId >= 0).length} Páginas</h2>
            <span>Atualizada em <Moment fromNow>{templateArray.updateHours}</Moment></span>
          </div>
        </div>
        <div className="headerInfoRight">
          <button
            className="buttonSettings add"
            onClick={() => handleOnAddPage()}
          >
            <AddPlusIcon />
            Adicionar Página
          </button>
        </div>
      </div>

      <div className="containerPaginationFlow">
        <ReactFlow
          elements={elements}
          onElementClick={onElementClick}
          onElementsRemove={onElementsRemove}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}
          connectionLineComponent={ConnectionLine}
          connectionLineType="smoothstep"
          nodeTypes={nodeTypes}
          elementsSelectable={true}
          selectNodesOnDrag={false}
          snapGrid={snapGrid}
          snapToGrid={true}
          defaultZoom={1.4}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>

      {modal && (
        <ModalNewPage
          id="containerNewPage"
          onClose={() => setModal(!modal)}
          onUpdatePage={(data) => handleOnNewPage(data)}
          namePage={namePage}
          validationPage={isTemplate}
        />
      )}

      {modalName && (
        <ModalEditPage
          id="containerConfigPage"
          onClose={() => setModalName(!modalName)}
          onDelete={() => handleOnDelete(page.id)}
          onUpdate={(data) => updateNewPage(data, page.id)}
          idPage={page.id}
          namePage={namePage}
          validationPage={isTemplate}
        />
      )}
    </div>
  );
};

export default PaginationFlow;