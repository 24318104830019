import React from "react";

export default function CopyIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15.492 15.492"
    >
      <g id="copiar" transform="translate(-2 -2)">
        <path
          id="Caminho_56"
          data-name="Caminho 56"
          d="M14.849,13.5H20.92a1.349,1.349,0,0,1,1.349,1.349V20.92A1.349,1.349,0,0,1,20.92,22.27H14.849A1.349,1.349,0,0,1,13.5,20.92V14.849A1.349,1.349,0,0,1,14.849,13.5Z"
          transform="translate(-5.778 -5.778)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_57"
          data-name="Caminho 57"
          d="M5.024,11.77H4.349A1.349,1.349,0,0,1,3,10.42V4.349A1.349,1.349,0,0,1,4.349,3H10.42A1.349,1.349,0,0,1,11.77,4.349v.675"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
