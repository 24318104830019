import React from "react";

export default function AddFunctionIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.97"
      height="26.5"
      viewBox="0 0 16.709 15.327"
    >
      <g id="icon" transform="translate(1)">
        <path
          id="Subtração_2"
          data-name="Subtração 2"
          d="M11.371,12.717a.556.556,0,0,1-.392-.162h0l-3.3-3.3H.235A1.237,1.237,0,0,1-1,8.019V.249A1.223,1.223,0,0,1-.4-.815l.022-.022A.557.557,0,0,1,.018-1H9.054a.557.557,0,0,1,.432.205A.557.557,0,0,1,9.6-.33,1.125,1.125,0,0,0,9.576-.1a1.117,1.117,0,0,0,1.115,1.115h.68a.557.557,0,0,1,.557.557V12.159a.558.558,0,0,1-.558.558ZM.226.115.178.14A.114.114,0,0,0,.115.249V8.019a.12.12,0,0,0,.12.12H7.909A.557.557,0,0,1,8.3,8.3l2.512,2.512V2.128h-.123A2.233,2.233,0,0,1,8.472.115Z"
          transform="translate(0 2.61)"
          fill={color}
        />
        <path
          id="União_3"
          data-name="União 3"
          d="M1.951,4.46V3.066H.557a.558.558,0,0,1,0-1.115H1.951V.557a.557.557,0,0,1,1.114,0V1.951H4.46a.558.558,0,1,1,0,1.115H3.065V4.46a.557.557,0,1,1-1.114,0Z"
          transform="translate(10.691)"
          fill={color}
        />
      </g>
    </svg>
  );
}
