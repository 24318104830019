import React from "react";

export default function CifraoIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.436"
      height="15"
      viewBox="0 0 8.436 15"
    >
      <path
        id="icone"
        fill={color}
        d="M6.164,6.838,3,5.912a.864.864,0,0,1,.243-1.693H5.186a1.788,1.788,0,0,1,1,.308.457.457,0,0,0,.571-.059l1.02-1a.475.475,0,0,0-.053-.718,4.143,4.143,0,0,0-2.534-.879V.469A.47.47,0,0,0,4.723,0H3.785a.47.47,0,0,0-.469.469V1.875H3.243A3.21,3.21,0,0,0,.05,5.379a3.354,3.354,0,0,0,2.455,2.83l3,.879a.864.864,0,0,1-.243,1.693H3.322a1.788,1.788,0,0,1-1-.308.457.457,0,0,0-.571.059l-1.02,1a.475.475,0,0,0,.053.718,4.143,4.143,0,0,0,2.534.879v1.406A.47.47,0,0,0,3.785,15h.938a.47.47,0,0,0,.469-.469V13.119a3.309,3.309,0,0,0,3.1-2.13A3.217,3.217,0,0,0,6.164,6.838Z"
        transform="translate(-0.036)"
      />
    </svg>
  );
}
