const OptionsInputsData = [
  {
    id: 1,
    name: "text",
    value: "Normal",
  },
  {
    id: 2,
    name: "num",
    value: "Apenas Numeros",
  },
  {
    id: 3,
    name: "date",
    value: "Data",
  },
  {
    id: 4,
    name: "hour",
    value: "Hora",
  },
  {
    id: 5,
    name: "datetime-local",
    value: "Data e Hora",
  },
  {
    id: 6,
    name: "tel",
    value: "Telefone",
  },
  {
    id: 7,
    name: "email",
    value: "E-mail",
  },
  {
    id: 8,
    name: "money",
    value: "Moeda",
  },
  {
    id: 9,
    name: "cpf",
    value: "CPF",
  },
  {
    id: 10,
    name: "cnpj",
    value: "CNPJ",
  },
  {
    id: 11,
    name: "rg",
    value: "RG",
  },
];


export {OptionsInputsData}