import React from "react";

import { Handle } from "react-flow-renderer";
import ConfigurationIcon from "../../../Icons/ConfigurationIcon";
import EditIcon from "../../../Icons/EditIcon";

import "./styles.scss";

const Edges = ({ data}) => {

  return (
    <>
      <Handle
        type="target"
        position="left"
        className="containerHandle"
        style={{ background: "#0766FF" }}
      />
      <div className="InputFlow">
        {data.namePage}
      </div>

      <div className="sectionButtonsConfigPage">
        <button 
            className="configIcon buttonEdit"
            onClick={data.onClick}
        >
          <EditIcon width={12.465} color={"#0766FF"} height={12.656} />
        </button>

        <button 
            className="configIcon buttonConfig"
            onClick={data.handleOn}
        >
          <ConfigurationIcon size={14} />
        </button>
      </div>

      <Handle
        type="source"
        position="right"
        style={{ background: "#0766FF" }}
      />
    </>
  );
}

const EdgeInput = ({ data, isConnectable}) =>  {
  return (
    <>
      <div className="InputFlow">
        {data.namePage}
      </div>

      <div className="sectionButtonsConfigPage">
        <button 
            className="configIcon buttonEdit"
            onClick={data.onClick}
        >
          <EditIcon width={12.465} color={"#0766FF"} height={12.656} />
        </button>

        <button 
            className="configIcon buttonConfig"
            onClick={data.handleOn}
        >
          <ConfigurationIcon size={14} />
        </button>
      </div>

      <Handle
        type="source"
        position="right"
        style={{ background: "#0766FF" }}
      />
    </>
  );
}

export { Edges, EdgeInput }