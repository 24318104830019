import React from "react";

export default function GaleryIcon({ size = 22, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15.084 15.084"
    >
      <g id="icon" transform="translate(1 1)">
        <path
          id="Caminho_12"
          data-name="Caminho 12"
          d="M13.665,11.5H24.419a2.167,2.167,0,0,1,2.165,2.165V24.419a2.167,2.167,0,0,1-2.165,2.165H13.665A2.167,2.167,0,0,1,11.5,24.419V13.665A2.167,2.167,0,0,1,13.665,11.5ZM24.419,25.327a.909.909,0,0,0,.908-.908V13.665a.909.909,0,0,0-.908-.908H13.665a.909.909,0,0,0-.908.908V24.419a.909.909,0,0,0,.908.908Z"
          transform="translate(-12.5 -12.5)"
          fill={color}
        />
        <path
          id="Caminho_13"
          data-name="Caminho 13"
          d="M29.947,31.728a1.781,1.781,0,1,1,1.781-1.781A1.783,1.783,0,0,1,29.947,31.728Zm0-2.3a.524.524,0,1,0,.524.524A.525.525,0,0,0,29.947,29.423Z"
          transform="translate(-26.094 -26.094)"
          fill={color}
        />
        <path
          id="Caminho_14"
          data-name="Caminho 14"
          d="M20.462,50.373a.628.628,0,0,1-.444-1.073l8.45-8.45a.628.628,0,0,1,.889,0L33.2,44.692a.628.628,0,0,1-.889.889l-3.4-3.4-8.006,8.006A.626.626,0,0,1,20.462,50.373Z"
          transform="translate(-19.297 -36.289)"
          fill={color}
        />
      </g>
    </svg>
  );
}
