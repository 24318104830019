import React from "react";

export default function AddSectionIcon({ size = 12, color = "#0766ff" }) {
  return (
    <svg
      id="icone_add_seção"
      data-name="icone add seção"
      xmlns="http://www.w3.org/2000/svg"
      width="23.091"
      height="13.47"
      viewBox="0 0 23.091 13.47"
    >
      <path
        id="Caminho_1"
        data-name="Caminho 1"
        d="M26.629,12.049H5.462a.965.965,0,0,1-.962-.962h0a.965.965,0,0,1,.962-.962H26.629a.965.965,0,0,1,.962.962h0A.965.965,0,0,1,26.629,12.049Z"
        transform="translate(-4.5 -10.125)"
        fill={color}
      />
      <path
        id="Caminho_2"
        data-name="Caminho 2"
        d="M26.629,18.8H5.462a.965.965,0,0,1-.962-.962h0a.965.965,0,0,1,.962-.962H26.629a.965.965,0,0,1,.962.962h0A.965.965,0,0,1,26.629,18.8Z"
        transform="translate(-4.5 -11.102)"
        fill={color}
      />
      <path
        id="Caminho_3"
        data-name="Caminho 3"
        d="M26.629,25.549H5.462a.965.965,0,0,1-.962-.962h0a.965.965,0,0,1,.962-.962H26.629a.965.965,0,0,1,.962.962h0A.965.965,0,0,1,26.629,25.549Z"
        transform="translate(-4.5 -12.079)"
        fill={color}
      />
    </svg>
  );
}
