import React from 'react'

//ICONES
import TrashIcon from '../../../../Icons/TrashIcon';

import "./styles.scss";

export default function CardItem({
    handleOnclick,
    handleOnDelete,
    image,
    type,
}) {
  return (
    <div id="containerGaleryItems">
        {type === 'imagem' ? (
            <div className='selectItem'>
                <button className='buttonRelativeItem' onClick={handleOnclick} type="button" />
                <img src={image} alt="galery imagem" />

                <div className="deleteMidia">
                    <span>Mídia: {type}</span>
                    <div
                        className="deleteMidiaButton"
                        onClick={handleOnDelete}
                    >
                        <TrashIcon />
                    </div>
                </div>
            </div>
        ) : (
            <div className='selectItem'>
                <button className='buttonRelativeItem' onClick={handleOnclick} type="button" />
                <video>
                    <source src={image} type="video/mp4" />
                    <source src={image} type="video/ogg" />
                </video>

                <div className="deleteMidia">
                    <span>Mídia: {type}</span>
                    <div
                        className="deleteMidiaButton"
                        onClick={handleOnDelete}
                    >
                    <TrashIcon />
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}
