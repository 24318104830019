import React from "react";

export default function TrashIcon({ width = 13.125, height = 15, color = "#9e9e9e" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.125 15"
    >
      <path
        id="excluir"
        d="M.937,13.594A1.406,1.406,0,0,0,2.344,15h8.437a1.406,1.406,0,0,0,1.406-1.406V3.75H.937Zm7.969-7.5a.469.469,0,1,1,.938,0v6.562a.469.469,0,0,1-.937,0Zm-2.812,0a.469.469,0,0,1,.938,0v6.562a.469.469,0,0,1-.937,0Zm-2.812,0a.469.469,0,1,1,.938,0v6.562a.469.469,0,0,1-.938,0ZM12.656.937H9.141L8.865.389A.7.7,0,0,0,8.235,0H4.887a.7.7,0,0,0-.627.39L3.984.937H.469A.469.469,0,0,0,0,1.406v.937a.469.469,0,0,0,.469.469H12.656a.469.469,0,0,0,.469-.469V1.406A.469.469,0,0,0,12.656.937Z"
        transform="translate(0 0)"
        fill={color}
      />
    </svg>
  );
}
