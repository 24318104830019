import React from 'react'
import TipUi from '../../Ui/TipUi/TipUi'

const EditionWidget = ({
    onSave,
    editionWidgetEdit,
}) => {

    return(
        <div className={editionWidgetEdit.component ? "right active" : "right"} >

            {editionWidgetEdit.component ?
                <>
                    <editionWidgetEdit.component 
                        onSave={onSave}
                        item={editionWidgetEdit.item}
                        id={editionWidgetEdit.id}
                    />
                </>
            :
                <TipUi>
                    Selecione um Widget para editar
                </TipUi>
            }
        </div>
    )
}

export default EditionWidget