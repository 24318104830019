import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import MyPages from "../Pages/Projects/MyPages/Mypages";
import LayoutPrivate from "../Components/LayoutPrivate/LayoutPrivate";
import PaginationFlow from "../Pages/Flow/PaginationFlow/PaginationFlow";
import PreviewProject from "../Pages/PreviewProject";
import GlobalEdit from "../Pages/GlobalEdit";

import ReactGA from 'react-ga';
import SignIn from "../Pages/Login/SignIn";
import SignUp from "../Pages/Login/SignUp";

//export function default
export default function Routes() {
  const params = window.location.pathname.replace("/", "").replace("/", " ").split(" ")[1]

  const template = window.localStorage.getItem(params)
    ? JSON.parse(window.localStorage.getItem(params))
    : [];

  useEffect(() => {
    ReactGA.initialize(template.analytics);
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [template])

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          window.localStorage.getItem("token") ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Router>
      <ReactNotification />
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/registrar" component={SignUp} />
          <Route exact path="/previewTeste" component={PreviewProject} />

          <LayoutPrivate>
            <PrivateRoute exact path="/home">
              <Route component={MyPages} />
            </PrivateRoute>

            <PrivateRoute>
              <Route exact path="/editar/:id" component={GlobalEdit} />
            </PrivateRoute>

            <PrivateRoute exact path="/paginas/:id">
              <Route component={PaginationFlow} />
            </PrivateRoute>
          </LayoutPrivate>
        </Switch>
    </Router>
  );
}
