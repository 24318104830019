import React, { useEffect, useState } from "react";
import InputDefault from "../../Form/InputDefault";
import Modal from "../../Ui/Modal/Modal";
import Galery from "../../Ui/GaleryUi/Galery/Galery";
import NewRange from "../../Ui/NewRange/NewRange";
import RangeSeconds from "../../Ui/RangeSeconds/RangeSeconds";
import TipUi from "../../Ui/TipUi/TipUi";

//ICONS
import SetinhaIcon from "../../../Icons/SetinhaIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import ConfigurationIcon from "../../../Icons/ConfigurationIcon";

//CSS
import "./EditSlide.scss";

function Slide(props) {
  const options = props.item.options;

  const slides = options?.src;
  const [current, setCurrent] = useState(0);
  const length = slides?.length;
  
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }, options.transition?.replace('s', '') * 1000);
    return () => clearInterval(interval);
  }, [current, length, options]);
  
  if (!Array.isArray(slides) || slides.length <= 0) {
    return <TipUi>Selecione uma imagem na galeria</TipUi>;
  }
  
  return (
    <div id="sliderContainer">
      <>
        <div className="sliderCarrosel">
          <button className="sliderLeftArrow" onClick={prevSlide}>
            <SetinhaIcon />
          </button>
          <button className="sliderRightArrow" onClick={nextSlide}>
            <SetinhaIcon />
          </button>
          {options.src.map((row, key) => {
            return (
              <div
                className={key === current ? "slideEdit active" : "slideEdit"}
                key={key}
                style={{
                  transitionDuration: '2s',
                }}
              >
                {key === current && (
                  <img
                    src={row.item}
                    style={{ borderRadius: options.borderRadius }}
                    alt="uma imagem"
                    className="imageSlider"
                  />
                )}
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
}

function SlideEdit({ item, onSave }) {
  const [openGaleryModal, setOpenGaleryModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [options, setOptions] = useState(item.options);

  const [images, setImages] = useState([]);
  const [updateImage, setUpdateImage] = useState("");
  const [itemImage, setItemImage] = useState("");
  const [error, setError] = useState({
    name: "",
    message: "",
    error: false,
  });

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  function handleOnGroupImages(item) {
    const newImages = images;
    newImages.push({ item });
    setImages([...newImages]);

    options["src"] = images;
    setOptions(options);
    onSave(options);
  }

  function handleOnUpdateImage(item, id) {
    if (item.includes("http") === false) {
      setError({
        error: true,
        message: "Link invalido",
        name: "nameAlbum",
      });
    } else {
      const updateImage = options.src;
      const filterImages = updateImage.filter((obj) => obj.item !== id);

      filterImages.push({ item });

      setImages([...filterImages]);
      options["src"] = filterImages;
      setOptions(options);
      onSave(options);

      setUpdateImage("");
      setItemImage("");
      setError({
        name: "",
        message: "",
        error: false,
      });
      setConfigModal(false);
    }
  }

  function handleOpenModalConfig(item) {
    setItemImage(item);
    setConfigModal(!configModal);
  }

  function handleOnDelete(item) {
    const deleteImage = options.src;

    const filterImages = deleteImage.filter((obj) => obj.item !== item);

    setImages([...filterImages]);
    options["src"] = filterImages;
    setOptions(options);
    onSave(options);
  }

  return (
    <div id="editSlide">
      <div className="headerPanelRight">
        <h2>Editar slide</h2>
      </div>
      <div className="mainPanelRight">
        <Galery
          onClose={() => setOpenGaleryModal(!openGaleryModal)}
          handleOnImage={(value) => handleOnGroupImages(value)}
          type={"imagem"}
        />

        <RangeSeconds
          icon={<ResizeWidthIcon />}
          description={"Tempo de Transição (Segundos)"}
          defaultValue={options.transition.replace("s", "")}
          onChange={(value) => handleOnChange("transition", value + "s")}
        />

        <div className="includeImage">
          {options.src.length <= 0 ? (
            <TipUi>Selecione imagens da galeria</TipUi>
          ) : (
            <>
              {options.src.map((row, key) => (
                <div key={key} className="configImage">
                  <img src={row.item} alt="imagem illustrativa" />
                  <div className="buttonsConfig">
                    <button
                      className="buttonConfig"
                      onClick={() => handleOpenModalConfig(row.item)}
                    >
                      <ConfigurationIcon size="18" color="#fff" />
                    </button>
                    <button
                      className="buttonTrash"
                      onClick={() => handleOnDelete(row.item)}
                    >
                      <TrashIcon color="#fff" />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>

      {configModal ? (
        <Modal onClose={() => setConfigModal(!configModal)}>
          <div className="openConfigImageModal">
            <header>
              <h2>Configurar essa Imagem</h2>
            </header>
            <main>
              <InputDefault
                type="text"
                name={"nameAlbum"}
                onChange={(event) => setUpdateImage(event.target.value)}
                placeholder={"Link da imagem"}
                error={error}
              />
            </main>
            <footer>
              <button
                className="configImageButtonCancel"
                onClick={() => setConfigModal(!configModal)}
              >
                Cancelar
              </button>
              <button
                className="configImageButtonSave"
                onClick={() => handleOnUpdateImage(updateImage, itemImage)}
                disabled={updateImage < 1 ? true : false}
              >
                Salvar
              </button>
            </footer>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export { Slide, SlideEdit };
