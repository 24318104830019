import React, { useState } from "react";
import { InputRangeSlider } from "../../Ui/InputRadio/InputRadio";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONES
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import PlusShareIcon from "../../../Icons/PlusShareIcon";
import TwitterIcon from "../../../Icons/TwitterIcon";
import FaceIcon from "../../../Icons/FacebookIcon";
import InstaIcon from "../../../Icons/InstaIcon";
import EnvelopeIcon from "../../../Icons/EnvelopeIcon";
import WhatsIcon from "../../../Icons/WhatsIcon";
import GmailIcon from "../../../Icons/GmailIcon";

import "./EditShare.scss";

function Share({ item }) {
  const styles = item.style;

  return (
    <div id="containerShare">
      {item.items
        ?.filter((obj) => obj.name === "Facebook")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#32508B",
              }}
            >
              <FaceIcon color="#fff" />
            </button>
            <span style={{ color: "#32508B" }}>Facebook</span>
          </div>
        ))}

      {item.items
        ?.filter((obj) => obj.name === "Twitter")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#1DA1F2",
              }}
            >
              <TwitterIcon color="#fff" />
            </button>
            <span style={{ color: "#1DA1F2" }}>Twitter</span>
          </div>
        ))}

      {item.items
        ?.filter((obj) => obj.name === "Instagram")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#CB0464",
              }}
            >
              <InstaIcon color="#fff" />
            </button>
            <span style={{ color: "#CB0464" }}>Instagram</span>
          </div>
        ))}

      {item.items
        ?.filter((obj) => obj.name === "Email")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#0766FF",
              }}
            >
              <EnvelopeIcon color="#fff" />
            </button>
            <span style={{ color: "#0766FF" }}>Email</span>
          </div>
        ))}

      {item.items
        ?.filter((obj) => obj.name === "WhatsApp")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#4CC147",
              }}
            >
              <WhatsIcon color="#fff" />
            </button>
            <span style={{ color: "#4CC147" }}>WhatsApp</span>
          </div>
        ))}

      {item.items
        ?.filter((obj) => obj.name === "Gmail")
        .map((row, key) => (
          <div
            className="social"
            key={key}
            style={{ borderRadius: styles.borderRadius, background: "#EFEFEF" }}
          >
            <button
              type="button"
              style={{
                borderRadius: styles.borderRadius,
                background: "#E04234",
              }}
            >
              <GmailIcon color="#fff" />
            </button>
            <span style={{ color: "#E04234" }}>Gmail</span>
          </div>
        ))}

      {item.items?.length < 1 && (
        <div className="plus" style={{ borderRadius: styles.borderRadius }}>
          <button>
            <PlusShareIcon />
          </button>
        </div>
      )}
    </div>
  );
}

function ShareEdit({ item, onSave }) {
  const [items, setItems] = useState(item.items);
  const [styles, setStyles] = useState(item.style);

  function handleOnChange(name, value) {
    styles[name] = value;
    setStyles(styles);
    onSave(styles);
  }

  function handleOnFacebook(name) {
    const exist = items.filter((obj) => obj.name === "Facebook");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "Facebook");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }

    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  function handleOnTwitter(name) {
    const exist = items.filter((obj) => obj.name === "Twitter");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "Twitter");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }
    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  function handleOnInstagram(name) {
    const exist = items.filter((obj) => obj.name === "Instagram");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "Instagram");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }
    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  function handleOnEmail(name) {
    const exist = items.filter((obj) => obj.name === "Email");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "Email");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }
    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  function handleOnWhatsApp(name) {
    const exist = items.filter((obj) => obj.name === "WhatsApp");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "WhatsApp");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }
    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  function handleOnGmail(name) {
    const exist = items.filter((obj) => obj.name === "Gmail");
    var newItems = items;

    if (exist.length === 1) {
      newItems = items.filter((obj) => obj.name !== "Gmail");
    } else {
      newItems.push({
        id: item.items.length,
        name: name,
      });
    }
    item.items = newItems;
    setItems([...newItems]);
    onSave({ ...item });
  }

  return (
    <div id="editShare">
      <div className="headerPanelRight">
        <h2>Editar compartilhar</h2>
      </div>
      <div className="mainPanelRight">
        <InputRangeSlider
          onClick={() => handleOnFacebook("Facebook")}
          total={item.items.filter((obj) => obj.name === "Facebook").length}
        >
          <span>Facebook</span>
        </InputRangeSlider>

        <InputRangeSlider
          onClick={() => handleOnTwitter("Twitter")}
          total={item.items.filter((obj) => obj.name === "Twitter").length}
        >
          <span>Twiter</span>
        </InputRangeSlider>

        <InputRangeSlider
          onClick={() => handleOnInstagram("Instagram")}
          total={item.items.filter((obj) => obj.name === "Instagram").length}
        >
          <span>Instagram</span>
        </InputRangeSlider>

        <InputRangeSlider
          onClick={() => handleOnEmail("Email")}
          total={item.items.filter((obj) => obj.name === "Email").length}
        >
          <span>E-mail</span>
        </InputRangeSlider>

        <InputRangeSlider
          onClick={() => handleOnWhatsApp("WhatsApp")}
          total={item.items.filter((obj) => obj.name === "WhatsApp").length}
        >
          <span>WhatsApp</span>
        </InputRangeSlider>

        <InputRangeSlider
          onClick={() => handleOnGmail("Gmail")}
          total={item.items.filter((obj) => obj.name === "Gmail").length}
        >
          <span>G-mail</span>
        </InputRangeSlider>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          mask={4}
          defaultValue={styles.borderRadius.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />

      </div>
    </div>
  );
}

export { Share, ShareEdit };
