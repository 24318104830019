import React from "react";
import { useParams } from "react-router-dom";
import InformationIcon from "../../Icons/InformatioIcon";
import ResizeWidthIcon from "../../Icons/ResizeWidthIcon";
import InputDefault from "../Form/InputDefault";
import SelectDefault from "../Form/SelectDefault";
import TextAreaDefault from "../Form/TextAreaDefault";
import Galery from "../Ui/GaleryUi/Galery/Galery";
import { LinkUi } from "../Ui/LinkUi/LinkUi";
import NewRange from "../Ui/NewRange/NewRange";

import "./AddGlobal.scss";

function AddGlobal({ handleOnStorytelling, image, borderRadiusLogo, handleOnChangeNew, link }) {
  const params = useParams();
  const template = window.localStorage.getItem(params.id)
    ? JSON.parse(window.localStorage.getItem(params.id))
    : [];
    
  return (
    <div id="panelInfo">
      <div className="mainInfo">
        <div className="title">
          <InformationIcon />
          <span>Informações gerais</span>
        </div>

        <div className="infoGroup">
          <InputDefault
            type="text"
            name={"project"}
            label={"Nome da aplicação"}
            onChange={(event) => handleOnChangeNew("project", event.target.value)}
            placeholder="Nome da page"
            defaultValue={template.project || ""}
          />

          <SelectDefault 
            name={"category"}
            label={"Categoria"}
            onChange={(event) => handleOnChangeNew("category", event.target.value)}
            placeholder="Categoria"
            defaultValue={template.category || ''}
          >
            <option value="">Selecione uma categoria</option>
            <option value="TropaMax">TropaMax</option>
            <option value="TropaDigital">TropaDigital</option>
            <option value="TropaDigital2">TropaDigital2</option>
          </SelectDefault>

          <TextAreaDefault 
            name={"descrição"}
            label={"Descrição"}
            onChange={(event) => handleOnChangeNew("description", event.target.value)}
            placeholder="Descrição"
            defaultValue={template.description || ""}
          />

          <Galery 
            handleOnImage={(value) => handleOnChangeNew("imagePage", value)}  
            type={'imagem'}
            title="Adicionar logo"
            typeButton='logo'
            image={image}
            borderRadiusLogo={borderRadiusLogo}
          />

          <NewRange
            icon={<ResizeWidthIcon />}
            description={"Arredondamento da logo"}
            defaultValue={template.borderRadiusLogo.replace("px", "")}
            onChange={(value) => handleOnChangeNew("borderRadiusLogo", value + "px")}
          />

          <InputDefault
            type="text"
            name={"analytics"}
            label={"ID Google Analytics"}
            onChange={(event) => handleOnChangeNew("analytics", event.target.value)}
            placeholder="Código analytics"
            defaultValue={template.analytics}
          />

          <LinkUi onChange={() => handleOnStorytelling('storytelling', !link)} value={link}>
            Storytelling 
          </LinkUi>
        </div>
      </div>
    </div>
  );
}

export { AddGlobal };
