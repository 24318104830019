import React, { useCallback, useState } from "react";
import ModalConfirmation from "../ModalConfirmation";

// ICONS
import ConfigurationIcon from "../../../../../Icons/ConfigurationIcon";
import MoverIcon from "../../../../../Icons/MoverIcon";
import TrashIcon from "../../../../../Icons/TrashIcon";
import AlertCircleIcon from "../../../../../Icons/AlertCircleIcon";
import InputCheckboxDefault from "../../../../Form/InputCheckboxDefault";

import "./styles.scss";

export default function Confirmation({ item, onItems, handleOnCheckConfirm, onDelete, styles }) {
  const [isFocused, setIsFocused] = useState(false);
  const [modal, setModal] = useState(false);
  const idField = item.id;

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  function hadleOnDelete(id) {
    onDelete(id)
    window.localStorage.removeItem('@buildr:edition')
  }

  function handleEdit(itemprops) {
    window.localStorage.setItem('@buildr:edition', JSON.stringify(itemprops))
    setModal(true)
  }

  return (
    <div
      className={`containerBoxSelection  ${isFocused ? "focused" : ""}`}
      style={{
        background: styles?.colorPrimary,
        borderRadius: styles?.borderRadius,
        border: isFocused
          ? "1px solid" + styles?.colorSecundary
          : "1px solid" + styles?.colorPrimary,
      }}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
    >
      <div className="boxHeader">
        <div className="boxHeaderLeft">
          <span style={{ color: `${isFocused ? styles.colorSecundary : styles.colorText}` }}>
            {item.config?.question || 'Texto'}
          </span>
        </div>
        <div className="boxHeaderRight">
            <div className="required">
              <div className="error">
                <AlertCircleIcon color={styles.colorSecundary} />
              </div>
            </div>
          <div className="buttonsRight">
            <button
              type="button"
              onClick={() => hadleOnDelete(idField)}
            >
              <TrashIcon width={12} height={13} color={styles.colorText} />
            </button>
            <button
              type="button"
              onClick={() => handleEdit(item.config)}
            >
              <ConfigurationIcon size={13} color={styles.colorText} />
            </button>
            <button
              className="moveBox"
              type="button"
            >
              <MoverIcon color={styles.colorText} />
            </button>
          </div>
        </div>
      </div>

        <div className="sectionAreaTerms">
          {item.config.alternative.map((row) => (
            <InputCheckboxDefault 
              key={row.id}
              styles={styles}
              data={row}
              onClick={() => handleOnCheckConfirm(row.id, idField)}
            />
          ))}
        </div>

      {modal && (
        <ModalConfirmation
          id={"id"}
          onClose={() => setModal(!modal)}
          setData={(items) => onItems(items, idField)}
          data={item}
        />
      )}
    </div>
  );
}
