import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import "./styles.scss";

export default function NewEditTextUi({
  value = "",
  options = {},
  onChangeText = () => {},
}) {
  const [valueDefault, setValueDefault] = useState(`<p>${value}</p>`);

  return (
    <>
      <div id="editTextUi">
        <Editor
          value={valueDefault}
          onInit={(evt, editor) => {
            onChangeText(editor.getContent());
          }}
          onEditorChange={(newValue, editor) => {
            setValueDefault(newValue);
            onChangeText(editor.getContent());
          }}
          init={{
            max_height: 300,
            menubar: false,
            toolbar_mode: 'wrap',
            language: 'pt_BR',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: ' formatselect link ' +
            'bold italic backcolor forecolor alignleft aligncenter ' +
            'alignright alignjustify underline strikethrough lineheight fontsizes ' +
            'undo redo removeformat',
            content_style: `body { font-family:Rubik,sans-serif; font-size:16px; font-Weight:700; color:${options.color} }`,
            visual_anchor_class: 'my-custom-class'
          }}
          
        />
      </div>
    </>
  );
}