import React from 'react'

import './styles.scss';

export default function Notifications({ 
  id = 'modal', 
  children,
  showModal,
  setShowModal,
 }) {
  const handleoutsideClick = (e) => {
    if(e.target.id === id)
    setShowModal(false)
  };

  return (
    <>
      {showModal ? (
        <div id={id} className='overlayNotifications' onClick={handleoutsideClick}>
          {children}
        </div>
      ) : null}
    </>
  )
}
