import React from "react";

export default function LinkIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="FontAwsome_link_"
        data-name="FontAwsome (link)"
        d="M9.568,5.431a4.451,4.451,0,0,1,.011,6.287l-.011.011L7.6,13.7A4.453,4.453,0,0,1,1.3,7.4L2.389,6.313a.468.468,0,0,1,.8.311,5.4,5.4,0,0,0,.284,1.545.472.472,0,0,1-.111.487l-.383.383a2.109,2.109,0,1,0,2.964,3l1.969-1.968a2.109,2.109,0,0,0,0-2.983,2.195,2.195,0,0,0-.3-.251.47.47,0,0,1-.2-.369,1.167,1.167,0,0,1,.343-.873l.617-.617a.471.471,0,0,1,.6-.051A4.467,4.467,0,0,1,9.568,5.431ZM13.7,1.3a4.458,4.458,0,0,0-6.3,0L5.431,3.27l-.011.011a4.454,4.454,0,0,0,.612,6.791.471.471,0,0,0,.6-.051L7.252,9.4a1.167,1.167,0,0,0,.343-.873.47.47,0,0,0-.2-.369,2.195,2.195,0,0,1-.3-.251,2.109,2.109,0,0,1,0-2.983L9.057,2.959a2.109,2.109,0,1,1,2.964,3l-.383.383a.472.472,0,0,0-.111.487,5.4,5.4,0,0,1,.284,1.545.468.468,0,0,0,.8.311L13.7,7.6a4.458,4.458,0,0,0,0-6.3Z"
        transform="translate(0.001 0.001)"
      />
    </svg>
  );
}
