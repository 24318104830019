import React from "react";

import "./styles.scss";

export default function ModalEditProject({
  id,
  onClose,
  onDelete = () => {},
  nameProject,
  title,
  subTitle,
  messageButtonCancel,
  messageButtonSend
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <div id={id} onClick={handleCloseModal} className="overlayModalProject">
      <div id="containerModalProject">
        <header>
          <h2>{title || ''}</h2>
        </header>

        <main>
          <div className="questionName">
          </div>
            <div className="deletePage">
              <h2>{`${subTitle} ${" " + nameProject || ''}`}</h2>
            </div>
        </main>
        <footer>
          <button className="buttonCancel" type="button" onClick={onClose}>
            {messageButtonCancel}
          </button>
          <button
            className={`buttonSave ${messageButtonSend === 'Deletar' ? 'del' : ''}`}
            type="button"
            onClick={onDelete}
          >
            {messageButtonSend}
          </button>
        </footer>
      </div>
    </div>
  );
}
