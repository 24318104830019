import React from "react";

export default function TipIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.888"
      height="18.743"
      viewBox="0 0 15.888 18.743"
    >
      <path
        id="FontAwsome_lightbulb_"
        data-name="FontAwsome (lightbulb)"
        d="M5.46,2.482A2.982,2.982,0,0,0,2.482,5.46a.5.5,0,1,0,.993,0A1.988,1.988,0,0,1,5.46,3.475a.5.5,0,1,0,0-.993ZM2.98,14.246a.493.493,0,0,0,.083.274l.76,1.143a.5.5,0,0,0,.413.222H6.683a.5.5,0,0,0,.413-.222l.76-1.143a.5.5,0,0,0,.083-.274l0-1.34H2.979ZM5.46,0A5.457,5.457,0,0,0,1.351,9.053a8.879,8.879,0,0,1,1.626,2.859v0H4.467v0a1.468,1.468,0,0,0-.067-.437,9.783,9.783,0,0,0-1.929-3.4,3.971,3.971,0,1,1,5.978,0,9.8,9.8,0,0,0-1.927,3.4,1.474,1.474,0,0,0-.069.444v0H7.943v0A8.872,8.872,0,0,1,9.57,9.053,5.458,5.458,0,0,0,5.46,0Z"
        transform="translate(5.693) rotate(21)"
        fill="#fff"
      />
    </svg>
  );
}
