import React, { useState } from "react";
import ColorUi from "../../Ui/ColorUi";
import Select from "../../Ui/Select/Select";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONS
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import CloseIcon from "../../../Icons/CloseIcon";

import "./EditModal.scss";
import NewEditTextUi from "../../Ui/NewEditTextUi";

const Modal = (props) => {
  const options = props.item.options;
  const [modal, setModal] = useState(false);

  const styles = {
    borderRadius: `${options.borderRadius} ${options.borderRadius} 0 0`,
    background: options.colorPrimary,
  };

  function handleOnModal() {
    setModal(!modal);
  }

  return (
    <div
      className={`modalEdits ${modal ? "modal" : ""}`}
      style={{
        background: `rgba(0, 0, 0, ${options.opacity / 100})`,
        alignItems: options.position,
      }}
      onClick={handleOnModal}
    >
        <div className="containerModalEdits" style={styles}>
          <header>
            <button className="closeModal" onClick={handleOnModal}>
              <CloseIcon />
            </button>
          </header>
          <main
            style={{ color: options.colorText }}
            dangerouslySetInnerHTML={{ __html: options.textModal }}
          >
          </main>

          <footer
            style={{
              color: options.colorText,
              background: options.colorSecundary,
            }}
            dangerouslySetInnerHTML={{ __html: options.descriptionModal }}
          />
        </div>
      </div>
  );
};

const ModalEdit = ({ item, onSave }) => {
  const [options, setOptions] = useState(item.options);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  return (
    <div id="editModal">
      <div className="headerPanelRight">
        <h2>Editar modal</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />


        <NewEditTextUi
          value={options.textModal}
          options={{
            color: options.colorText,
          }}
          onChangeText={(value) => handleOnChange("textModal", value)}
        />

        <Select
          onChange={(value) => handleOnChange("position", value)}
          value={options.position}
          placeholder="Selecione posição do modal"
        >
          <option value="baseline">Posição top</option>
          <option value="center">Posição center</option>
          <option value="end">Posição bottom</option>
        </Select>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Opacidade"}
          defaultValue={options.opacity}
          onChange={(value) => handleOnChange("opacity", value)}
        />

        <NewEditTextUi
          value={options.descriptionModal}
          options={{
            color: options.colorTextSecond,
            backgroundColor: options.colorSecundary,
          }}
          onChangeText={(value) => handleOnChange("descriptionModal", value)}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
};

export { ModalEdit, Modal };
