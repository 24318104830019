import React from "react";

// COLUNAS -- SECTIONS
import { Column, ColumnEdit } from "../Components/Edits/EditColumn/EditColumn";

// CONTEUDO -- CONTENT
import {
  Button,
  ButtonEdit,
} from "../Components/Edits/EditButtons/EditButtons";
import { Image, ImageEdit } from "../Components/Edits/EditImage/EditImage";
import { Modal, ModalEdit } from "../Components/Edits/EditModal/EditModal";
import { TextEdit, Text } from "../Components/Edits/EditText/EditText";
import { Count, CountEdit } from "../Components/Edits/EditCount/EditCount";
import { OfferEdit, Offer } from "../Components/Edits/EditOffer/EditOffer";
import { Slide, SlideEdit } from "../Components/Edits/EditSlide/EditSlide";
import { Video, VideoEdit } from "../Components/Edits/EditVideo/EditVideo";
import { MapWidget, MapEdit } from "../Components/Edits/EditMap/EditMap";
import { EditForm, FormEdit } from "../Components/Edits/EditForm/EditForm";

// FUNÇÂO -- FUNCTION
import { Share, ShareEdit } from "../Components/Edits/EditShare/EditShare";
import {
  ButtonLink,
  ButtonLinkEdit,
} from "../Components/Edits/EditButtonLink/EditButtonLink";

//ICONES
import ColumnIcon from "../Icons/ColumnIcon";
import ModalIcon from "../Icons/ModalIcon";
import ImageIcon from "../Icons/ImageIcon";
import FormIcon from "../Icons/FormIcon";
import TextIcon from "../Icons/TextIcon";
import ContIcon from "../Icons/ContIcon";
import OfferIcon from "../Icons/OfferIcon";
import ButtonIcon from "../Icons/ButtonIcon";
import MapIcon from "../Icons/MapIcon";
import VideoIcon from "../Icons/VideoIcon";
import SlideIcon from "../Icons/SlideIcon";

import FunctionShareIcon from "../Icons/FunctionShareIcon";
import FunctionDownloadIcon from "../Icons/FunctionDownloadIcon";
import FunctionFacebookIcon from "../Icons/FunctionFacebookIcon";
import FunctionInstagramIcon from "../Icons/FunctionInstagramIcon";
import FunctionWhatsAppIcon from "../Icons/FunctionWhatsAppIcon";
import FunctionPhoneIcon from "../Icons/FunctionPhoneIcon";
import FunctionSmsIcon from "../Icons/FunctionSmsIcon";
import FunctionLocationIcon from "../Icons/FunctionLocationIcon";
import FunctionVideoIcon from "../Icons/FunctionVideoIcon";
import FunctionEmailIcon from "../Icons/FunctionEmailIcon";

import FileDownloadICon from "../Icons/FileDownloadICon";
import EnvelopeIcon from "../Icons/EnvelopeIcon";
import FacebookIcon from "../Icons/FacebookIcon";
import InstaIcon from "../Icons/InstaIcon";
import WhatsIcon from "../Icons/WhatsIcon";
import TelephoneIcon from "../Icons/TelephoneIcon";
import CommentsIcon from "../Icons/CommentsIcon";
import MapMarkerIcon from "../Icons/MapMarkerIcon";
import YoutubeIcon from "../Icons/YoutubeIcon";

class WidgetStore {
  
  constructor() {
    this.state = [];
    this.globalStyles = {};
    this.setState();
  }

  setGlobalStyle(params) {
    this.globalStyles = params;
  }

  getGlobalStyle() {
    return this.globalStyles;
  }

  setState() {
    const randomUUID = (idUnique) =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    const styles = this.getGlobalStyle();

    var defaultVar = [
      {
        className: "column column-1",
        name: "1 coluna",
        ico: "column-1",
        icone: <ColumnIcon />,
        cat: "secao",
        component: Column,
        componentEdit: ColumnEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          width: "100%",
          gap: "0px",
          padding: "0px",
          backgroundColor: "rgba(0,0,0,.1)",
          midia: {
            value: '',
            type: 'imagem',
          },
          fixed: false,
          stiky: false,
          position: 'initial',
          positionItem: 'center'
        },
        content: [[]],
      },
      {
        className: "column column-2",
        name: "2 colunas",
        ico: "column-2",
        icone: <ColumnIcon />,
        cat: "secao",
        component: Column,
        componentEdit: ColumnEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          width: "100%",
          padding: "0px",
          gap: "0px",
          backgroundColor: "rgba(0,0,0,.1)",
          midia: {
            value: '',
            type: 'imagem',
          },
          fixed: false,
          stiky: false,
          position: 'initial',
          positionItem: 'center'
        },
        content: [[], []],
      },
      {
        className: "column column-3",
        name: "3 colunas",
        ico: "column-3",
        icone: <ColumnIcon />,
        cat: "secao",
        component: Column,
        componentEdit: ColumnEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          margin: "0px",
          width: "100%",
          padding: "0px",
          gap: "0px",
          backgroundColor: "rgba(0,0,0,.1)",
          midia: {
            value: '',
            type: 'imagem',
          },
          fixed: false,
          stiky: false,
          position: 'initial',
          positionItem: 'center'
        },
        content: [[], [], []],
      },
      {
        className: "column column-4",
        name: "4 colunas",
        ico: "column-4",
        icone: <ColumnIcon />,
        cat: "secao",
        component: Column,
        componentEdit: ColumnEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          margin: "0px",
          width: "100%",
          padding: "0px",
          gap: "0px",
          backgroundColor: "rgba(0,0,0,.1)",
          midia: {
            value: '',
            type: 'imagem',
          },
          fixed: false,
          stiky: false,
          position: 'initial',
          positionItem: 'center'
        },
        content: [[], [], [], []],
      },
      {
        className: "widget-modal",
        name: "Modal",
        ico: "modal",
        icone: <ModalIcon />,
        cat: "conteudo",
        component: Modal,
        componentEdit: ModalEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          colorTextSecond: "#000000",
          textModal: "Titulo do Modal",
          borderRadius: "20px",
          opacity: "1",
          position: "center",
          descriptionModalColor: "#FFF",
          descriptionModal: "Descrição do Modal",
        },
      },

      {
        className: "widget-image",
        name: "Imagem",
        ico: "image",
        icone: <ImageIcon />,
        cat: "conteudo",
        component: Image,
        componentEdit: ImageEdit,
        style: {},
        options: {
          src: "https://images.unsplash.com/photo-1593642533144-3d62aa4783ec?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
          borderRadius: "0%",
          width: "100%",
          padding: "0px",
          externalLink: "http://www.google.com",
          internalLink: "0",
          toggleLink: false,
        },
      },

      {
        className: "widget-offer",
        name: "Oferta",
        ico: "offer",
        icone: <OfferIcon />,
        cat: "conteudo",
        component: Offer,
        componentEdit: OfferEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          linkOffer: "https://tropa.digital/",
          title: "Sapatos da mota",
          valueOffer: 500,
          valueDescont: 10,
          valueFinal: 200,
          src: "https://www.litoralverde.com.br/img/uploads/acomodacao_hotel/pousada-maravilha-area-piscina_1487640559.jpg",
          borderRadius: "20px",
        },
      },

      {
        className: "widget-slide",
        name: "Slide",
        ico: "slide",
        icone: <SlideIcon />,
        cat: "conteudo",
        component: Slide,
        componentEdit: SlideEdit,
        style: {},
        options: {
          transition: "30s",
          borderRadius: "0px",
          src: [
            {
              item: "https://www.litoralverde.com.br/img/uploads/acomodacao_hotel/pousada-maravilha-area-piscina_1487640559.jpg",
            },
          ],
        },
      },

      {
        className: "widget-contador",
        name: "Contador",
        ico: "timer",
        icone: <ContIcon />,
        cat: "conteudo",
        component: Count,
        componentEdit: CountEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          expiration: "Expira",
          borderRadius: "0px",
          date: "2022-12-13T12:00",
          time: "00:00",
          closePage: false,
        },
      },

      {
        className: "widget-text",
        name: "Texto",
        ico: "text",
        icone: <TextIcon />,
        cat: "conteudo",
        component: Text,
        componentEdit: TextEdit,
        style: {},
        options: {
          colorText: "#000000",
          text: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is.",
          copy: false,
          textButton: "Copiar texto",
          colorButtonPrimary: "#F5F5F5",
          borderButtonRadius: "0px",
          toggle: false,
        },
      },
      {
        className: "widget-button",
        name: "Botão",
        ico: "button",
        icone: <ButtonIcon />,
        cat: "conteudo",
        component: Button,
        componentEdit: ButtonEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          textButton: "Clique aqui",
          internalLink: "0",
          externalLink: "http://www.google.com",
          toggleLink: false,
          borderRadius: "0px",
          border: "none",
          width: "100%",
          height: "45px",
        },
      },

      {
        className: "widget-form",
        name: "Formulario",
        ico: "form",
        icone: <FormIcon />,
        cat: "conteudo",
        component: EditForm,
        componentEdit: FormEdit,
        style: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          borderRadius: "0px",
        },
        options: {
          textButton: "Enviar",
          colorButtonPrimary: "#F5F5F5",
          colorButtonSecundary: "#1F1F1F",
          borderButtonRadius: "0px",
          externalLink: "http://www.google.com",
          internalLink: "0",
          toggleLink: false,
          fields: [
            {
              id: 0,
              config: {
                required: false,
                inputType: 'text',
                placeholder: "Pergunta",
                minlength: "1",
                maxlength: "20",
              },
              input: {
                inputType: "text",
                required: false,
                placeholder: "Pergunta",
                name: "InputSimple",
                mask: "",
                minlength: "1",
                maxlength: "20",
              },
            },
            {
              id: 1,
              type: 'selecao',
              config: {
                question: 'Seleção',
                required: false,
                alternative: [
                  {
                    id: randomUUID(),
                    name: "groupSelecao",
                    placeholder: "Alternativa",
                    value: "Alternativa",
                    checked: true,
                  },
                ],
              },
            },
            {
              id: 2,
              type: 'alternative',
              config: {
                question: 'Alternativas',
                required: false,
                alternative: [
                  {
                    id: randomUUID(),
                    name: "groupAlternative",
                    placeholder: "Alternativa",
                    value: "Alternativa",
                    checked: true,
                  },
                ],  
              },
            },
            {
              id: 3,
              type: "textarea",
              config: {
                required: false,
                question: 'Texto',
              },
            },
            {
              id: 4,
              type: 'confirmation',
              config: {
                required: true,
                question: 'Confirmar',
                placeholder: "Digite seu texto",
                text: "Aceitar os termos",
                alternative: [
                  {
                    id: 1,
                    placeholder: "Aceitar os termos",
                    value: "Aceitar os termos",
                    required: false,
                  },
                ],  
              },
            },
          ],
        },
      },
      {
        name: "Vídeo",
        ico: "video",
        icone: <VideoIcon />,
        cat: "conteudo",
        component: Video,
        componentEdit: VideoEdit,
        style: {},
        options: {
          src: "https://www.youtube.com/embed/1o-BgQKCwWk",
          borderRadius: "0px",
          externalLink: "https://www.youtube.com/embed/1o-BgQKCwWk",
          internalLink: "https://player.vimeo.com/external/347925811.sd.mp4?s=e48915e5abacccf4471e3daf61611fd8af52ca87&profile_id=139&oauth2_token_id=57447761",
          toggleLink: false,
        },
      },
      {
        name: "Mapa",
        ico: "map",
        icone: <MapIcon />,
        cat: "conteudo",
        component: MapWidget,
        componentEdit: MapEdit,
        style: {},
        options: {
          address: "Avenida Paulista",

          lat: -23.4839086,
          lng: -46.7637682,

          borderRadius: "10px",
        },
      },
      {
        name: "Compartilhar",
        ico: "share",
        icone: <FunctionShareIcon />,
        cat: "funcao",
        component: Share,
        componentEdit: ShareEdit,
        style: {
          borderRadius: "0px",
        },
        options: {},
        items: [],
      },
      {
        name: "Download",
        ico: "donload",
        icone: <FunctionDownloadIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: FileDownloadICon,
          name: "Download",
          placeholder: "Link do download",
        },
      },
      {
        name: "E-mail",
        ico: "e-mail",
        icone: <FunctionEmailIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: EnvelopeIcon,
          name: "Email",
          placeholder: "E-mail",
        },
      },
      {
        name: "Facebook",
        ico: "facebook",
        icone: <FunctionFacebookIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: FacebookIcon,
          name: "Facebook",
          placeholder: "Facebook",
        },
      },
      {
        name: "Instagram",
        ico: "instagram",
        icone: <FunctionInstagramIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: InstaIcon,
          name: "Instagram",
          placeholder: "Instagram",
        },
      },
      {
        name: "Whatsapp",
        ico: "whatsapp",
        icone: <FunctionWhatsAppIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: WhatsIcon,
          name: "WhatsApp",
          placeholder: "WhatsApp",
          message: "",
        },
      },
      {
        name: "Telefone",
        ico: "telefone",
        icone: <FunctionPhoneIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: TelephoneIcon,
          name: "Telefone",
          placeholder: "Telefone",
        },
      },
      {
        name: "SMS",
        ico: "sms",
        icone: <FunctionSmsIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: CommentsIcon,
          name: "SMS",
          placeholder: "Sms",
        },
      },
      {
        name: "Localização",
        ico: "location",
        icone: <FunctionLocationIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: MapMarkerIcon,
          name: "Localizacao",
          placeholder: "Localização",
        },
      },
      {
        name: "Video",
        ico: "video",
        icone: <FunctionVideoIcon />,
        cat: "funcao",
        component: ButtonLink,
        componentEdit: ButtonLinkEdit,
        style: {},
        options: {
          colorPrimary: "#F5F5F5",
          colorSecundary: "#1F1F1F",
          colorText: "#000000",
          text: "Click aqui",
          link: "https://tropa.digital/",
          borderRadius: "50px",
          width: "50px",
          icone: YoutubeIcon,
          name: "Video",
          placeholder: "Video",
        },
      },
    ];

    var stateNew = [];
    const randomId = (idUnique) =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    defaultVar.map((row) => {
      var item = {};
      item.id = randomId();
      item.template = row;

      item.template.options.colorPrimary = styles?.colorPrimary;
      item.template.options.colorSecundary = styles?.colorSecundary;
      item.template.options.colorText = styles?.colorText;
      item.template.options.borderRadius = styles?.borderRadius;

      item.template.style.colorPrimary = styles?.colorPrimary;
      item.template.style.colorSecundary = styles?.colorSecundary;
      item.template.style.colorText = styles?.colorText;
      item.template.style.borderRadius = styles?.borderRadius;

      if (row.content) {
        row.content.map(
          (contentKey) => (item.template.content[contentKey] = []),
        );
      }

      if(styles === undefined) {
        item.template.style.colorPrimary = "#F5F5F5";
        item.template.style.colorSecundary = "#1F1F1F";
        item.template.style.colorText = "#000000";
        item.template.style.borderRadius = '0px';

        item.template.options.colorPrimary = "#F5F5F5";
        item.template.options.colorSecundary = "#1F1F1F";
        item.template.options.colorText = "#000000";
        item.template.options.borderRadius = '0px';
      }
      return stateNew.push(item);

    });

    this.state = stateNew;
  }

  getState() {
    return this.state;
  }
}

const widgetStore = new WidgetStore({});

export default widgetStore;
