import React, { useState } from "react";
import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import Galery from "../../Ui/GaleryUi/Galery/Galery";
import ColorUi from "../../Ui/ColorUi";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONES
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import LinkIcon from "../../../Icons/LinkIcon";
import CifraoIcon from "../../../Icons/CifraoIcon";
import PercentIcon from "../../../Icons/PercentIcon";
import ShoppingCarticon from "../../../Icons/ShoppingCartIcon";

//CSS
import "./EditOffer.scss";

const Offer = (props) => {
  const options = props?.item.options;

  const percent = options.valueOffer / 100;
  const descont = percent * options.valueDescont;
  const value = options.valueOffer - descont;

  function handleClick() {
    if (options.linkOffer.includes('http')) {
      var url = options.externalLink;
      var win = window.open(url, "_blank");
      win.focus();
    }
  }

  return (
    <div 
      id="ContainerOffer" 
      style={{
        backgroundColor: options.colorPrimary,
        borderRadius: options.borderRadius,
        pointerEvents:
        props.edit === true || options.linkImage === "default"
          ? "none"
          : "false",
      }}
      onClick={handleClick}
    >
      <div className="figure">
        <img
          src={options.src}
          style={{ borderRadius: options.borderRadius }}
          alt="Imagem oferta"
        />
      </div>
      <div className="offerInfo">
        <div className="offerTitle">
          <h3 style={{ color: options.colorText }}>{options.title}</h3>
          <div className="offerSubtitle">
            <span>R${options.valueOffer}</span>
            <b style={{ color: options.colorText }}>R${value}</b>
          </div>
        </div>
        <button
          style={{ backgroundColor: options.colorSecundary }}
          type="button"
        >
          <ShoppingCarticon />
        </button>
      </div>
    </div>
  );
}

const OfferEdit = ({ item, onSave }) => {
  const [openGaleryModal, setOpenGaleryModal] = useState(false);
  const [options, setOptions] = useState(item.options);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  return (
    <div id="editOffer">
      <div className="headerPanelRight">
        <h2>Editar oferta</h2>
      </div>
      <div className="mainPanelRight">
        <ColorUi
          description={"Cor principal"}
          value={options.colorPrimary}
          onChange={(value) => handleOnChange("colorPrimary", value)}
        />

        <ColorUi
          description={"Cor secundária"}
          value={options.colorSecundary}
          onChange={(value) => handleOnChange("colorSecundary", value)}
        />

        <ColorUi
          description={"Cor texto"}
          value={options.colorText}
          onChange={(value) => handleOnChange("colorText", value)}
        />

        <InputPrimary
          placeholder="Link da oferta"
          onChange={(value) => handleOnChange("linkOffer", value)}
          defaultValue={options.linkOffer}
        >
          <LinkIcon />
        </InputPrimary>

        <Galery 
          onClose={() => setOpenGaleryModal(!openGaleryModal)}
          handleOnImage={(value) => handleOnChange("src", value)}
          type={"imagem"}
        />

        <InputPrimary
          placeholder="Titulo da oferta"
          onChange={(value) => handleOnChange("title", value)}
          defaultValue={options.title}
        />

        <InputPrimary
          placeholder="Valor da oferta"
          onChange={(value) => handleOnChange("valueOffer", value)}
          defaultValue={options.valueOffer}
        >
          <CifraoIcon />
        </InputPrimary>

        <InputPrimary
          placeholder="Desconto (Porcentagem)"
          onChange={(value) => handleOnChange("valueDescont", value)}
          defaultValue={options.valueDescont}
        >
          <PercentIcon />
        </InputPrimary>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />

      </div>
    </div>
  );
}

export { Offer, OfferEdit };
