import React from "react";
import InputDefault from "../../../Components/Form/InputDefault";
import TextAreaDefault from "../../../Components/Form/TextAreaDefault";
import "./styles.scss";

export default function ModalEdit({
  id,
  onClose,
  handleOnNameValue = (value) => {},
  handleOnDescriptionValue = (value) => {},
  onEdit = () => {},
  nameProject,
  title,
  description,
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <div id={id} onClick={handleCloseModal} className="overlayModalEdit">
      <div id="modalEdit">
        <header>
          <h2>{title}</h2>
        </header>

        <main>
          <div className="questionName">
            <div className="field">
            <InputDefault
              type="text"
              name={"namePage"}
              label={"Nome da Page"}
              onChange={(event) => handleOnNameValue(event.target.value)}
              placeholder="Nome da page"
              error={{
                error: nameProject < 1 ? true : false,
                message: 'Nome da page nao pode ser nulo'
              }}
            />
            </div>

            <div className="field">
            <TextAreaDefault 
              name={"descrição"}
              label={"Descrição"}
              onChange={(event) => handleOnDescriptionValue(event.target.value)}
              placeholder="Descrição"
              defaultValue={description}
            
            />
            </div>
          </div>
        </main>
        <footer>
          <button className="buttonCancel" type="button" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="buttonSave"
            type="button"
            onClick={onEdit}
            disabled={nameProject < 1 ? true : false}
          >
            Salvar
          </button>
        </footer>
      </div>
    </div>
  );
}
