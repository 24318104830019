import React from "react";

export default function DeleteIcon({ size = 12, color = "#252428" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="22.556"
      viewBox="0 0 20.5 22.556"
    >
      <g id="excluir" transform="translate(-3.5 -2)">
        <path
          id="Caminho_27"
          data-name="Caminho 27"
          d="M4.5,9H23"
          transform="translate(0 -1.889)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_28"
          data-name="Caminho 28"
          d="M21.889,7.111V21.5a2.056,2.056,0,0,1-2.056,2.056H9.556A2.056,2.056,0,0,1,7.5,21.5V7.111m3.083,0V5.056A2.056,2.056,0,0,1,12.639,3H16.75a2.056,2.056,0,0,1,2.056,2.056V7.111"
          transform="translate(-0.944)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_29"
          data-name="Caminho 29"
          d="M15,16.5v6.167"
          transform="translate(-3.306 -4.25)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_30"
          data-name="Caminho 30"
          d="M21,16.5v6.167"
          transform="translate(-5.194 -4.25)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
