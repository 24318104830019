import React from "react";
import MetroPinIcon from "../../../Icons/MetroPinIcon";

import "./styles.scss";

export default function PositionUi({ 
  onChange, 
  options,
  value, 
}) {

  return (
    <div id="containerPositionUi">
      <div className="sectionIcon">
        <MetroPinIcon />
        <span>Posicionar item</span>
      </div>

      <div className="sectionPosition">
        {options.map((row) => (
          <label key={row.label}>
            <input
              type="radio"
              name="group"
              onClick={() => onChange(row.value)}
              defaultChecked={row.value === value ? true : false}
            />
            <span className="radioPulse" />
            <span className="radioButton">
              <span className="radioButtonInner" />
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

