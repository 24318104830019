import React from 'react'

export default function GoogleIcon({size = 12, color = "#252428"}){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.461" viewBox="0 0 22.712 14.461">
        <g id="Icon_ionic-logo-googleplus" data-name="Icon ionic-logo-googleplus" transform="translate(0 -6.539)">
          <path id="Caminho_18" data-name="Caminho 18" d="M14.115,12.656l-.071-.311H7.1v2.843h4.023A3.6,3.6,0,0,1,7.39,18.01a4.254,4.254,0,0,1-3.03-1.122,4.474,4.474,0,0,1-.058-6.21A4.184,4.184,0,0,1,7.35,9.551a3.84,3.84,0,0,1,2.577.936l2.129-2.116A7.209,7.209,0,0,0,7.279,6.539h0a7.356,7.356,0,0,0-5.221,2.12A7.363,7.363,0,0,0,0,13.77,7.276,7.276,0,0,0,1.978,18.8,7.558,7.558,0,0,0,7.43,21a6.727,6.727,0,0,0,4.893-2.054,7.2,7.2,0,0,0,1.881-5A8.315,8.315,0,0,0,14.115,12.656Z"/>
          <path id="Caminho_19" data-name="Caminho 19" d="M31.977,14.271H29.449V11.742H27.63v2.528H25.1v1.819H27.63v2.528h1.819V16.089h2.528Z" transform="translate(-9.266 -1.921)"/>
        </g>
      </svg>
    )
}