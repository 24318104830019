import React from "react";

export default function LogoHeader({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="127.512"
      height="36.178"
      viewBox="0 0 127.512 36.178"
    >
      <g id="logo" transform="translate(-70 -27)">
        <g id="logo-2" data-name="logo" transform="translate(123 34)" fill={color ? color : "#000"}>
          <g id="logo_buildr">
            <path
              id="b"
              d="M660.577,457.516a6.033,6.033,0,0,1-4.895-2.345v1.451a.6.6,0,0,1-.6.6h-3.253a.6.6,0,0,1-.6-.6V438.809a2.982,2.982,0,0,1,2.982-2.982h.873a.6.6,0,0,1,.6.6v7.342a5.769,5.769,0,0,1,4.895-2.55c3.664,0,7.152,2.872,7.152,8.119v.059C667.728,454.644,664.3,457.516,660.577,457.516Zm2.7-8.177c0-2.608-1.758-4.338-3.839-4.338s-3.81,1.729-3.81,4.338v.059c0,2.609,1.729,4.338,3.81,4.338s3.839-1.7,3.839-4.338Z"
              transform="translate(-651.227 -435.827)"
            />
            <path
              id="u"
              d="M810.015,498.775v-1.731a5.558,5.558,0,0,1-4.6,2.521c-3.371,0-5.334-2.228-5.334-5.833v-9.674a.5.5,0,0,1,.5-.5h3.463a.5.5,0,0,1,.5.5v8.268c0,2.11,1,3.195,2.7,3.195s2.784-1.085,2.784-3.195v-8.268a.5.5,0,0,1,.5-.5h3.463a.5.5,0,0,1,.5.5v14.718a.5.5,0,0,1-.5.5h-3.463A.5.5,0,0,1,810.015,498.775Z"
              transform="translate(-782.348 -477.875)"
            />
            <path
              id="i"
              d="M937.031,439.173v-.03a3.316,3.316,0,0,1,3.316-3.316h.763a.61.61,0,0,1,.61.61v2.736a.61.61,0,0,1-.61.61h-3.469A.61.61,0,0,1,937.031,439.173Zm.117,17.439V442.123a.61.61,0,0,1,.61-.61h3.234a.61.61,0,0,1,.61.61v14.489a.611.611,0,0,1-.61.611h-3.234A.61.61,0,0,1,937.148,456.613Z"
              transform="translate(-902.986 -435.827)"
            />
            <path
              id="l"
              d="M993.056,456.563V436.487a.66.66,0,0,1,.66-.66h.7a3.1,3.1,0,0,1,3.1,3.1v17.639a.66.66,0,0,1-.66.66h-3.135A.66.66,0,0,1,993.056,456.563Z"
              transform="translate(-952.337 -435.827)"
            />
            <path
              id="d"
              d="M1053.451,456.592v-1.626a5.769,5.769,0,0,1-4.895,2.55c-3.664,0-7.151-2.872-7.151-8.119v-.059c0-5.246,3.429-8.119,7.151-8.119a6.033,6.033,0,0,1,4.895,2.345v-4.818a2.919,2.919,0,0,1,2.919-2.919h.905a.631.631,0,0,1,.631.631v20.135a.631.631,0,0,1-.631.631h-3.194A.631.631,0,0,1,1053.451,456.592Zm.059-7.254c0-2.608-1.729-4.338-3.81-4.338s-3.84,1.7-3.84,4.338v.059c0,2.609,1.759,4.338,3.84,4.338s3.81-1.729,3.81-4.338Z"
              transform="translate(-994.927 -435.827)"
            />
            <path
              id="r"
              d="M1206.5,485.737c-2.96,0-4.777,1.788-4.777,5.539v5.364a.44.44,0,0,1-.44.44h-3.534a.481.481,0,0,1-.481-.481V481.842a.472.472,0,0,1,.472-.472h.864a3.119,3.119,0,0,1,3.119,3.119v.046c.847-2.021,2.177-3.381,4.489-3.462a.5.5,0,0,1,.523.5V485.5a.234.234,0,0,1-.234.234Z"
              transform="translate(-1132.224 -475.683)"
            />
          </g>
        </g>
        <g id="símbolo" transform="translate(70 27)">
          <path
            id="Caminho_20"
            data-name="Caminho 20"
            d="M78.373,111H71.279A30.279,30.279,0,0,0,41,141.279v.016a5.883,5.883,0,0,0,5.883,5.883H78.373a5.883,5.883,0,0,0,5.883-5.883V116.883A5.883,5.883,0,0,0,78.373,111Zm-4.253,20.521c0,5.036-3.291,7.793-6.864,7.793a5.791,5.791,0,0,1-4.7-2.251v1.393a.577.577,0,0,1-.577.576H58.859a.577.577,0,0,1-.577-.576v-17.1a2.862,2.862,0,0,1,2.862-2.862h.838a.577.577,0,0,1,.577.577v7.047a5.538,5.538,0,0,1,4.7-2.448c3.517,0,6.864,2.757,6.864,7.793Z"
            transform="translate(-41 -111)"
            fill="#0766ff"
          />
          <path
            id="Caminho_21"
            data-name="Caminho 21"
            d="M154.016,193.905c-2,0-3.657,1.66-3.657,4.164v.056c0,2.5,1.66,4.164,3.657,4.164s3.685-1.632,3.685-4.164v-.056A3.845,3.845,0,0,0,154.016,193.905Z"
            transform="translate(-128.857 -177.604)"
            fill="#0766ff"
          />
        </g>
      </g>
    </svg>
  );
}
