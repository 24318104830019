import React from "react";

export default function WhatsIcon({ size = 16, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        id="FontAwsome_whatsapp_"
        data-name="FontAwsome (whatsapp)"
        d="M13.6,34.325A7.931,7.931,0,0,0,1.125,43.893L0,48l4.2-1.1a7.9,7.9,0,0,0,3.789.964H8A7.878,7.878,0,0,0,13.6,34.325ZM8,46.525a6.578,6.578,0,0,1-3.357-.918L4.4,45.464l-2.493.654.664-2.432-.157-.25a6.6,6.6,0,1,1,12.246-3.5A6.664,6.664,0,0,1,8,46.525Zm3.614-4.936c-.2-.1-1.171-.579-1.354-.643s-.314-.1-.446.1-.511.643-.629.779-.232.15-.429.05a5.393,5.393,0,0,1-2.7-2.357c-.2-.35.2-.325.582-1.082a.367.367,0,0,0-.018-.346c-.05-.1-.446-1.075-.611-1.471s-.325-.332-.446-.339-.246-.007-.379-.007a.734.734,0,0,0-.529.246,2.226,2.226,0,0,0-.693,1.654,3.881,3.881,0,0,0,.807,2.05,8.853,8.853,0,0,0,3.386,2.993,3.877,3.877,0,0,0,2.379.5,2.029,2.029,0,0,0,1.336-.943,1.657,1.657,0,0,0,.114-.943C11.939,41.736,11.807,41.686,11.611,41.589Z"
        transform="translate(0 -32)"
        fill={color}
      />
    </svg>
  );
}
