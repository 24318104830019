import React from "react";

export default function AdjustDarkIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.5"
      height="22.5"
      viewBox="0 0 22.5 22.5"
    >
      <path
        id="FontAwsome_adjust_"
        data-name="FontAwsome (adjust)"
        d="M8,19.25A11.25,11.25,0,1,0,19.25,8,11.25,11.25,0,0,0,8,19.25ZM19.25,27.6V10.9a8.347,8.347,0,0,1,0,16.694Z"
        transform="translate(-8 -8)"
        fill="#fff"
      />
    </svg>
  );
}
