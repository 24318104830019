import React from "react";

export default function WifiIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6C15.537 6 18.837 7.353 21.293 9.809L22.707 8.395C19.874 5.561 16.071 4 12 4C7.92897 4.001 4.12597 5.561 1.29297 8.395L2.70697 9.809C5.16297 7.353 8.46297 6 12 6ZM17.671 14.307C14.597 11.233 9.40297 11.233 6.32897 14.307L7.74297 15.721C10.05 13.414 13.95 13.414 16.257 15.721L17.671 14.307Z"
        fill={color}
      />
      <path
        d="M20.437 11.293C15.865 6.71899 8.13596 6.71899 3.56396 11.293L4.97797 12.707C8.78497 8.89999 15.216 8.89999 19.023 12.707L20.437 11.293Z"
        fill={color}
      />
      <path
        d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z"
        fill={color}
      />
    </svg>
  );
}
