import React from "react";

export default function OptionsIcon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.5"
      height="22.5"
      viewBox="0 0 22.5 22.5"
    >
      <path
        id="todos_os_app"
        data-name="todos os app"
        d="M6.75,12.375h5.625V6.75H6.75ZM15.188,29.25h5.625V23.625H15.188Zm-8.437,0h5.625V23.625H6.75Zm0-8.437h5.625V15.188H6.75Zm8.438,0h5.625V15.188H15.188ZM23.625,6.75v5.625H29.25V6.75Zm-8.437,5.625h5.625V6.75H15.188Zm8.438,8.438H29.25V15.188H23.625Zm0,8.438H29.25V23.625H23.625Z"
        transform="translate(-6.75 -6.75)"
        fill={color ? color : "#000"}
      />
    </svg>
  );
}
