import React, { useCallback, useState } from "react";

import "./styles.scss";

export default function TextAreaDefault({ name, label, children, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (

    <div id="containerTextArea">
      <label className={`${isFocused ? "focused" : ""}`} htmlFor={name}>{label}</label>
      <div className={`containerTextAreaDefault ${isFocused ? "focused" : ""}`}>
        <textarea
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          id={name}
          name={name}
          {...rest}
        />
      </div>
    </div>
  );
}
