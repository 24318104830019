import React from "react";

export default function MoverIcon({ size = 12, color = "#9b9fab" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12.998"
      viewBox="0 0 13 12.998"
    >
      <path
        id="mover"
        d="M15.106,8.454l-2.3-2.3a.4.4,0,0,0-.568,0l-.006.006a.4.4,0,0,0,0,.568l1.592,1.592L9.13,8.332l.014-4.691,1.592,1.592a.4.4,0,0,0,.568,0l.006-.006a.4.4,0,0,0,0-.568l-2.3-2.3a.4.4,0,0,0-.568,0l-2.3,2.3a.4.4,0,0,0,0,.568l.006.006a.4.4,0,0,0,.568,0L8.3,3.641l.014,4.691L3.627,8.318,5.219,6.726a.4.4,0,0,0,0-.568l-.006-.006a.4.4,0,0,0-.568,0l-2.3,2.3a.4.4,0,0,0,0,.568l2.3,2.3a.4.4,0,0,0,.568,0l.006-.006a.4.4,0,0,0,0-.568L3.627,9.159l4.691-.014L8.3,13.835,6.712,12.244a.4.4,0,0,0-.568,0l-.006.006a.4.4,0,0,0,0,.568l2.3,2.3a.4.4,0,0,0,.568,0l2.3-2.3a.4.4,0,0,0,0-.568l-.006-.006a.4.4,0,0,0-.568,0L9.145,13.835,9.13,9.144l4.691.014L12.23,10.751a.4.4,0,0,0,0,.568l.006.006a.4.4,0,0,0,.568,0l2.3-2.3A.4.4,0,0,0,15.106,8.454Z"
        transform="translate(-2.225 -2.239)"
        fill={color}
      />
    </svg>
  );
}
