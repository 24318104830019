import React from "react";

export default function AddContentIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="33.42"
      height="24"
      viewBox="0 0 16.708 12"
    >
      <path
        id="Subtração_1"
        data-name="Subtração 1"
        d="M12.428,12H1.285A1.287,1.287,0,0,1,0,10.715V1.286A1.287,1.287,0,0,1,1.285,0H12.428A1.29,1.29,0,0,1,13.6.75h-.889a1.5,1.5,0,0,0,0,3h1.006v6.964A1.287,1.287,0,0,1,12.428,12ZM1.285,4.286v6.268a.161.161,0,0,0,.161.161H12.268a.161.161,0,0,0,.16-.161V4.286Z"
        transform="translate(0 0)"
        fill={color}
      />
      <path
        id="União_1"
        data-name="União 1"
        d="M1.75,4V2.75H.5a.5.5,0,0,1,0-1H1.75V.5a.5.5,0,1,1,1,0V1.75H4a.5.5,0,0,1,0,1H2.75V4a.5.5,0,0,1-1,0Z"
        transform="translate(12.208)"
        fill={color}
      />
    </svg>
  );
}
