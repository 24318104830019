import React from "react";

export default function ColumnIcon({ size = 12, color = "#3b3b3b" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="Grupo_7" data-name="Grupo 7" transform="translate(9 8)">
        <g id="bg" transform="translate(-9 -8)" fill="#eef0f4">
          <path
            d="M 30 39 L 10 39 C 5.037380218505859 39 1 34.96261978149414 1 30 L 1 10 C 1 5.037380218505859 5.037380218505859 1 10 1 L 30 1 C 34.96261978149414 1 39 5.037380218505859 39 10 L 39 30 C 39 34.96261978149414 34.96261978149414 39 30 39 Z"
            stroke="none"
          />
          <path
            d="M 10 2 C 5.588790893554688 2 2 5.588790893554688 2 10 L 2 30 C 2 34.41120910644531 5.588790893554688 38 10 38 L 30 38 C 34.41120910644531 38 38 34.41120910644531 38 30 L 38 10 C 38 5.588790893554688 34.41120910644531 2 30 2 L 10 2 M 10 0 L 30 0 C 35.52285003662109 0 40 4.477149963378906 40 10 L 40 30 C 40 35.52285003662109 35.52285003662109 40 30 40 L 10 40 C 4.477149963378906 40 0 35.52285003662109 0 30 L 0 10 C 0 4.477149963378906 4.477149963378906 0 10 0 Z"
            stroke="none"
            fill="#859ec5"
          />
        </g>
        <g
          id="Retângulo_23"
          data-name="Retângulo 23"
          fill="#fff"
          stroke="#859ec5"
          strokeWidth="2"
        >
          <path
            d="M5,0h7a0,0,0,0,1,0,0V24a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
            stroke="none"
          />
          <path
            d="M5,1h5a1,1,0,0,1,1,1V22a1,1,0,0,1-1,1H5a4,4,0,0,1-4-4V5A4,4,0,0,1,5,1Z"
            fill="none"
          />
        </g>
        <g
          id="Retângulo_41"
          data-name="Retângulo 41"
          transform="translate(10)"
          fill="#fff"
          stroke="#859ec5"
          strokeWidth="2"
        >
          <path
            d="M0,0H7a5,5,0,0,1,5,5V19a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            stroke="none"
          />
          <path
            d="M2,1H7a4,4,0,0,1,4,4V19a4,4,0,0,1-4,4H2a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1Z"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}
