import React, { useState } from "react";
import InputDefault from "../../../Components/Form/InputDefault/";

import "./styles.scss";

export default function ModalNewPage({
  id,
  onClose,
  onUpdatePage,
  namePage,
  validationPage
}) {
  const handleCloseModal = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  const [data, setData] = useState('')
  const [error, setError] = useState({
    error: false,
    message: ''
  })

  function handleOnSetPage(e) {
    try {
      e.preventDefault()

      if(validationPage.filter(row => row.nome === data).length > 0) {
        throw{
          name: 'namePage',
          message: "Já existe uma page com este nome!",
        };
      } else if(data === "undefined" || data === null || data === '') {
        throw{
          name: 'namePage',
          message: "Favor insira um nome valido",
        };
      }
      onUpdatePage(data)

    } catch(err) {
      setError({
        error: true,
        message: err.message,
        name: err.name,
      })
    }
  }


  return (
    <div id={id} onClick={handleCloseModal} className="overlayModalNewPage">
      <form onSubmit={handleOnSetPage} className="containerNewPage">
        <header>
          <h2>Criar nova página</h2>
        </header>

        <main>
          <div className="questionName">
          <InputDefault
            type="text"
            name={"namePage"}
            label={"Nome da página"}
            onChange={(event) => setData(event.target.value)}
            placeholder="Digite o nome da página"
            defaultValue={namePage}
            error={error}
          />
          </div>
        </main>
        <footer>
          <button className="buttonCancel" type="button" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="buttonSave"
            type="submit"
          >
            Salvar
          </button>
        </footer>
      </form>
    </div>
  );
}
