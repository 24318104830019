import React from "react";

export default function EnvelopeIcon({ width = 16, height = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 12"
    >
      <path
        id="FontAwsome_envelope_"
        fill={color}
        data-name="FontAwsome (envelope)"
        d="M14.5,64H1.5A1.5,1.5,0,0,0,0,65.5v9A1.5,1.5,0,0,0,1.5,76h13A1.5,1.5,0,0,0,16,74.5v-9A1.5,1.5,0,0,0,14.5,64Zm0,1.5v1.275c-.7.571-1.818,1.458-4.206,3.328-.526.414-1.569,1.409-2.294,1.4-.725.012-1.768-.983-2.294-1.4C3.318,68.233,2.2,67.346,1.5,66.775V65.5Zm-13,9V68.7c.716.57,1.732,1.371,3.279,2.583C5.462,71.82,6.659,73.007,8,73s2.516-1.162,3.22-1.717c1.548-1.212,2.564-2.013,3.28-2.583v5.8Z"
        transform="translate(0 -64)"
      />
    </svg>
  );
}
