import React, { useEffect, useState } from "react";
import { ButtonBlur } from "../../Button/Button";
import Modal from "../../Modal/Modal";
import GaleryEdit from "../ModalEdit";
import AlbumModal from "../AlbumModal";
import messageStore from "../../../../Store/MessageStore";
import ModalDeleteAlbum from "../ModalDeleteAlbum";
import ButtonControllers from "../../ButtonControllers";
import ButtonDefault from "../../../Form/ButtonDefault";
import CardItem from "../CardItem";

//ICONS
import PageIcon from "../../../../Icons/PageIcon";
import GaleryIcon from "../../../../Icons/GaleryIcon";
import TrashIcon from "../../../../Icons/TrashIcon";
import PlusCircleIcon from "../../../../Icons/PlusCircleIcon";

//IMAGES
import AlbumImage from "../../../../Images/AlbumImage.png";

//CSS
import "./Galery.scss";

export default function Galery({
  handleOnImage,
  typeButton,
  disabled,
  type,
  title,
  image,
  borderRadiusLogo,
  resolution,
}) {
  const randomId = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  const [openGaleryModal, setOpenGaleryModal] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAddImage, setModalAddImage] = useState(false);
  const [idModal, setIdModal] = useState(null);
  const [nameAlbum, setNameAlbum] = useState("");
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [messageDelete, setMessageDelete] = useState("");

  const [error, setError] = useState({
    name: "",
    message: "",
    error: false,
  });
  const [addressImage, setAddressImage] = useState("");

  const [galery, setGalery] = useState(
    window.localStorage.getItem("galery")
      ? JSON.parse(window.localStorage.getItem("galery"))
      : [],
  );

  const [filteredGalery, setFilteredGalery] = useState(galery);

  useEffect(() => {
    saveGalery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galery]);

  useEffect(() => {
    filterGalery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, galery]);

  function filterGalery() {
    if (!type) {
      setFilteredGalery(galery);
    } else {
      setFilteredGalery(
        galery
          .map((row) => row)
          .filter((obj) => obj.type === type || obj.type === "album"),
      );
    }
  }

  function handleOpenModalCreate() {
    setModalCreate(true);
  }

  function changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  function handleNewAlbum() {
    const newId = randomId();
    const newGalery = galery;
    const length = newGalery.length;

    const newAlbum = {
      id: newId,
      title: nameAlbum,
      images: [],
      type: "album",
    };

    window.localStorage.setItem(`galery:${newId}`, JSON.stringify(newAlbum));

    newGalery.push(newAlbum);
    changePosition(newGalery, length, 0);
    setGalery(...[newGalery]);
    handleSaveGalery();
    filterGalery();
    setModalCreate(false);
  }

  function handleOpenModal(id) {
    setIdModal(id);
    setOpenGaleryModal(false);
    setOpenAlbumModal(!openAlbumModal);
  }

  function handleOpenModalDelete(id, message) {
    setMessageDelete(message);
    setIdModal(id);
    setModalDelete(!modalDelete);
  }

  function handlePrevioModal() {
    setGalery(JSON.parse(window.localStorage.getItem("galery")));
    setOpenAlbumModal(!openAlbumModal);
    setOpenGaleryModal(true);
  }

  async function handleSaveGalery() {
    window.localStorage.setItem("galery", JSON.stringify(galery));
    messageStore.addSuccess(`${nameAlbum} - Galeria salvo.`);
    filterGalery();
  }

  function handleOnDelete() {
    const newGalery = galery;
    const selectGalery = newGalery.filter((obj) => obj.id !== idModal);

    window.localStorage.removeItem(`galery:${idModal}`);

    setGalery(selectGalery);
    filterGalery();
    setModalDelete(false);
  }

  function saveGalery() {
    window.localStorage.setItem("galery", JSON.stringify(galery));
    filterGalery();
  }

  function handleOnCancel() {
    setAddressImage("");
    setModalAddImage(!modalAddImage);
    setError({
      name: "",
      message: "",
      error: false,
    });
  }

  function handleOnAddImage(type, item) {
    const newId = randomId();
    const newGalery = galery;
    const length = newGalery.length;

    if (!item.includes("http")) {
      setError({
        error: true,
        message: "Link invalido",
        name: "nameAlbum",
      });
    } else {
      const newImage = {
        id: newId,
        images: item,
        title: type,
        type: type,
      };

      newGalery.push(newImage);
      changePosition(newGalery, length, 0);

      setGalery(...[newGalery]);
      handleSaveGalery();
      setModalAddImage(false);
    }
  }

  function handleSelectImage(url, type) {
    handleOnImage(url, type);
    setOpenGaleryModal(false);
  }

  return (
    <>
      {typeButton === true ? (
        <ButtonControllers
          title={"Galeria"}
          onClick={() =>
            setOpenGaleryModal((openGaleryModal) => !openGaleryModal)
          }
        >
          <GaleryIcon />
        </ButtonControllers>
      ) : (
          <div className={`openGalery ${disabled ? "galeryDisabled" : ""}`}>
            <header>
              <PageIcon />
              <span>{title ?? "Adicionar mídia"}</span>
            </header>

            <button
              type="button"
              onClick={() =>
                setOpenGaleryModal((openGaleryModal) => !openGaleryModal)
              }
              disabled={disabled}
            >
              Abrir galeria
            </button>
            {resolution && <h3>Resolução {resolution}</h3>}
          </div>
        ) && typeButton === "logo" ? (
        <div className="sectionLogoPreview">
          <div className="contentInfoGalery">
            <div className="titleGalery">
              <PageIcon />
              <span>{title ?? "Adicionar mídia"}</span>
            </div>

            <button
              type="button"
              onClick={() =>
                setOpenGaleryModal((openGaleryModal) => !openGaleryModal)
              }
              disabled={disabled}
            >
              Abrir galeria
            </button>
          </div>

          <div
              className="imagePhoneUi"
              style={{ borderRadius: borderRadiusLogo }}
            >
              <img
                src={image}
                alt="Icon aplication"
                style={{ borderRadius: borderRadiusLogo }}
              />
            </div>
        </div>
      ) : (
        <div className={`openGalery ${disabled ? "galeryDisabled" : ""}`}>
          <header>
            <PageIcon />
            <span>{title ?? "Adicionar mídia"}</span>
          </header>

          <button
            type="button"
            onClick={() =>
              setOpenGaleryModal((openGaleryModal) => !openGaleryModal)
            }
            disabled={disabled}
          >
            Abrir galeria
          </button>
          {resolution && <h3>Resolução {resolution}</h3>}
        </div>
      )}

      {openGaleryModal ? (
        <Modal
          onClose={() =>
            setOpenGaleryModal((openGaleryModal) => !openGaleryModal)
          }
        >
          <div className="openGaleryModal">
            <header>
              <div className="information">
                <h1>Galeria</h1>
                <span>{filteredGalery.length} Mídias</span>
              </div>
              <div className="openGaleryButtons">
                <ButtonDefault
                  onClick={() => setModalAddImage(!modalAddImage)}
                  width={"245px"}
                  Icon={<PlusCircleIcon />}
                  title={"Adicionar mídia"}
                  type="default"
                />
                <ButtonBlur onClick={handleOpenModalCreate}>
                  <PlusCircleIcon />
                  Novo Álbum
                </ButtonBlur>
              </div>
            </header>
            <main>
              {filteredGalery.map((row) => (
                <React.Fragment key={row.id}>
                  {row.type === "imagem" && (
                    <CardItem
                      handleOnDelete={() =>
                        handleOpenModalDelete(row.id, row.type)
                      }
                      handleOnclick={() =>
                        handleSelectImage(row.images, row.type)
                      }
                      image={row.images}
                      type={row.type}
                    />
                  )}

                  {row.type === "video" && (
                    <CardItem
                      handleOnDelete={() =>
                        handleOpenModalDelete(row.id, row.type)
                      }
                      handleOnclick={() =>
                        handleSelectImage(row.images, row.type)
                      }
                      image={row.images}
                      type={row.type}
                    />
                  )}

                  {row.type === "album" && (
                    <div key={row.id} className="containerAlbum">
                      <button
                        className="imageAlbum"
                        onClick={() => handleOpenModal(row.id)}
                      >
                        <img src={AlbumImage} alt="Album" />
                      </button>
                      <div className="informationAlbum">
                        <button
                          onClick={() => handleOpenModal(row.id)}
                          className="titleAlbm"
                        >
                          <h2>{row.title}</h2>
                          <span>{row.images.length} Mídias</span>
                        </button>

                        <button
                          className="deleteAlbum"
                          onClick={() =>
                            handleOpenModalDelete(row.id, row.type)
                          }
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </main>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {openAlbumModal && (
        <AlbumModal
          id="overlayAlbumModal"
          onClose={() => setOpenAlbumModal((openAlbumModal) => !openAlbumModal)}
          handlePrevioModal={handlePrevioModal}
          idModal={idModal}
          data={galery}
          handleOnImage={(value, type) => handleOnImage(value, type)}
          type={type}
        />
      )}

      {modalCreate && (
        <GaleryEdit
          id="containerModalEdit"
          onClose={() => setModalCreate(!modalCreate)}
          handleOnCreate={handleNewAlbum}
          handleOnChange={(value) => setNameAlbum(value)}
          title={"Criar novo album"}
          placeholder={"Nome do album"}
          name={nameAlbum}
        />
      )}

      {modalAddImage && (
        <GaleryEdit
          id="containerModalEdit"
          onClose={handleOnCancel}
          handleOnCreate={(type, item) => handleOnAddImage(type, item)}
          handleOnChange={(value) => setAddressImage(value)}
          title={"Adicionar mídia"}
          name={addressImage}
          placeholder={"URL da mídia"}
          error={error}
        />
      )}

      {modalDelete && (
        <ModalDeleteAlbum
          id="containerGaleryDelete"
          onClose={() => setModalDelete(!modalDelete)}
          handleOnDelete={handleOnDelete}
          message={messageDelete}
          idModal={idModal}
        />
      )}
    </>
  );
}
