import React from "react";

export default function CommentsIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12.444"
      viewBox="0 0 16 12.444"
    >
      <path
        id="FontAwsome_comments_"
        data-name="FontAwsome (comments)"
        d="M11.556,36.444C11.556,33.989,8.969,32,5.778,32S0,33.989,0,36.444A3.768,3.768,0,0,0,1.056,39a6.252,6.252,0,0,1-.994,1.514.221.221,0,0,0-.042.242.218.218,0,0,0,.2.133,4.891,4.891,0,0,0,2.464-.694,7.068,7.068,0,0,0,3.092.694C8.969,40.889,11.556,38.9,11.556,36.444Zm3.389,6.111A3.763,3.763,0,0,0,16,40c0-1.858-1.486-3.45-3.592-4.114a4.128,4.128,0,0,1,.036.558c0,2.942-2.992,5.333-6.667,5.333a8.328,8.328,0,0,1-.881-.053,6,6,0,0,0,5.325,2.719,7.029,7.029,0,0,0,3.092-.694,4.891,4.891,0,0,0,2.464.694.219.219,0,0,0,.2-.133.223.223,0,0,0-.042-.242A6.186,6.186,0,0,1,14.944,42.556Z"
        transform="translate(0 -32)"
        fill={color}
      />
    </svg>
  );
}
