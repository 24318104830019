import React from "react";

export default function SetaIcon({ size = 12, color = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.597"
      height="8.594"
      viewBox="0 0 9.597 8.594"
    >
      <path
        id="check"
        d="M190.983,297.5l2.4,3.1,4.4-6.2"
        transform="translate(-189.58 -293.006)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
