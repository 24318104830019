import React from "react";
import "./styles.scss";

import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
    prefixCls="rc-slider-tooltip"
    overlay={`${value} %`}
    visible={dragging}
    placement="top"
    key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

export default function NewRange({ min, max, defaultValue, icon, description, mask, onChange = () => {} }) {
  return (
    <>
      <div className="containerNewRange">
        <div className="descriptionNewRange">
          {icon}
          <span>{description ? description : 'Nome do widget'}</span>
        </div>

        <Slider
          min={min ? min : 0}
          max={max ? max : 100}
          defaultValue={defaultValue ? defaultValue : 0}
          handle={handle}
          trackStyle={{ backgroundColor: "#0766FF", height: "8px" }}
          railStyle={{ backgroundColor: "rgba(255, 255, 255, 0.49)", height: "8px" }}
          handleStyle={{ borderColor: "rgb(230, 239, 255)", width: "20px", height: "20px" }}
          marks={mask === 4 ? { 0: "0%", 25: "25%", 50: "50%", 75: "75%", 100: "100%" } : { 0: "0%", 100: "100%" }}
          onAfterChange={(event) => onChange(event)}
        />

      </div>
    </>
  );
}
