import React from "react";
import { ReactSortable } from "react-sortablejs";
import WidgetRender from "../WidgetRender/WidgetRender";

const RenderWidgets = ({
  loading,
  setLoading,
  template,
  setTemplate,
  editWidget,
  removeWidget,
  editionWidgetEdit,
  updateWidgetColumn,
  stateButton,
  pageId,
  setPageId,
}) => {
  return (
    <div className="sortableItems">
      <ReactSortable
        group="shared"
        animation={150}
        list={template}
        setList={setTemplate}
        className="listProject"
      >
        {loading === false &&
          template &&
          template.map((item, key) => (
            <WidgetRender
              updateWidgetColumn={updateWidgetColumn}
              id={item.id}
              item={item.template}
              indice={key}
              editId={editionWidgetEdit.id}
              setLoading={setLoading}
              template={template}
              editWidget={editWidget}
              removeWidget={removeWidget}
              stateButton={stateButton}
              pageId={pageId}
              setPageId={setPageId}
              key={key}
              edit={true}
            />
          ))}
      </ReactSortable>
    </div>
  );
};

export default RenderWidgets;
