import React, { useState } from 'react';

import AddContentIcon2 from '../../Icons/AddContentIcon2';
import DropUpIcon from '../../Icons/DropUpIcon';

import widgetStore from '../../Store/WidgetStore';
import { ReactSortable } from 'react-sortablejs';

import './AddContent.scss'

export default function AddContent() {
  const newData = widgetStore.getState().filter(obj => obj.template.cat === 'conteudo')
  const [close, setClose] = useState(false)

  return (
    <div id="addContent">
    <section>      
      <div className="mainAddContent" onClick={() => setClose(!close)}>
        <div className="contentDescription">
          <AddContentIcon2 />
          <h3>Adicionar conteúdo</h3>
        </div>
        <div className="iconDropContent" style={close ? {transform: 'rotate(-180deg)'} : {transform: 'rotate(0deg)'}}>
          <DropUpIcon />
        </div>
      </div>

      <footer className={close ? 'close' : 'open'}>
        <ReactSortable
          animation={150}
          sort={false}
          list={newData} 
          setList={() => {}}
          group={
              {
                  name: 'shared',
                  pull: 'clone',
                  put: false
              }
          }
        >
          {newData.map((row, key) => (
            <div className="drag-item" key={key}>
                  <i className={row.template.ico}></i>
                    {row.template.icone}
                  <span>{row.template.name}</span>
              </div>
          ))}
        </ReactSortable>
      </footer>
    </section>
  </div>
  );
}
