import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Axios from "axios";
import MapStyles from "./MapStyles";
import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import NewRange from "../../Ui/NewRange/NewRange";

//ICONS
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import MapMarkerIcon from "../../../Icons/MapMarkerIcon";

import "./EditMap.scss";

function MapWidget(props) {
  const options = props.item.options;

  const styleOptions = {
    borderRadius: options.borderRadius,
  };

  const googleMap = {
    center: {
      lat: options.lat,
      lng: options.lng,
    },
    zoom: 14,
  };

  const AnyReactComponent = () => (
    <i className="fa fa-map-marker" aria-hidden="true"></i>
  );

  return (
    <div className="containerMapWidget">
      <div className="buttonMap">
        <InputPrimary
          name="address"
          defaultValue={options.address}
          type="text"
          placeholder="Endereço"
        >
          <MapMarkerIcon />
        </InputPrimary>
      </div>

      <div className="containerMap" style={styleOptions}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDNnbvC6YiCzjPVTS2n1_S3OnLQXzXk49o" }}
          defaultCenter={googleMap.center}
          defaultZoom={googleMap.zoom}
          options={{ styles: MapStyles.Blue }}
        >
          <AnyReactComponent
            lat={options.lat}
            lng={options.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

function MapEdit({ item, onSave }) {
  const [options, setOptions] = useState(item.options);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options);
  }

  function handleLatLng(address) {
    Axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=AIzaSyDNnbvC6YiCzjPVTS2n1_S3OnLQXzXk49o",
    ).then((res) => {
      console.log(res)

      // options.address = address;
      // options.lat = res.data.results[0].geometry.location.lat;
      // options.lng = res.data.results[0].geometry.location.lng;
      // onSave(options);
      // setOptions({});
    });
  }

  const googleMap = {
    center: {
      lat: -23.4839086,
      lng: -46.7637682,
    },
    zoom: 7,
  };

  const AnyReactComponent = ({ text }) => (
    <i className="fa fa-map-marker" aria-hidden="true"></i>
  );

  return (
    <div id="editMap">
      <div className="headerPanelRight">
        <h2>Editar mapa</h2>
      </div>
      <div className="mainPanelRight">
        <div className="map">
          <div className="buttonMap">
            <InputPrimary
              name="address"
              defaultValue={options.address}
              type="text"
              placeholder="Endereço"
              onChange={(value) => handleLatLng(value)}
            >
              <MapMarkerIcon />
            </InputPrimary>
          </div>
          <div className="containerMap">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDNnbvC6YiCzjPVTS2n1_S3OnLQXzXk49o",
              }}
              defaultCenter={googleMap.center}
              defaultZoom={googleMap.zoom}
              options={{ styles: MapStyles.Blue }}
            >
              <AnyReactComponent
                lat={-23.4839086}
                lng={-46.7637682}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
}

export { MapWidget, MapEdit };
