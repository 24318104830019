const Blue = [
  {
      "stylers": [
          {
              "hue": "#2c3e50"
          },
          {
              "saturation": 250
          },
          {
            "borderRadius": "20px"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
          {
              "lightness": 50
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  }
]

export default {Blue}