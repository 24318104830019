import React, { useCallback, useState } from "react";
import ModalFormulario from "../ModalFormulario";

// ICONS
import AlertCircleIcon from "../../../../Icons/AlertCircleIcon";
import ConfigurationIcon from "../../../../Icons/ConfigurationIcon";
import MoverIcon from "../../../../Icons/MoverIcon";
import TrashIcon from "../../../../Icons/TrashIcon";

import "./styles.scss";
import InputRadioDefault from "../../../Form/InputRadioDefault";

export default function BoxAlternative({ item, onItems, handleOnCheckRadio, onDelete, styles }) {
  const [isFocused, setIsFocused] = useState(false);
  const [modal, setModal] = useState(false);
  const idField = item.id;

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  function hadleOnDelete(id) {
    onDelete(id)
    window.localStorage.removeItem('@buildr:edition')
  }

  function handleEdit(itemprops) {
    window.localStorage.setItem('@buildr:edition', JSON.stringify(itemprops))
    setModal(true)
  }

  return (
    <div
      className={`containerBoxSelection  ${isFocused ? "focused" : ""}`}
      style={{
        background: styles.colorPrimary,
        borderRadius: styles.borderRadius,
        border: isFocused
          ? "1px solid" + styles.colorSecundary
          : "1px solid" + styles.colorPrimary,
      }}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
    >
      <div className="boxHeader">
        <div className="boxHeaderLeft">
          <span style={{ color: `${isFocused ? styles.colorSecundary : styles.colorText}` }}>
            {item.config.question || 'Selecão'}
          </span>
        </div>
        <div className="boxHeaderRight">
          {item.config.required && (
            <div className="required">
              <div className="error">
                <AlertCircleIcon color={styles.colorSecundary} />
              </div>
            </div>
          )}
          <div className="buttonsRight">
            <button type="button" onClick={() => hadleOnDelete(idField)}>
              <TrashIcon width={12} height={13} color={styles.colorText} />
            </button>
            <button type="button" onClick={() => handleEdit(item.config)}>
              <ConfigurationIcon size={13} color={styles.colorText} />
            </button>
            <button
              className="moveBox"
              type="button"
              style={{ border: "none", background: "transparent" }}
            >
              <MoverIcon color={styles.colorText} />
            </button>
          </div>
        </div>
      </div>

      <div className="boxMainSelections">
        {item.config.alternative.map((row) => (
          <InputRadioDefault
            key={row.id}
            data={row}
            styles={styles}
            onClick={() => handleOnCheckRadio(row.id, idField)}
          />
        ))}
      </div>

      {modal && (
        <ModalFormulario 
          id={"id"}
          data={item}
          onClose={() => setModal(!modal)}
          setData={(items) => onItems(items, idField)}
        />
      )}
    </div>
  );
}
