import React, { useState } from "react";

import InputPrimary from "../../Ui/InputPrimary/InputPrimary";
import Select from "../../Ui/Select/Select";
import NewRange from "../../Ui/NewRange/NewRange";
import Galery from "../../Ui/GaleryUi/Galery/Galery";
import { useParams } from "react-router-dom";
import { LinkUi } from "../../Ui/LinkUi/LinkUi";

//ICONS
import ResizeWidthIcon from "../../../Icons/ResizeWidthIcon";
import ExternalLinkIcon from "../../../Icons/ExternalLinkIcon";
import LinkIcon from "../../../Icons/LinkIcon";

//CSS
import "./EditImage.scss";

const Image = (props) => {
  const options = props.item.options;

  const styleOptions = {
    borderRadius: options.borderRadius,
    width: options.width,
    src: options.src,
  };

  function handleClick() {
    if (options.toggleLink === false) {
      props.setPageId(options.internalLink)
    } else if (options.externalLink.includes('http')) {
      var url = options.externalLink;
      var win = window.open(url, "_blank");
      win.focus();
    }
  }

  return (
    <div
      className="ImageEdits"
      style={{
        borderRadius: options.borderRadius,
        pointerEvents:
          props.edit === true || options.linkImage === "default"
            ? "none"
            : "false",
      }}
      onClick={handleClick}
    >
      <img src={options.src} style={styleOptions} alt="um foto qualquer" />
    </div>
  );
};

const ImageEdit = ({ id, item, onSave }) => {
  const [options, setOptions] = useState(item.options);
  const params = useParams();
  const template = window.localStorage.getItem(params.id)
      ? JSON.parse(window.localStorage.getItem(params.id))
      : []

  const pages = template.pages.filter((obj) => obj.data);
  const [link, setLink] = useState(options.toggleLink);
  const [openGaleryModal, setOpenGaleryModal] = useState(false);

  function handleOnChange(name, value) {
    options[name] = value;
    setOptions(options);
    onSave(options, id);
  }

  function handleToggleInputRange() {
    setLink(!link);
    handleOnChange("toggleLink", !link);

    if(link === true) {
      handleOnChange("internalLink", '0')
    }
  }

  return (
    <div id="editImage">
      <div className="headerPanelRight">
        <h2>Editar imagem</h2>
      </div>
      <div className="mainPanelRight">
        <LinkUi onChange={() => handleToggleInputRange(link)} value={link}>
          <ExternalLinkIcon />
          Link externo
        </LinkUi>

        <Select
          onChange={(value) => handleOnChange("internalLink", value)}
          value={options.internalLink}
          placeholder="Selecione uma página"
          disabled={link}
        >
          {pages.map((row) => (
            <option key={row.id} value={row.pageId}>
              {row.nome}
            </option>
          ))}
          <option value="0">Sem link</option>
        </Select>

        <InputPrimary
          placeholder="Link da imagem"
          onChange={(value) => handleOnChange("externalLink", value)}
          defaultValue={options.externalLink}
          disabled={!link}
        >
          <LinkIcon />
        </InputPrimary>

        <Galery
          onClose={() => setOpenGaleryModal(!openGaleryModal)}
          handleOnImage={(value) => handleOnChange("src", value)}
          type="imagem"
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Largura"}
          defaultValue={options.width.replace("%", "")}
          onChange={(value) => handleOnChange("width", value + "%")}
          mask={4}
        />

        <NewRange
          icon={<ResizeWidthIcon />}
          description={"Arredondamento da borda"}
          mask={4}
          defaultValue={options.borderRadius?.replace("px", "")}
          onChange={(value) => handleOnChange("borderRadius", value + "px")}
        />
      </div>
    </div>
  );
};

export { ImageEdit, Image };
