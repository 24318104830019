import React from "react";

export default function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.469"
      height="15"
      viewBox="0 0 18.469 15"
    >
      <path
        id="FontAwsome_twitter_"
        data-name="FontAwsome (twitter)"
        d="M16.57,51.82c.012.164.012.328.012.492a10.7,10.7,0,0,1-10.77,10.77A10.7,10.7,0,0,1,0,61.383a7.831,7.831,0,0,0,.914.047,7.581,7.581,0,0,0,4.7-1.617,3.792,3.792,0,0,1-3.539-2.625,4.773,4.773,0,0,0,.715.059,4,4,0,0,0,1-.129A3.786,3.786,0,0,1,.75,53.4v-.047a3.812,3.812,0,0,0,1.711.48,3.791,3.791,0,0,1-1.172-5.062,10.759,10.759,0,0,0,7.8,3.961A4.273,4.273,0,0,1,9,51.867a3.789,3.789,0,0,1,6.551-2.59,7.452,7.452,0,0,0,2.4-.914,3.775,3.775,0,0,1-1.664,2.086,7.588,7.588,0,0,0,2.18-.586A8.137,8.137,0,0,1,16.57,51.82Z"
        transform="translate(0 -48.082)"
        fill="#fff"
      />
    </svg>
  );
}
