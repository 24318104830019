import React from "react";

import "./styles.scss";

export default function InputCheckboxDefault({
  data,
  styles,
  ...rest
}) {

  return (
    <div id="containerCheckboxDefault">
      <label style={{color: data.checked && styles.colorText}} htmlFor={data.id}>
        <input 
          id={data.id}
          type="checkbox"
          className="checkboxDefault"
          defaultChecked={data.checked}
          {...rest}
          style={{backgroundColor: data.checked && styles.colorSecundary}}
        />
        <span style={{color: data.checked && styles.colorText}} className="textInputCheckBoxDefault" dangerouslySetInnerHTML={{ __html: data.value }} />
      </label>
    </div>
  );
}
