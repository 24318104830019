import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  ButtonSignIn,
  ButtonRegister,
} from "../../../Components/Ui/Button/Button";

import FAcebookIcon from "../../../Icons/FacebookIcon";
import GoogleIcon from "../../../Icons/GoogleIcon";
import LinkedinIcon from "../../../Icons/LinkedinIcon";
import Logo from "../../../Components/Logo/Logo";

import "./styles.scss";
import InputDefault from "../../../Components/Form/InputDefault";

export default function SignIn() {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [hour, setHour] = useState('')

  useEffect(() => {
    getHour()
  }, []);

  async function handleOnSubmit(e) {
    try {
      e.preventDefault();

      if (!userName || userName !== "tropadigital")
        throw {
          name: "usuario",
          message: "Usuario ou senha incorretos.",
        }

      window.localStorage.setItem("token", "true");

      history.push("/home");
    } catch (e) {

      setError({
        error: true,
        message: e.message,
        name: e.name,
      });
    }
  }

  function getHour() {
    const date = new Date();
    const hours = date.getHours();
    if (hours >= 5 && hours < 12) {
      setHour('Bom Dia')      
    } else if (hours >= 12 && hours < 18) {
      setHour('Boa Tarde')      
    } else {
      setHour('Boa Noite')      
    }
  }

  return (
    <>
      <div id="login">
        <div className="contentLeft">
          <div className="logo">
            <div className="logoImage">
              <Logo />
            </div>
          </div>
        </div>

        <div className="contentRight">
          <div className="wrapper">
            <div className="infoLogin">
              <header>
                <h1>
                  Olá!
                  <br /> {hour}.
                </h1>
                <div className="socialNetworks">
                  <ul>
                    <li>
                      <a href="/facebook">
                        <FAcebookIcon />
                      </a>
                    </li>
                    <li>
                      <a href="/google">
                        <GoogleIcon />
                      </a>
                    </li>
                    <li>
                      <a href="/linkedin">
                        <LinkedinIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </header>
              <form action="#">
                <div className="field">
                  <InputDefault 
                    label="Usuário"
                    name="usuario"
                    type="text"
                    value={userName}
                    onChange={(event) => setUserName(event.target.value)}
                    placeholder="Digite seu nome"
                    error={error}
                  />
                </div>

                <div className="field">
                  <div className="fieldGroup">
                    <Link to="#">Esqueceu sua senha?</Link>
                  </div>
                  <InputDefault 
                    label="Senha"
                    name="senha"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Digite sua senha"
                    autoComplete="on"
                  />
                </div>

                <div className="buttonsLogin">
                  <ButtonSignIn type="submit" onClick={handleOnSubmit}>
                    Entrar
                  </ButtonSignIn>

                  <ButtonRegister
                    onClick={() => history.push('/registrar')}
                  >
                      Registrar-se
                  </ButtonRegister>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}