import React from "react";

export default function YoutubeIcon({ size = 12, color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11.25"
      viewBox="0 0 16 11.25"
    >
      <path
        id="FontAwsome_youtube_"
        data-name="FontAwsome (youtube)"
        d="M30.6,65.76a2.01,2.01,0,0,0-1.415-1.424A47.512,47.512,0,0,0,22.933,64a47.512,47.512,0,0,0-6.251.336,2.01,2.01,0,0,0-1.415,1.424,22.636,22.636,0,0,0,0,7.752,1.98,1.98,0,0,0,1.415,1.4,47.51,47.51,0,0,0,6.251.337,47.51,47.51,0,0,0,6.251-.337,1.98,1.98,0,0,0,1.415-1.4,22.636,22.636,0,0,0,0-7.752Zm-9.3,6.255V67.257l4.182,2.379Z"
        transform="translate(-14.933 -64)"
        fill={color}
      />
    </svg>
  );
}
